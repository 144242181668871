// better structuring of data types constraints
export enum BrowserType {
  Opera = 'Opera',
  Firefox = 'Firefox',
  InternetExplorer = 'InternetExplorer',
  Edge = 'Microsoft Edge',
  Chrome = 'Google Chrome or Chromium',
  Safari = 'Safari',
  Unknown = 'Unknown',
}

export enum ScreenSizeType {
  Unknown = 'Unknown',
  XSmall = 'XSmall',
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
  XLarge = 'XLarge',
}

export enum OrientationType {
  Landscape = 'Landscape',
  Portrait = 'Portrait',
  Unknown = 'Unknown',
}

export enum DeviceType {
  Unknown = 'Unknown',
  Handset = 'Handset', // Mobile
  Tablet = 'Tablet',
  Web = 'Web',
}

export enum BreakpointType {
  Unknown = 'Unknown',
  HandsetPortrait = 'HandsetPortrait',
  HandsetLandscape = 'HandsetLandscape',
  TabletPortrait = 'TabletPortrait',
  TabletLandscape = 'TabletLandscape',
  WebPortrait = 'WebPortrait',
  WebLandscape = 'WebLandscape',
}

export enum MessageType {
  Error = 'error',
  Info = 'info',
  Null = '',
}

export enum MessageSource {
  Collect = 'collect',
  Sync = 'sync',
  Null = '',
}

export enum NotificationType {
  Primary = 'primary',
  Secondary = 'secondary',
  Success = 'success',
  Info = 'info',
  Warning = 'warning',
  Danger = 'danger',
}

export enum PickerType {
  Document = 'document',
  Month = 'month',
  Year = 'year',
}

export enum NodeType {
  Actitime = 'actitime',
  AuditLog = 'auditLog',
  Invoice = 'invoice',
}

export enum ReleaseRecord {
  Current = 'current',
  Previous = 'previous',
  Planned = 'planned',
  Past = 'past',
}

export enum EntityStatus {
  Active = 'active',
  Archived = 'archived',
}

export enum EntityType {
  Client = 'client',
  CustomerAddress = 'customeraddress',
  Customer = 'customer',
  Department = 'department',
  Project = 'project',
  Task = 'task',
  TimeTrack = 'timetrack',
  TimeTrackRecord = 'record',
  TypeOfWork = 'typeOfWork',
  UserRate = 'userRate',
  User = 'user',
  WorkflowStatus = 'workflowStatus',
  AuditLog = 'auditLog',
  AuditLogNode = 'auditLogNode',
  Invoice = 'invoice',
}

export enum LogType {
  Customer = 'Customer',
  Project = 'Project',
  Task = 'Task',
  Subtask = 'Subtask',
  Timetrack = 'Timetrack',
  HTML = 'HTML',
};

export enum ActitimeKey {
  Customers = 'customers',
  Departments = 'departments',
  Projects = 'projects',
  Tasks = 'tasks',
  TimeTracks = 'timetrack',
  TypesOfWork = 'typesOfWork',
  UserRates = 'userRates',
  Users = 'users',
  WorkflowStatuses = 'workflowStatuses',
}

export enum ApiKey {
  Clients = 'clients',
  CustomerAddresses = 'customeraddresses',
  Customers = 'customers',
  Departments = 'departments',
  Projects = 'projects',
  Tasks = 'tasks',
  TimeTracks = 'timetracks',
  TypesOfWork = 'typesOfWork',
  UserRates = 'userRates',
  Users = 'users',
  WorkflowStatuses = 'workflowStatuses',
  AuditLogs = 'auditLogs',
  AuditLogNodes = 'auditLogNodes',
  Invoices = 'invoices',
}

export enum StorageKeys {
  LastUrlWithParams = 'lastUrlWithParams',
  LastRequest = 'lastRequest',
  TimeTracked = 'timeTracked',
  AppState = 'appState',
  History = 'history',
  CustomerAddresses = 'customeraddresses',
  Clients = 'clients',
  Customers = 'customers',
  Departments = 'departments',
  Projects = 'projects',
  Tasks = 'tasks',
  Timetracks = 'timetracks',
  TypesOfWork = 'typesOfWork',
  UserRates = 'userRates',
  Users = 'users',
  WorkflowStatuses = 'workflowStatuses',
  AuditLogNodes = 'auditLogNodes',
  AuditLogs = 'auditLogs',
  AppSettings = 'appSettings',
  InvoiceEndDate = 'invoiceEndDate',
  InvoiceStartDate = 'invoiceStartDate',
  FeedbackComments = 'feedbackComments',
  ActivePreviewInvoiceNumber = 'activepreviewinvoicenumber',
  StartingInvoicesFrom = 'startinginvoicesfrom',
  CurrentInvoiceNumber = 'currentinvoicenumber',
  ManualInvoiceNumber = 'manualInvoiceNumber',
  CurrentFiscalMonth = 'currentFiscalMonth',
  InvoiceFiscalMonth = 'invoiceFiscalMonth',
  DocDefSchemaSet = 'docdefschemaset',
  TotalsByInvoice = 'totalsByInvoice',
  HoursByInvoice = 'hoursByInvoice',
  InvoicedHours = 'invoicesHours',
  InvoicedTotal = 'invoicedTotal',
  Credentials = 'credentials',
  MyTokens = 'mytokens',
  WhoAmI = 'whoami',
  Token = 'token',
  Me = 'me',
}

export enum CacheKeys {
  LastUrlWithParams = 'lastUrlWithParams',
  LastRequest = 'lastRequest',
  TimeTracked = 'timeTracked',
  AppState = 'appState',
  History = 'history',
  CustomerAddresses = 'customeraddresses',
  Clients = 'clients',
  Customers = 'customers',
  Departments = 'departments',
  Projects = 'projects',
  Tasks = 'tasks',
  Timetracks = 'timetracks',
  TypesOfWork = 'typesofwork',
  UserRates = 'userrates',
  Users = 'users',
  WorkflowStatuses = 'workflowstatus',
  AuditLogNodes = 'auditlognodes',
  AuditLogs = 'auditlogs',
  AppSettings = 'appSettings',
  InvoiceStartDate = 'invoiceStartDate',
  InvoiceEndDate = 'invoiceEndDate',
  FeedbackComments = 'feedbackcomments',
  CurrentFiscalMonth = 'currentFiscalMonth',
  InvoiceFiscalMonth = 'invoiceFiscalMonth',
  TotalsByInvoice = 'totalsByInvoice',
  HoursByInvoice = 'hoursByInvoice',
  InvoicedHours = 'invoicesHours',
  InvoicedTotal = 'invoicedTotal',
  Credentials = 'credentials',
  Token = 'token',
}

export enum BackupKeys {
  GeneratedInvoices = 'invoices-generated',
  CorrectedInvoices = 'invoices-corrected',
  ApprovedInvoices = 'invoices-approved',
  VerifiedInvoices = 'invoices-verified',
  TotalsByInvoice = 'totalsbyinvoice',
  HoursByInvoice = 'hoursbyinvoice',
  CustomerAddresses = 'customeraddresses',
  WorkflowStatuses = 'workflowstatuses',
  AuditLogNodes = 'auditlognodes',
  Departments = 'departments',
  TypesOfWork = 'typesofwork',
  Timetracks = 'timetracks',
  Customers = 'customers',
  UserRates = 'userrates',
  AuditLogs = 'auditlogs',
  Projects = 'projects',
  Invoices = 'invoices',
  Clients = 'clients',
  History = 'history',
  DocDefs = 'docdefs',
  Tasks = 'tasks',
  Users = 'users',
}

export enum DocMarkKeys {
  InvoiceLogo = 'invoicelogo',
  InvoiceTitle = 'invoicetitle',
  InvoiceSender = 'invoicesenderinfo',
  InvoiceRecipient = 'invoicerecipientinfo',
  InvoiceParsedProps = 'invoiceparsedprops',
  InvoiceProjectInfo = 'invoiceprojecttitle',
  InvoiceProjectLocation = 'invoiceprojectlocation',
  InvoiceSummaryTable = 'invoicesummarytable',
  TimesheetTablePreamble = 'timesheettablepreamble',
  TimesheetTable = 'timesheettable',
}

export enum DocMarkLabels {
  InvoiceLogo = 'Invoice Logo',
  InvoiceTitle = 'Invoice Title',
  InvoiceSender = 'Invoice Sender Information',
  InvoiceRecipient = 'Invoice Recipient Info',
  InvoiceParsedProps = 'Invoice Parsed Properties',
  InvoiceProjectInfo = 'Invoice Project Title',
  InvoiceProjectLocation = 'Invoice Project Location',
  InvoiceSummaryTable = 'Invoice Summary  Table',
  TimesheetTablePreamble = 'Timesheet Table Preamble',
  TimesheetTable = 'Timesheet Table',
}

