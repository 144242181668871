<!-- <span class="login-logo">
  <img class="login-branding" src="assets/brand/ncal.webp" alt="NCAL" />
</span>
<mat-card class="login-card">
  <mat-card-title class="login-card-title" translate>
    <mat-basic-chip
      class="autofill-chip"
      [matMenuTriggerFor]="autofillMenu">
      Autofill
    </mat-basic-chip>
    <mat-menu
      #autofillMenu="matMenu"
      class="autofill-menu-panel">
      <div class="autofill-content" mat-menu-content>
        <span class="autofill-opt-list">
          <button
            mat-menu-item
            class="autofill-option"
            (click)="autofillDeveloper()">
            Developer
          </button>
          <button
            mat-menu-item
            class="autofill-option"
            (click)="autofillAdmin()">
            Admin
          </button>
          <button
            mat-menu-item
            class="autofill-option"
            (click)="autofillUser()">
            User
          </button>
        </span>
      </div>
    </mat-menu>
    <mat-basic-chip
      class="options-chip"
      [matMenuTriggerFor]="optionsMenu">
      LoginOptions
    </mat-basic-chip>
    <mat-menu
      #optionsMenu="matMenu"
      class="options-menu-panel">
      <div class="options-content" mat-menu-content>
        <span class="login-opt-list">
          <button
            mat-menu-item
            class="login-option"
            (click)="loginNormally()">
            Email & Password
          </button>
          <button
            mat-menu-item
            class="login-option"
            (click)="sendMagicLink()">
            Send me a Magic Link
          </button>
          <button
            mat-menu-item
            class="login-option"
            (click)="loginSSO()">
            Google SSO
          </button>
        </span>
      </div>
    </mat-menu>
    Login
  </mat-card-title>
  <mat-card-subtitle class="login-card-subtitle">Use the same credentials that you use to login to the ActiTime Web Application</mat-card-subtitle>
  <mat-card-content class="login-card-content">
    <form class="login-form" [formGroup]="loginForm" novalidate>
      <mat-form-field [hideRequiredMarker]="true" class="login-form-field" appearance="outline" floatLabel="always">
        <mat-label for="email" translate>Email</mat-label>
        <input type="text" matInput class="auth-input" formControlName="email" autocomplete="email" [placeholder]="'Enter your email...' | translate" required/>
        <mat-error *ngIf="!loginForm.controls['email'].value.length && loginForm.controls['email'].touched">
          <span translate>Email is required.</span>
        </mat-error>
        <mat-error *ngIf="loginForm.controls['email'].invalid && loginForm.controls['email'].touched">
          <span translate>Email must be a valid address.</span>
        </mat-error>
      </mat-form-field>
      <mat-form-field [hideRequiredMarker]="true" class="login-form-field" appearance="outline" floatLabel="always">
        <mat-label for="password" translate>Password</mat-label>
        <input type="password" matInput class="auth-input" formControlName="password" [autocomplete]="false" [placeholder]="'Enter your password...' | translate" required/>
        <mat-error *ngIf="loginForm.controls['password'].invalid && loginForm.controls['password'].touched">
          <span translate>Password is required.</span>
        </mat-error>
      </mat-form-field>
    </form>
  </mat-card-content>
  <mat-card-actions class="login-card-actions">
    <button mat-raised-button class="dev-login-btn" (click)="developerLogin()" [disabled]="isLoading">
      <ncal-loader *ngIf="!isLoading" class="inline-loader"></ncal-loader>
      Developer Login
    </button>
    <button mat-raised-button class="login-btn" (click)="login()" [disabled]="isLoading">
      <ncal-loader *ngIf="!isLoading" class="inline-loader"></ncal-loader>
      Login
    </button>
  </mat-card-actions>
</mat-card> -->

<!--
<span class="login-logo">
  <img class="login-branding" src="assets/brand/ncal.webp" alt="NCAL" />
</span>
<mat-card class="login-card">
  <mat-card-title class="login-card-title" translate>
    Login
  </mat-card-title>
  <mat-card-subtitle class="login-card-subtitle">Use the same credentials that you use to login to the ActiTime Web Application</mat-card-subtitle>
  <mat-card-content class="login-card-content">
    <div class="login-container">
      <form [formGroup]="loginForm" (ngSubmit)="login()">
        <div>
          <label for="email">Email</label>
          <input id="email" formControlName="email" type="email" />
        </div>
        <div>
          <label for="password">Password</label>
          <input id="password" formControlName="password" type="password" />
        </div>
        <button type="submit">Login</button>
      </form>
      <button (click)="loginWithGoogle()">Login with Google</button>
    </div>
  </mat-card-content>
</mat-card>
-->

<span class="login-logo">
  <img class="login-branding" src="assets/brand/ncal.webp" alt="NCAL" />
</span>
<mat-card class="login-card">
  <mat-card-title class="login-card-title" translate>
    Login
  </mat-card-title>
  <mat-card-subtitle class="login-card-subtitle">
    Use the same credentials that you use to login to the ActiTime Web Application
  </mat-card-subtitle>
  <mat-card-content class="login-card-content">
    <div class="login-container">
      <form class="login-form" [formGroup]="loginForm" novalidate>
        <mat-form-field [hideRequiredMarker]="true" class="login-form-field" appearance="outline" floatLabel="always">
          <mat-label for="email" translate>Email</mat-label>
          <input type="text" matInput class="auth-input" formControlName="email" autocomplete="email" [placeholder]="'Enter your email...' | translate" required/>
          <mat-error *ngIf="!loginForm.controls['email'].value.length && loginForm.controls['email'].touched">
            <span translate>Email is required.</span>
          </mat-error>
          <mat-error *ngIf="loginForm.controls['email'].invalid && loginForm.controls['email'].touched">
            <span translate>Email must be a valid address.</span>
          </mat-error>
        </mat-form-field>
        <mat-form-field [hideRequiredMarker]="true" class="login-form-field" appearance="outline" floatLabel="always">
          <mat-label for="password" translate>Password</mat-label>
          <input type="password" matInput class="auth-input" formControlName="password" [autocomplete]="false" [placeholder]="'Enter your password...' | translate" required/>
          <mat-error *ngIf="loginForm.controls['password'].invalid && loginForm.controls['password'].touched">
            <span translate>Password is required.</span>
          </mat-error>
        </mat-form-field>
      </form>
      <button (click)="loginWithGoogle()">Login with Google</button>
    </div>
  </mat-card-content>
</mat-card>
