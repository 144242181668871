import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';

@Injectable()
export class ReuseRouteStrategy implements RouteReuseStrategy {
  private routeStore = new Map<string, DetachedRouteHandle>();

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    const path = route.routeConfig.path;
    return path && ['compA', 'compB'].includes(path);
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    this.routeStore.set(route.routeConfig.path, handle);
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    const path = route.routeConfig.path;
    return (
      path && ['compA', 'compB'].includes(path) && !!this.routeStore.get(path)
    );
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    const path = route.routeConfig.path;
    return this.routeStore.get(path);
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return future.routeConfig === curr.routeConfig;
  }
}

/**
 * A route strategy allowing for explicit route reuse.
 * Used as a workaround for https://github.com/angular/angular/issues/18374
 * To reuse a given route, add `data: { reuse: true }` to the route definition.
 */
// @Injectable()
// export class ReuseRouteStrategy extends RouteReuseStrategy {
//   public shouldDetach(route: ActivatedRouteSnapshot): boolean {
//     return false;
//   }

//   public store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle | null): void {}

//   public shouldAttach(route: ActivatedRouteSnapshot): boolean {
//     return false;
//   }

//   public retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
//     return null;
//   }

//   public shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
//     // Reuse the route if the RouteConfig is the same, or if both routes use the
//     // same component, because the latter can have different RouteConfigs.
//     return (
//       future.routeConfig === curr.routeConfig ||
//       Boolean(future.routeConfig?.component && future.routeConfig?.component === curr.routeConfig?.component)
//     );
//   }
// }
