import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NcalMaterialModule } from '@material/index';
import { CommonModule } from '@angular/common';
import { AgGridModule } from 'ag-grid-angular';
import { LoaderModule } from '@loader/index';
import { GridComponent } from './grid.component';
import { GridService } from './grid.service';

@NgModule({
  imports: [CommonModule, AgGridModule, LoaderModule, TranslateModule, NcalMaterialModule],
  declarations: [GridComponent],
  exports: [GridComponent],
  providers: [GridService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class GridModule {}
