import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NcalMaterialModule } from '@material/index';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HistoryComponent } from './history.component';
import { DialogModule } from '@dialog/dialog.module';
import { HistoryService } from './history.service';
import { SafePipe } from '@pipes/safe.pipe';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    NcalMaterialModule,
    DialogModule,
    SafePipe,
  ],
  declarations: [HistoryComponent],
  exports: [HistoryComponent],
  providers: [HistoryService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class HistoryModule {}
