import { inject } from '@angular/core';
import {
  DateRange,
  Client,
  Customer,
  CustomerAddress,
  InvoiceRecipients,
  Project,
  Task,
  User,
  UserRate,
  TimeTrack,
  TypeOfWork,
  FiscalYear,
  WorkOnSubtask,
  ProjectWithTasks,
  CustomerInvoiceBlob,
  MarkedDocumentContent,
  DocTableRowDefs,
  DocumentMarker,
  DocStyleSchema,
  DocColDef,
  MarkerFlag,
  InvoiceProject,
} from '@data/models';
import {
  StyleDef,
  DOCHEADER,
  PAGEHEADER,
  PAGESUBHEADER,
  INVOICEBLOCKHEADER,
  BLOCKHEADER,
  INVOICEBLOCKTEXT,
  BLOCKTEXT,
  BLOCKTEXTVAL,
  INVOICETABLE,
  TIMESHEETSUMMARYTABLE,
  TIMESHEETTABLE,
  INVOICECOLHEADER,
  INVOICEFIRSTCOLCELL,
  INVOICEMIDCOLCELL,
  INVOICELASTCOLCELL,
  BLANKNEWLINE,
  SIGNATUREFOOTER,
  TIMESHEETLOGO,
  TABLEROWLABEL,
  TABLEROWVALUE,
  TIMESHEETCOLHEADER,
  TIMESHEETFIRSTCOLCELL,
  TIMESHEETFIRSTCOLCELLPAD1LEFT,
  TIMESHEETFIRSTCOLCELLPAD2LEFT,
  TIMESHEETMIDCOLCELL,
  TIMESHEETLASTCOLCELL,
  TIMESHEETMULTIROWRIGHTCOL,
} from '@invoice/invoice.model';
import { DocumentMarkerFlags } from '@data/constants';
import { StorageKeys, BackupKeys } from '@data/enums';
import { parseFromProjectName, processDate } from '@data/utils';
import { isWithinInterval, startOfDay, endOfDay, addMonths } from 'date-fns';

export class InvoicingLogic {

  getCustomerProjectData(
    clients: Client[],
    customerAddresses: CustomerAddress[],
    customers: Customer[],
    projects: Project[]
  ): InvoiceRecipients {
    console.log(clients);
    console.log(customerAddresses);
    console.log(customers);
    console.log(projects);
    const activecustomers = customers.filter((cust) => !cust.archived);
    const customerprojectdata: CustomerInvoiceBlob[] | any[] = activecustomers
      .map((ac: Customer) => {
        const address = customerAddresses.filter((ca: CustomerAddress) => ca.customerid === ac.id)[0] || {};
        const customerprojects = projects.filter((proj) => proj.customerId === ac.id);
        const client = clients.find((cli) => cli.address?.streetaddress === address['streetaddress']) || {};
        return { ...ac, client, address, projects: customerprojects };
      })
      .filter((cust) => cust.projects.length > 0);
    return { customers: customerprojectdata };
  }

  getWorkDone(cpdata: InvoiceRecipients, tasks: Task[], typesofwork: TypeOfWork[]): InvoiceRecipients {
    const taskmap = new Map(tasks.map((task) => [`${task.customerId}-${task.projectId}`, []]));
    tasks.map((task) => {
      taskmap.get(`${task.customerId}-${task.projectId}`).push(task);
    });
    const typeofworkmap = new Map(typesofwork.map((tow) => [tow.id, tow]));
    const customerprojecttasks = cpdata.customers.map((cust) => {
      const projectswithtasks: ProjectWithTasks = cust.projects.map((project) => {
        const projecttasks = taskmap.get(`${project.customerId}-${project.id}`) || [];
        const taskswithtypeofwork = projecttasks.map((task) => ({
          ...task,
          typeOfWork: typeofworkmap.get(task.typeOfWorkId) || {},
        }));
        return { ...project, tasks: taskswithtypeofwork };
      });
      return { ...cust, projects: projectswithtasks };
    });
    return { customers: customerprojecttasks };
  }

  getWorkedDetails(
    cpdata: InvoiceRecipients,
    timetracks: TimeTrack[],
    userrates: UserRate[],
    users: User[],
    mockDate?: DateRange | null
  ): InvoiceRecipients {
    const activeUsers = users.filter((user) => user.active || !user.active);
    const invoiceRange = this.getCurrentMonthRange(mockDate);
    const inRangeTimeTracks = timetracks.filter((tt) =>
      isWithinInterval(new Date(tt.date), {
        start: startOfDay(new Date(invoiceRange.from)),
        end: endOfDay(new Date(invoiceRange.til)),
      })
    );
    const customerworktoinvoice = cpdata.customers
      .map((cust) => {
        const customerprojects = cust.projects
          .map((project) => {
            const projectTasks = project.tasks
              .map((task) => {
                const timeTracksOnTask = inRangeTimeTracks
                  .map((tt) => {
                    const records = tt.records.filter((rec) => rec.taskId === task.id);
                    const user = activeUsers.find((u) => u.id === tt.userId);
                    const userRate = userrates.find((ur) => ur.userId === tt.userId);
                    return { ...tt, user, userRate, records };
                  })
                  .filter((tt) => tt.records.length > 0);
                return { ...task, timetracks: timeTracksOnTask };
              })
              .filter((task) => task.timetracks.length > 0);
            return { ...project, tasks: projectTasks };
          })
          .filter((project) => project.tasks.length > 0);
        return { ...cust, projects: customerprojects };
      })
      .filter((cust) => cust.projects.length > 0);

    return { customers: customerworktoinvoice };
  }

  resetByInvoiceData(): void {
    console.log('clearing by-invoice datasets...');
    localStorage.setItem(BackupKeys.HoursByInvoice, JSON.stringify([]));
    localStorage.setItem(BackupKeys.TotalsByInvoice, JSON.stringify([]));
  }

  private getCurrentMonthRange(mockDate?: DateRange | null): DateRange {
    const invoicemonth = { label: 'September', value: 9 };
    const day = 15;
    const month = invoicemonth.label;
    let year = new Date(Date.now()).getFullYear();
    if (invoicemonth.value === 0) year -= 1;
    const reference = new Date(`${month} ${day}, ${year}`);
    const from = startOfDay(addMonths(reference, 0));
    const til = endOfDay(addMonths(reference, 0));
    return { from: from.toISOString(), til: til.toISOString() };
  }

  getFiscalYears(): FiscalYear[] {
    return [
      { start: '12-01-2023', end: '11-30-2024', year: 24 },
      { start: '12-01-2024', end: '11-30-2025', year: 25 },
      { start: '12-01-2025', end: '11-30-2026', year: 26 },
      { start: '12-01-2027', end: '11-30-2028', year: 27 },
      { start: '12-01-2028', end: '11-30-2029', year: 28 },
      { start: '12-01-2029', end: '11-30-2030', year: 29 },
    ];
  }

  getDocumentStyles(): DocStyleSchema {
    return {
      docheader: {
        bold: true,
        fontSize: 24,
        alignment: 'right',
        color: 'light-gray',
        margin: [0, -30, 0, 10],
      },
      pageheader: {
        bold: true,
        fontSize: 18,
        alignment: 'left',
        margin: [0, 0, 0, 10],
      },
      pagesubheader: {
        bold: false,
        fontSize: 16,
        alignment: 'left',
        margin: [0, -33, 0, 10],
      },
      invoiceblockheader: {
        bold: true,
        fontSize: 12,
        margin: [0, -30, 0, 10],
        wordWrap: 'break-word',
      },
      blockheader: {
        bold: true,
        fontSize: 12,
        marginTop: 10,
        wordWrap: 'break-word',
      },
      invoiceblocktext: {
        bold: false,
        fontSize: 12,
        margin: [0, -30, 0, 10],
        wordWrap: 'break-word',
      },
      blocktext: {
        bold: false,
        fontSize: 12,
        marginTop: 10,
        wordWrap: 'break-word',
      },
      blocktextval: {
        bold: false,
        fontSize: 12,
        marginTop: 10,
        wordWrap: 'break-word',
      },
      invoicetable: {
        display: 'inline-table',
      },
      timesheetsummarytable: {
        marginTop: 20,
        display: 'inline-table',
      },
      timesheettable: {
        marginTop: 20,
        display: 'inline-table',
      },
      invoicecolheader: {
        bold: true,
        fontSize: 12,
        alignment: 'center',
      },
      invoicefirstcolcell: {
        bold: false,
        fontSize: 12,
        alignment: 'left',
      },
      invoicemidcolcell: {
        bold: false,
        fontSize: 12,
        alignment: 'center',
      },
      invoicelastcolcell: {
        bold: false,
        fontSize: 12,
        alignment: 'right',
      },
      blanknewline: {
        width: '100%',
        height: '10px',
        display: 'block',
      },
      signaturefooter: {
        marginTop: 50,
        alignment: 'center',
      },
      timesheetlogo: {
        alignment: 'right',
        margin: [0, -30, 0, 0],
      },
      tablerowlabel: {
        bold: true,
        alignment: 'left',
      },
      tablerowvalue: {
        bold: false,
        alignment: 'left',
      },
      timesheetcolheader: {
        bold: true,
        fontSize: 12,
        paddingTop: 10,
        color: 'light-gray',
        alignment: 'center',
      },
      timesheetfirstcolcell: {
        bold: false,
        fontSize: 10,
        alignment: 'left',
      },
      'timesheetfirstcolcell-pad1left': {
        bold: false,
        fontSize: 10,
        alignment: 'center',
      },
      'timesheetfirstcolcell-pad2left': {
        bold: false,
        fontSize: 10,
        alignment: 'right',
      },
      timesheetmidcolcell: {
        bold: false,
        fontSize: 10,
        alignment: 'center',
      },
      timesheetlastcolcell: {
        bold: false,
        fontSize: 10,
        alignment: 'right',
      },
      timesheetmultirowrightcol: {
        fontSize: 10,
        alignment: 'right',
      },
    };
  }

  getDocumentExportStyles(): DocStyleSchema {
    return {
      docheader: { ...DOCHEADER },
      pageheader: { ...PAGEHEADER },
      pagesubheader: { ...PAGESUBHEADER },
      invoiceblockheader: { ...INVOICEBLOCKHEADER },
      blockheader: { ...BLOCKHEADER },
      invoiceblocktext: { ...INVOICEBLOCKTEXT },
      blocktext: { ...BLOCKTEXT },
      blocktextval: { ...BLOCKTEXTVAL },
      invoicetable: { ...INVOICETABLE },
      timesheetsummarytable: { ...TIMESHEETSUMMARYTABLE },
      timesheettable: { ...TIMESHEETTABLE },
      invoicecolheader: { ...INVOICECOLHEADER },
      invoicefirstcolcell: { ...INVOICEFIRSTCOLCELL },
      invoicemidcolcell: { ...INVOICEMIDCOLCELL },
      invoicelastcolcell: { ...INVOICELASTCOLCELL },
      blanknewline: { ...BLANKNEWLINE },
      signaturefooter: { ...SIGNATUREFOOTER },
      timesheetlogo: { ...TIMESHEETLOGO },
      tablerowlabel: { ...TABLEROWLABEL },
      tablerowvalue: { ...TABLEROWVALUE },
      timesheetcolheader: { ...TIMESHEETCOLHEADER },
      timesheetfirstcolcell: { ...TIMESHEETFIRSTCOLCELL },
      'timesheetfirstcolcell-pad1left': { ...TIMESHEETFIRSTCOLCELLPAD1LEFT },
      'timesheetfirstcolcell-pad2left': { ...TIMESHEETFIRSTCOLCELLPAD2LEFT },
      timesheetmidcolcell: { ...TIMESHEETMIDCOLCELL },
      timesheetlastcolcell: { ...TIMESHEETLASTCOLCELL },
      timesheetmultirowrightcol: { ...TIMESHEETMULTIROWRIGHTCOL },
    };
  }

  getTotalWorkBilledRow(project: string, totalworkbilled: string): DocColDef[] {
    return [
      {
        text: project,
        style: 'timesheetfirstcolcell',
        border: [true, true, false, true],
      },
      {
        text: '  ',
        style: 'timesheetmidcolcell',
        border: [false, true, false, true],
      },
      {
        text: '  ',
        style: 'timesheetmidcolcell',
        border: [false, true, false, true],
      },
      {
        text: '  ',
        border: [false, true, false, true],
        style: 'timesheetmultirowrightcol',
      },
      {
        text: totalworkbilled,
        style: 'timesheetmidcolcell',
      },
      {
        text: '  ',
        style: 'timesheetlastcolcell',
      },
    ];
  }

  getTotalUserWorkedRow(userfullname: string, userworked: string): DocColDef[] {
    return [
      {
        text: userfullname,
        style: 'timesheetfirstcolcell-pad1left',
        border: [true, true, false, true],
      },
      {
        text: '  ',
        style: 'timesheetmidcolcell',
        border: [false, true, false, true],
      },
      {
        text: '  ',
        style: 'timesheetmidcolcell',
        border: [false, true, false, true],
      },
      {
        text: '  ',
        border: [false, true, false, true],
        style: 'timesheetmultirowrightcol',
      },
      {
        text: userworked,
        style: 'timesheetmidcolcell',
      },
      {
        text: '  ',
        style: 'timesheetlastcolcell',
      },
    ];
  }

  getInvoiceSummaryLogisitics(rowtype: string, metadata?: any): DocColDef[] {
    return rowtype === 'receiptinfo'
      ? [
          {
            width: 100,
            text: 'BILL TO:',
            style: 'blockheader',
          },
          {
            style: 'invoiceblockheader',
            width: 195,
            text: '',
          },
          {
            width: '*',
            style: 'invoiceblockheader',
            type: 'none',
            ul: ['DATE:', 'INVOICE #:', 'PROJECT #:'],
          },
          {
            width: '*',
            style: 'invoiceblocktext',
            type: 'none',
            ul: [
              `${new Date().toLocaleDateString()}`,
              `${metadata.invoicenumber}`,
              `${parseFromProjectName('projectnum', metadata.project.customerName)}`,
            ],
          },
        ]
      : rowtype === 'senderinfo'
      ? [
          {
            width: 180,
            text: '210, 323 - 10th Avenue SW\nCalgary, Alberta, T2R 0A5\nPhone (403) 460-5000',
          },
          {
            width: '*',
            text: '',
          },
        ]
      : rowtype === 'parsedinfo'
      ? [
          {
            width: 280,
            style: 'blocktext',
            type: 'none',
            ul: Object.keys(metadata.address).length
              ? [
                  `${metadata.customerclient}`,
                  `${metadata.address.streetaddress}`,
                  `${metadata.address.city}, ${metadata.address.province} ${metadata.address.postal}`,
                  `Attn: Accounts Payable`,
                ]
              : [`${metadata.customerclient}`, `Attn: Accounts Payable`],
          },
          {
            width: 5,
            text: '',
          },
          {
            width: '*',
            style: 'blockheader',
            type: 'none',
            ul: ['PO/AFE #:', 'APPROVER #:', 'AGREEMENT NO.:', 'WORK ORDER NO.:'],
          },
          {
            width: 100,
            style: 'blocktextval',
            type: 'none',
            ul: [
              `${parseFromProjectName('poafenum', metadata.project.customerName)}`,
              `${parseFromProjectName('approvernum', metadata.project.customerName)}`,
              ` `,
              ` `,
            ],
          },
        ]
      : rowtype === 'projectinfo'
      ? [
          {
            width: 100,
            style: 'blockheader',
            text: `Project Name: `,
          },
          {
            width: 160,
            style: 'blocktext',
            text: `${parseFromProjectName('projectname', metadata.project.customerName)}`,
          },
          {
            width: '*',
            text: '',
          },
        ]
      : [
          {
            width: 60,
            text: 'LSD:',
            style: 'blocktext',
          },
          {
            width: '*',
            text: '',
          },
        ];
  }

  subTotalRow(nogstsubtotal: number): DocColDef[] {
    return [
      // subtotal row
      {
        text: '  ',
        style: 'invoicefirstcolcell',
        border: [false, false, false, false],
      },
      {
        text: '  ',
        style: 'invoicemidcolcell',
        border: [false, false, false, false],
      },
      {
        text: 'SUBTOTAL',
        style: 'invoicemidcolcell',
        border: [false, false, false, false],
      },
      {
        text: `$${parseFloat(nogstsubtotal.toString()).toFixed(2)}`,
        fillColor: '#dddddd',
        style: 'invoicelastcolcell',
        border: [true, true, true, true],
      },
    ];
  }

  disbursementsRow(invoicehours: number, disbrate: number, disbursementscharge: number): DocColDef[] {
    return [
      // dispursements row
      {
        text: 'Disbursements @ $8.00 per hour',
        style: 'invoicefirstcolcell',
        border: [true, false],
      },
      {
        text: `${invoicehours.toFixed(2)}`,
        style: 'invoicemidcolcell',
        border: [true, false],
      },
      {
        text: `$${disbrate}.00`,
        style: 'invoicemidcolcell',
        border: [true, false],
      },
      {
        text: `$${disbursementscharge.toFixed(2)}`,
        border: [true, false, true, false],
        style: 'invoicelastcolcell',
        fillColor: '#dddddd',
      },
    ];
  }

  emptyRow(): DocColDef[] {
    return [
      // blank row
      {
        text: '  ',
        style: 'invoicefirstcolcell',
        border: [true, false],
      },
      {
        text: '  ',
        style: 'invoicemidcolcell',
        border: [true, false],
      },
      {
        text: '  ',
        style: 'invoicemidcolcell',
        border: [true, false],
      },
      {
        text: '  ',
        fillColor: '#dddddd',
        style: 'invoicelastcolcell',
        border: [true, false, true, false],
      },
    ];
  }

  invoiceSummaryHeaderRow(): DocColDef[] {
    return [
      { text: 'DESCRIPTION', style: 'invoicecolheader', fillColor: '#dddddd' },
      { text: 'HOURS', style: 'invoicecolheader', fillColor: '#dddddd' },
      { text: 'RATE', style: 'invoicecolheader', fillColor: '#dddddd' },
      { text: 'AMOUNT', style: 'invoicecolheader', fillColor: '#dddddd' },
    ];
  }

  invoiceSummarySubHeaderRow(): DocColDef[] {
    return [
      {
        text: 'Engineering, Design & Drafting Services',
        style: 'invoicefirstcolcell',
        border: [true, false],
      },
      {
        text: '',
        style: 'invoicemidcolcell',
        border: [true, false],
      },
      {
        text: '',
        style: 'invoicemidcolcell',
        border: [true, false],
      },
      {
        text: '',
        fillColor: '#dddddd',
        style: 'invoicelastcolcell',
        border: [true, false, true, false],
      },
    ];
  }

  invoiceSummaryDateRangeRow(daterange: DateRange): DocColDef[] {
    return [
      {
        text: `${daterange.from} to ${daterange.til} per attached timesheet`,
        style: 'invoicefirstcolcell',
        border: [true, false],
      },
      {
        text: '',
        style: 'invoicemidcolcell',
        border: [true, false],
      },
      {
        text: '',
        style: 'invoicemidcolcell',
        border: [true, false],
      },
      {
        text: '',
        fillColor: '#dddddd',
        style: 'invoicelastcolcell',
        border: [true, false, true, false],
      },
    ];
  }

  invoiceSummaryLastEmptyRow(): DocColDef[] {
    return [
      // last blank column
      {
        text: '  ',
        style: 'invoicefirstcolcell',
        border: [true, false, true, true],
      },
      {
        text: '  ',
        style: 'invoicemidcolcell',
        border: [true, false, true, true],
      },
      {
        text: '  ',
        style: 'invoicemidcolcell',
        border: [true, false, true, true],
      },
      {
        text: '  ',
        fillColor: '#dddddd',
        style: 'invoicelastcolcell',
        border: [true, false, true, true],
      },
    ];
  }

  taxRateRow(
    busnumlabel: string,
    ncalbusnum: string,
    taxratelabel: string,
    taxratevalue: string,
  ): DocColDef[] {
    return [
      // tax rate row
      {
        text: `${busnumlabel}: ${ncalbusnum}`,
        style: 'invoicefirstcolcell',
        border: [false, false, false, false],
      },
      {
        text: '  ',
        style: 'invoicemidcolcell',
        border: [false, false, false, false],
      },
      {
        text: `${taxratelabel}`,
        style: 'invoicemidcolcell',
        border: [false, false, false, false],
      },
      {
        text: `${taxratevalue}`,
        style: 'invoicelastcolcell',
        border: [true, true, true, true],
      },
    ];
  }

  salesTaxRow(ncalfullname: string, salestaxlabel: string, formattedgst: string): DocColDef[] {
    return [
      // sales tax row
      {
        text: ncalfullname,
        style: 'invoicefirstcolcell',
        border: [false, false, false, false],
      },
      {
        text: '  ',
        style: 'invoicemidcolcell',
        border: [false, false, false, false],
      },
      {
        text: salestaxlabel,
        style: 'invoicemidcolcell',
        border: [false, false, false, false],
      },
      {
        text: formattedgst,
        style: 'invoicelastcolcell',
        fillColor: '#dddddd',
        border: [true, true, true, true],
      },
    ];
  }

  otherSummaryRow(otherlabel: string): DocColDef[] {
    return [
      // other row
      {
        text: '  ',
        style: 'invoicefirstcolcell',
        border: [false, false, false, false],
      },
      {
        text: '  ',
        style: 'invoicemidcolcell',
        border: [false, false, false, false],
      },
      {
        text: otherlabel,
        style: 'invoicemidcolcell',
        border: [false, false, false, false],
      },
      {
        text: '  ',
        style: 'invoicelastcolcell',
        border: [true, true, true, true],
      },
    ];
  }

  summaryTotalRow(totallabel: string, formattedtotal: string): DocColDef[] {
    return [
      // total row
      {
        text: '  ',
        style: 'invoicefirstcolcell',
        border: [false, false, false, false],
      },
      {
        text: '  ',
        style: 'invoicemidcolcell',
        border: [false, false, false, false],
      },
      {
        text: totallabel,
        style: 'invoicemidcolcell',
        border: [false, false, false, false],
      },
      {
        text: formattedtotal,
        style: 'invoicelastcolcell',
        fillColor: '#dddddd',
        border: [true, true, true, true],
      },
    ];
  }

  timesheetTableHeaderRow(): DocColDef[] {
    return [
      {
        style: 'timesheetcolheader',
        text: 'Projects / Users / Tasks',
      },
      {
        text: 'Subtask',
        style: 'timesheetcolheader',
      },
      {
        text: 'Type of Work',
        style: 'timesheetcolheader',
      },
      {
        text: 'Day',
        style: 'timesheetcolheader',
      },
      {
        text: 'Spent Time',
        style: 'timesheetcolheader',
      },
      {
        text: 'Comments',
        style: 'timesheetcolheader',
      },
    ];
  }

  getBlankRow(cols: number, def: DocColDef): DocColDef[] {
    const rowdefkeys = Object.keys(def);
    const rowdefvals = Object.values(def);
    let blankrow: DocColDef[] = [];
    for (let i = 0; i < cols; i++) {
      let coldef: DocColDef = { text: '  ' };
      coldef[rowdefkeys[i]] = rowdefvals[i];
      blankrow.push(coldef);
    }
    return blankrow;
  }

  formatCurrency(
    value: number,
    locale: string = 'en-US',
    currency: string = 'CAD',
  ): string {
    const fullcurrencyformat: string = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency
    }).format(value);
    return fullcurrencyformat.substring(2);
  }

  markReadOnly(key: string, label?: string): DocumentMarker {
    // const utils = inject(UtilityService);
    const docmark: DocumentMarker = {
      key,
      editable: false,
      label: label ?? this.capitalCase(key),
    };
    return docmark;
  }

  markEditable(key: string, label?: string): DocumentMarker {
    // const this = inject(UtilityService);
    const docmark: DocumentMarker = {
      key,
      editable: true,
      label: label ?? this.capitalCase(key),
    };
    return docmark;
  }

  markAutoLabel(key: string, editable: boolean): DocumentMarker {
    // const this = inject(UtilityService);
    const docmark: DocumentMarker = {
      key,
      editable,
      label: this.capitalCase(key),
    };
    return docmark;
  }

  markAs(key: string, label: string, editable: boolean): DocumentMarker {
    // const this = inject(UtilityService);
    const docmark: DocumentMarker = {
      key,
      label,
      editable,
    };
    return docmark;
  }

  capitalCase(value: string): string {
    return value.charAt(0).toUpperCase() + value.substring(1);
  }
}
