import { Injectable } from '@angular/core';
import { AuditService } from '@services/audit.service';

@Injectable({
  providedIn: 'root',
})
export class HistoryService {
  constructor(
    private auditor: AuditService,
  ) {}



}
