import dayjs from 'dayjs';

declare global {
  interface DateConstructor {
    asDayJSDate: (value: Date) => dayjs.Dayjs;
  }
}

function extend() {
  if (!Date.asDayJSDate) {
    Date.asDayJSDate = (value: Date) => {
      // Need to handle timezone stuff here like moment.parseZone?

      // const date = dayjs(value);
      // const values = [date.year(), date.month(), date.date()];
      // const result = dayjs(values);
      return dayjs(value);
    };
  }
}

extend();
export { extend as DateExtensions };
