// import { Component } from '@angular/core';
// import { Router, ActivatedRoute } from '@angular/router';
// import { FormGroup, FormBuilder, Validators } from '@angular/forms';
// import { StorageService, UntilDestroy, untilDestroyed } from '@core/index';
// import useraccounts from '@jsonassets/useraccounts.json';
// import { AuthenticationService } from '@auth/services';
// import { LoginCredentials, WhoAmI, MyTokens } from '@auth/auth.model';
// import { AuthService } from '@auth/services';
// import firebase from 'firebase/compat/app';
// import { Logger } from '@logger';

// const log = new Logger('LoginComponent');

// @UntilDestroy()
// @Component({
//   selector: 'ncal-login',
//   templateUrl: './login.component.html',
//   styleUrls: ['./login.component.css'],
// })
// export class LoginComponent {
//   validLogins: any[] = useraccounts;
//   errormessage: string = '';
//   error: string | undefined;
//   loginForm!: FormGroup;
//   password: string = '';
//   email: string = '';
//   isLoading = false;

//   constructor(
//     private router: Router,
//     private auth: AuthService,
//     private formBuilder: FormBuilder,
//     private authService: AuthenticationService,
//   ) {
//     this.createForm();
//   }

//   async login(): Promise<void> {
//     this.isLoading = true;
//     this.loginForm.controls['role'].patchValue('user');
//     const payload = this.loginForm.value;
//     const credentials: LoginCredentials = {
//       email: payload.email,
//       password: payload.password,
//       role: payload.role,
//     };
//     const validix: number = this.validLogins.findIndex((account: any) => {
//       return (
//         account.email === payload.email &&
//         account.username === payload.password &&
//         account.roles.includes(payload.role)
//       );
//     });
//     if (validix !== -1) {
//       this.isLoading = false;
//       this.authService.setLoggedIn(credentials, payload.remember, {success: true, data: 'token'});
//       this.authService.updateRoutes(payload.role);
//       this.loginForm.markAsPristine();
//       log.info('Login completed...');
//       await this.auth.basicLogin(payload.email, payload.password).then((usercred: firebase.auth.UserCredential) => {
//         console.log('basic login succeeded...');
//         this.auth.storeAuthState(usercred);
//       });
//     }
//   }

//   async developerLogin(): Promise<void> {
//     this.isLoading = true;
//     this.loginForm.controls['role'].patchValue('developer');
//     const payload = this.loginForm.value;
//     const credentials: LoginCredentials = {
//       email: payload.email,
//       password: payload.password,
//       role: payload.role,
//     };
//     console.log(payload);
//     const validix: number = this.validLogins.findIndex((account: any) => {
//       return (
//         account.email === payload.email &&
//         account.username === payload.password &&
//         account.roles.includes(payload.role)
//       );
//     });
//     if (validix !== -1) {
//       this.isLoading = false;
//       this.authService.setLoggedIn(credentials, payload.remember, {success: true, data: 'token'});
//       this.authService.updateRoutes(payload.role);
//       this.loginForm.markAsPristine();
//       log.info('Login completed...');
//       await this.auth.googleLogin().then((usercred: firebase.auth.UserCredential) => {
//         console.log('google login succeeded...');
//         this.auth.storeAuthState(usercred);
//       });
//     }
//   }

//   private createForm() {
//     this.loginForm = this.formBuilder.group({
//       email: ['', Validators.required, Validators.email],
//       password: ['', Validators.required],
//       role: ['', Validators.required],
//       remember: true,
//     });
//   }

//   autofillDeveloper(): void {
//     this.loginForm.controls['email'].patchValue('michael@remoteinked.net');
//     this.loginForm.controls['password'].patchValue('Perkidge54343*');
//   }

//   autofillAdmin(): void {
//     this.loginForm.controls['email'].patchValue('mlo2gs@mail.com');
//     this.loginForm.controls['password'].patchValue('Perkidge54343*');
//   }

//   autofillUser(): void {
//     this.loginForm.controls['email'].patchValue('bernie.nielsen');
//     this.loginForm.controls['password'].patchValue('P@ssw0rd');
//   }

//   loginNormally(): void {
//     const payload = this.loginForm.value;
//     this.auth.basicLogin(payload.email, payload.password).then((usercred: firebase.auth.UserCredential) => {
//       log.info('login successful');
//       this.auth.storeAuthState(usercred);
//       this.router.navigate(['/explore']);
//     })
//     .catch((err: any) => {
//       log.error('Error Occurred During Basic Login', err);
//     });
//   }

//   loginSSO(): void {
//     this.auth.googleLogin().then((usercred: firebase.auth.UserCredential) => {
//       log.info('login successful');
//       this.auth.storeAuthState(usercred);
//       this.router.navigate(['/explore']);
//     })
//     .catch((err: any) => {
//       log.error('Error Occurred During SSO Login', err);
//     });
//   }

//   sendMagicLink(): void {
//     this.auth.otpEmailLogin(this.email).then(() => {
//       alert('Magic link has been sent to your email!');
//     })
//     .catch((err: any) => {
//       log.error('Error Occurred Sending Magic Link', err);
//     });
//   }

//   handleMagicLink(): void {
//     if (this.auth.otpEmailLogin(window.location.href)) {
//       const email = window.localStorage.getItem('emailForSignIn');
//       if (email) {
//         this.auth.completeOtpEmailLogin(email, window.location.href).then(() => {
//           log.info('otp login was successful');
//           this.router.navigate(['/explore']);
//         })
//         .catch((err: any) => {
//           log.error('Error Occurred During OTP Login', err);
//         })
//       }
//     }
//   }
// }

import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import firebase from 'firebase/compat/app';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  loginForm: FormGroup;

  constructor(private fb: FormBuilder, private afAuth: AngularFireAuth, private router: Router) {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  login() {
    const { email, password } = this.loginForm.value;
    this.afAuth.signInWithEmailAndPassword(email, password).then(() => {
      this.router.navigate(['/']);
    }).catch(error => {
      console.error(error);
    });
  }

  loginWithGoogle() {
    this.afAuth.signInWithPopup(new firebase.auth.GoogleAuthProvider()).then(() => {
      this.router.navigate(['/']);
    }).catch(error => {
      console.error(error);
    });
  }
}
