import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { AuthenticationService } from '@auth/services/authentication.service';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PublicGuard implements CanActivate {
  constructor(
    private auth: AuthenticationService,
    private router: Router,
  ) { }

  canActivate(): Observable<boolean> {
    const isLoggedIn: boolean = this.auth.isAuthenticated();
    if (isLoggedIn) this.router.navigateByUrl('/');
    return of(!isLoggedIn);
  }
}
