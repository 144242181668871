import { Injectable } from '@angular/core';
import { Logger } from '@logger';

const log = new Logger('TreeService');

@Injectable({
  providedIn: 'root',
})
export class TreeService {
  constructor() {}
}
