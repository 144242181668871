import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { SpinnerService } from '@common/components/spinner';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  private totalRequests = 0;

  constructor(private loader: SpinnerService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    console.log('caught');
    this.totalRequests++;
    // this.loader.setLoading(true);
    this.loader.startLoading();
    return next.handle(request)?.pipe(
      finalize(() => {
        this.totalRequests--;
        if (this.totalRequests == 0) {
          // this.loader.setLoading(false);
          this.loader.finishLoading();
        }
      }),
    );
  }
}
