import { AfterViewInit, Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import {
  GridApi,
  GridOptions,
  ColDef,
  SideBarDef,
  RowModelType,
} from 'ag-grid-community';
import { SpecialFormatColumns, HiddenGridColumns } from '@data/constants';
import { FormatColumn } from '@data/models';
import { GridService } from './grid.service';
import { formatDate, normalizeDate, processDate } from '@data/utils';
import { Router } from '@angular/router';
import * as mod from '@data/models';
import { Logger } from '@logger';

const log = new Logger('GridLogger');

@Component({
  selector: 'ncal-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.css'],
})
export class GridComponent implements OnInit, AfterViewInit {
  public sideBar: SideBarDef | string | string[] | boolean | null = 'columns';
  @ViewChild('searchbar', {static: false}) searchbar: HTMLInputElement;
  @Output() selecting: EventEmitter<any> = new EventEmitter<any>();
  gridRowModel: RowModelType = 'clientSide';
  gridTheme: string = 'ag-theme-material';
  loadingMessage: string = 'Loading';
  columnnames: string[] = [];
  columndefs: ColDef[] = [];
  @Input() dataset!: any[];
  filtertext: string = '';
  loading: boolean = true;
  @Input() name!: string;
  selection = 'multiple';
  options: GridOptions;
  rowdata: any[] = [];
  gridapi: GridApi;
  rowHeight = 36;
  colids: any[];
  colsapi: any;

  constructor(
    private grids: GridService,
    private router: Router,
  ) {
    this.navigateToNextCell = this.navigateToNextCell.bind(this);
    this.tabToNextCell = this.tabToNextCell.bind(this);
    this.options = {
      defaultColDef: {
        resizable: true,
        cellDataType: false,
      },
      animateRows: true,
      alwaysShowHorizontalScroll: true,
    };
  }

  ngOnInit(): void {
    this.simLoad();
    this.loadingMessage = `Loading ${this.name.toUpperCase()}...`;
    const parsedColDefs: ColDef[] = this.grids.parseCols(this.dataset);
    const processedColDefs = this.processColumnDefs(parsedColDefs);
    this.columndefs = this.presentColumnDefs(processedColDefs);
    this.rowdata = this.dataset;
  }

  private processColumnDefs(coldefs: ColDef[]): ColDef[] {
    let displayCols: ColDef[] = [];
    const hiddenfields: string[] = HiddenGridColumns.filter((gc: mod.GridCol) => {
      return gc.entity === this.name;
    }).map((gc: mod.GridCol) => gc.field);
    coldefs.filter((cd: ColDef) => {
      return !hiddenfields.includes(cd.field);
    }).forEach((cd: ColDef, ix: number) => {
      SpecialFormatColumns.forEach((fcol: FormatColumn) => {
        if (fcol.label === cd.field && fcol.datatype !== null) {
          cd.cellDataType = fcol.datatype;
          if (fcol.datatype === 'boolean') cd.cellRenderer = this.booleanCellRenderer;
          if (fcol.datatype === 'object') cd.cellRenderer = this.objectCellRenderer;
        }
        if (cd.field === 'date') cd.cellRenderer = this.datetimeCellRenderer;
        if (
          cd.field === 'hired' ||
          cd.field === 'created' ||
          cd.field === 'deadline' ||
          cd.field === 'releaseDate'
        ) cd.cellRenderer = this.timestampCellRenderer;
      });
      if (cd.field === 'rate') cd.cellRenderer = this.currencyCellFormatRenderer;
      if (
        cd.field === 'status'
        || (
          this.name === 'workflowStatuses'
          && cd.field === 'type'
        )
      ) cd.cellRenderer = this.stringCellFormatRenderer;
      if (cd.field === 'rates') cd.cellRenderer = this.ratesCellValueFormatRenderer;
      if (cd.field === 'records') cd.cellRenderer = this.specialCellValueFormatRenderer;
      if (cd.cellDataType === 'object' && !cd.cellRenderer) cd.cellRenderer = this.specialCellValueFormatRenderer
      displayCols.push(cd);
    });
    return displayCols;
  }

  presentColumnDefs(coldefs: ColDef[]): ColDef[] {
    let showing: ColDef[] = [];
    coldefs.forEach((cd: ColDef) => {
      console.log(cd);
      if (cd.cellDataType === 'boolean') {
        cd.cellClass = 'tf-icon';
        console.log(cd);
      } else if (cd.field === 'default' || cd.field === 'billable') {
        cd.cellRenderer = this.booleanCellRenderer;
      }
      showing.push(cd);
    });
    return showing;
  }

  simLoad(): void {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  }

  ngAfterViewInit(): void {
    log.info('grid afterviewinit...');
    log.debug(this.columndefs);
    log.debug(this.rowdata);
    log.debug(this.options);
    log.debug(this.gridapi);
    log.debug(this.colsapi);
  }

  specialFormatColumn(col: string): boolean {
    let found: boolean = false;
    SpecialFormatColumns.forEach((fcol: FormatColumn) => {
      if (fcol.label === col) found = true;
    });
    return found;
  }

  formattedColDisplay(col: string): string {
    let display: string = '';
    SpecialFormatColumns.forEach((fcol: FormatColumn) => {
      if (fcol.label === col) display = fcol.display;
    });
    return display;
  }

  siftResults(event: any): void {
    const target = event.target.value;
    this.gridapi.setGridOption('quickFilterText', target.trim().toLowerCase());
  }

  clearSearch(empty: string): void {
    this.filtertext = empty;
    this.grids.clearQuickFilter(this.gridapi, this.searchbar, empty);
  }

  tabToNextCell(params: any): any {
    return params.nextCellPosition;
  }

  navigateToNextCell(params: any): any {
    return this.grids.navigateToNextCell(this.gridapi, params);
  }

  stringCellFormatRenderer(params: any): any {
    return String(params.value).charAt(0).toUpperCase() + String(params.value).slice(1);
  }

  currencyCellFormatRenderer(params: any): any {
    return `$ ${parseFloat(params.value).toFixed(2)}`;
  }

  arrayCellRenderer(params: any): any {
    const aslist = Array.from(params.value);
    return `${aslist.length} records found`;
  }

  datetimeCellRenderer(params: any): any {
    return (params.value !== null) ? normalizeDate(params.value) : '-';
  }

  timestampCellRenderer(params: any): any {
    return (params.value !== null) ? formatDate(
      new Date(params.value).toDateString()
    ) : '-';
  }

  objectCellRenderer(params: any): any {
    const obkeys = Object.keys(params.value);
    let out: string[] = [];
    if (obkeys && obkeys.length) {
      obkeys.forEach((k: string) => {
        if (k === 'canModify') {
          out.push('Edit');
        } else if (k === 'canDelete') {
          out.push('Delete');
        } else if (k === 'canSubmitTimetrack') {
          out.push('Track Time');
        }
      });
    }
    return out.length ? out.join(' & ') : '-';
  }

  ratesCellValueFormatRenderer(params: any): any {
    const values: any[] = [];
    if (Array.isArray(params.value)) {
      params.value.forEach((val: any) => {
        const summary = `[From: ${formatDate(new Date(val.dateFrom).toDateString())}] Hourly Rate: $ ${parseFloat(val.regularRate).toFixed(2)}`;
        values.push(summary);
      });
    } else {
      `[From: ${formatDate(new Date(params.value.dateFrom).toDateString())}] Hourly Rate: $ ${parseFloat(params.value.regularRate).toFixed(2)}`;
    }
    return values.join(' / ');
  }

  renderTimetrackRecords(value: any): string {
    const data = JSON.parse(value);
    const count = JSON.parse(value).length;
    let total: number = 0;
    data.forEach((rec: mod.TimeTrackRecord) => {
      total += rec.time;
    });
    const totalhours = (total / 60).toFixed(2);
    return `${count} record(s) over ${totalhours} hours`;
  }

  booleanCellRenderer(params: any): any {
    return params.value
      ? `<span class="tf-icon">&#9989;</span>`  // &#10004;
      : `<span class="tf-icon">&#10060;</span>`;  // &#10006;
  }

  specialCellValueFormatRenderer(params: any): any {
    // console.log('special formatting needed here...');
    // console.log(params.colDef.field);
    // console.log(params.colDef.headerName);
    // console.log(params.valueFormatted);
    // console.log(params.value);
    return JSON.stringify(params.value);
    // if (params.colDef.field === 'records') {
    //   return this.renderTimetrackRecords(JSON.stringify(params.value));
    // } else {
    //   return JSON.stringify(params.value);
    // }
  }

  onCellDoubleClicked(params: any): void {
    this.router.navigate([`/entity/${this.name}/${params.data.id}`]);
  }

  onGridReady(params: any): void {
    let sort: any;
    let element: any;
    let index: number;
    let tabIndex: number;
    let nextelement: any;
    this.gridapi = params.api;
    this.colsapi = params.columnApi;
    let columns = this.gridapi.getAllDisplayedVirtualColumns();
    this.colids = columns.map(column => {
      return column['colId'];
    });
    this.columnnames = columns.map(column => {
      return column['field'] ?? column;
    });
    tabIndex = 0;
    columns.forEach(column => {
      element = document.querySelector('div[col-id=' + column['colId'] + '] div.ag-header-cell-label');
      element.setAttribute('tabindex', tabIndex++);
      element.addEventListener('keydown', function(e: any) {
        if (e.key === 'Tab') {
          index = this.colids.findIndex((colid: number) => {
            return colid === column['colId'];
          });
          if (index === -1 || index === this.colids.length - 1) {
            index = 0;
          } else {
            index++;
          }
          nextelement = document.querySelector('div[col-id=' + this.colids[index] + '] div.ag-header-cell-label');
          nextelement.focus();
          e.preventDefault();
        } else if (e.key === 'Enter') {
          sort = [{
            colId: column['colId'],
            sort: 'asc'
          }];
          params.api.setSortModel(sort);
        }
      });
    });
    this.gridapi.sizeColumnsToFit();
    window.addEventListener('resize', () => this.gridapi.sizeColumnsToFit());
    this.gridapi.addEventListener('resize', () => this.gridapi.sizeColumnsToFit());
  }
}

// getContextMenuItems(
//     params: GetContextMenuItemsParams
//   ): (string | MenuItemDef)[] {
//     var result: (string | MenuItemDef)[] = [
//       {
//         // custom item
//         name: 'Alert ' + params.value,
//         action: () => {
//           window.alert('Alerting about ' + params.value);
//         },
//         cssClasses: ['red', 'bold'],
//       },
//       {
//         // custom item
//         name: 'Always Disabled',
//         disabled: true,
//         tooltip:
//           'Very long tooltip, did I mention that I am very long, well I am! Long!  Very Long!',
//       },
//       {
//         name: 'Country',
//         subMenu: [
//           {
//             name: 'Ireland',
//             action: () => {
//               console.log('Ireland was pressed');
//             },
//             icon: createFlagImg('ie'),
//           },
//           {
//             name: 'UK',
//             action: () => {
//               console.log('UK was pressed');
//             },
//             icon: createFlagImg('gb'),
//           },
//           {
//             name: 'France',
//             action: () => {
//               console.log('France was pressed');
//             },
//             icon: createFlagImg('fr'),
//           },
//         ],
//       },
//       {
//         name: 'Person',
//         subMenu: [
//           {
//             name: 'Niall',
//             action: () => {
//               console.log('Niall was pressed');
//             },
//           },
//           {
//             name: 'Sean',
//             action: () => {
//               console.log('Sean was pressed');
//             },
//           },
//           {
//             name: 'John',
//             action: () => {
//               console.log('John was pressed');
//             },
//           },
//           {
//             name: 'Alberto',
//             action: () => {
//               console.log('Alberto was pressed');
//             },
//           },
//           {
//             name: 'Tony',
//             action: () => {
//               console.log('Tony was pressed');
//             },
//           },
//           {
//             name: 'Andrew',
//             action: () => {
//               console.log('Andrew was pressed');
//             },
//           },
//           {
//             name: 'Kev',
//             action: () => {
//               console.log('Kev was pressed');
//             },
//           },
//           {
//             name: 'Will',
//             action: () => {
//               console.log('Will was pressed');
//             },
//           },
//           {
//             name: 'Armaan',
//             action: () => {
//               console.log('Armaan was pressed');
//             },
//           },
//         ],
//       },
//       'separator',
//       {
//         // custom item
//         name: 'Windows',
//         shortcut: 'Alt + W',
//         action: () => {
//           console.log('Windows Item Selected');
//         },
//         icon:
//           '<img src="https://www.ag-grid.com/example-assets/skills/windows.png" />',
//       },
//       {
//         // custom item
//         name: 'Mac',
//         shortcut: 'Alt + M',
//         action: () => {
//           console.log('Mac Item Selected');
//         },
//         icon:
//           '<img src="https://www.ag-grid.com/example-assets/skills/mac.png"/>',
//       },
//       'separator',
//       {
//         // custom item
//         name: 'Checked',
//         checked: true,
//         action: () => {
//           console.log('Checked Selected');
//         },
//         icon:
//           '<img src="https://www.ag-grid.com/example-assets/skills/mac.png"/>',
//       },
//       'copy',
//       'separator',
//       'chartRange',
//     ];
//     return result;
//   }
// }
// }
