import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '@env/environment';
import { DocStore } from '@app/common/data';
import { CacheService } from '@app/cache';

const ASSETSAPIURL = environment.asset_api_base_url;
const ENGINEAPIURL = environment.engine;
const SYNCAPIURL = environment.sync;
const FASTAPIURL = environment.fast;
const IOAPIURL = environment.dbio;

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  constructor(
    private cache: CacheService,
    private http: HttpClient,
  ) { }

  getJsonDoc(server: string, entity: string): Observable<any> {
    const documentsUrl = server === 'engine'
      ? ENGINEAPIURL : server === 'sync'
      ? SYNCAPIURL : server === 'fast'
      ? FASTAPIURL : IOAPIURL;
    return this.http.get<{data: any}>(`${documentsUrl}/${entity}`);
  }

  pullJsonDoc(server: string, entity: string): Observable<any> {
    const documentsUrl = server === 'assets'
      ? ASSETSAPIURL : server === 'engine'
      ? ENGINEAPIURL : server === 'sync'
      ? SYNCAPIURL : server === 'fast'
      ? FASTAPIURL : IOAPIURL;
    return this.http.get<{data: any}>(`${documentsUrl}/${entity}.json`);
  }

  async setJsonDoc(server: string, doc: DocStore): Promise<any> {
    const documentsUrl = server === 'assets'
      ? ASSETSAPIURL : server === 'engine'
      ? ENGINEAPIURL : server === 'sync'
      ? SYNCAPIURL : server === 'fast'
      ? FASTAPIURL : IOAPIURL;
    const body = {
      document: await this.cache.get(doc.path),
      path: doc.path,
      name: doc.name,
      model: [],
    };
    this.http.post<{payload: DocStore}>(`${documentsUrl}/`, body).subscribe({
      next: (response) => console.log(response),
      error: (error) => console.log('Error sending document...', error),
    });
  }
}
