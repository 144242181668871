import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NcalMaterialModule } from '@material/index';
import { SkeletonComponent } from './skeleton.component';

@NgModule({
  imports: [CommonModule, TranslateModule, NcalMaterialModule],
  declarations: [SkeletonComponent],
  exports: [SkeletonComponent],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class SkeletonModule {}
