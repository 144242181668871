import { Injectable } from '@angular/core';
import { delay, finalize, of } from 'rxjs';
import { SpinnerService } from '@spinner/index';

@Injectable({
  providedIn: 'root',
})
export class SimService {
  action: string;

  constructor(private spinner: SpinnerService) {}

  async simPageLoad(loading: boolean): Promise<boolean> {
    loading = true;
    setTimeout(() => {
      loading = false;
    }, 600);
    return loading;
  }

  async simCompLoad(loading: boolean): Promise<boolean> {
    loading = true;
    setTimeout(() => {
      loading = false;
    }, 400);
    return loading;
  }

  quickspin(): void {
    this.spinner.startLoading();
    of({ data: 'Loading...' })
      .pipe(
        delay(1000),
        finalize(() => this.spinner.finishLoading())
      )
      .subscribe(res => {
        this.action = res.data;
      });
  }

  longspin(): void {
    this.spinner.startLoading();
    of({ data: 'Loading...' })
      .pipe(
        delay(3000),
        finalize(() => this.spinner.finishLoading())
      )
      .subscribe(res => {
        this.action = res.data;
      });
  }

  debugspin(): void {
    this.spinner.startLoading();
    of({ data: 'Loading...' })
      .pipe(
        delay(60000),
        finalize(() => this.spinner.finishLoading())
      )
      .subscribe(res => {
        this.action = res.data;
      });
  }
}
