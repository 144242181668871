import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { catchError, map, tap, retryWhen, delay, take } from 'rxjs/operators';
import { NotificationService } from './notification.service';
import { Observable, forkJoin, of, throwError } from 'rxjs';
import { QUERYPATHS } from '@data/constants';
import { CacheService } from '@cache/index';
import { MessageSource } from '@data/enums';
import { QueryParams } from '@data/models';
import { Logger } from '@logger';

const log = new Logger('FirebaseService');

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  queryvals: string[] = [];
  queryargs: string[] = [];
  querykeys: string[] = [];
  querypaths: QueryParams[] = QUERYPATHS;

  constructor(
    private cache: CacheService,
    private db: AngularFireDatabase,
    private notifier: NotificationService,
  ) {}

  collectData(): Observable<any> {
    this.queryvals = QUERYPATHS.map((qe) => qe.val);
    this.querykeys = QUERYPATHS.map((qe) => qe.key);
    this.queryargs = QUERYPATHS.map((qe) => qe.path ? qe.path : qe.key);
    const totaltasks = this.queryargs.length;
    let taskscomplete = 0;

    return new Observable(observer => {
      const observables = this.queryargs.map((path, index) =>
        this.db.object(`/${path}`).valueChanges().pipe(
          tap(response => this.handleResponse(response, this.querykeys[index], `Collected ${this.queryvals[index]}`, observer, ++taskscomplete, totaltasks)),
          retryWhen(errors => errors.pipe(delay(1000), take(3))),
          catchError(error => this.handleError(observer, error, `collecting ${this.querykeys[index]}`))
        )
      );

      forkJoin(observables).pipe(
        map(responses => responses.reduce((acc, curr, index) => {
          acc[this.queryargs[index]] = curr;
          return acc;
        }, {}))
      ).subscribe({
        next: result => observer.next(result),
        error: error => observer.error(error),
        complete: () => observer.complete(),
      });
    });
  }

  private handleResponse(response: any, backupKey: string, message: string, observer: any, totalreqs: number, completedreqs: number): void {
    console.log(response);
    this.cache.setLocal(backupKey, response);
    this.updateProgress(message, completedreqs, totalreqs, MessageSource.Sync);
  }

  private updateProgress(msg: string, complete: number, total: number, source: MessageSource): void {
    const progress = Math.round((complete / total) * 100);
    this.notifier.notify(msg, progress, source);
  }

  private handleError(observer: any, error: any, context: string): Observable<never> {
    this.notifier.error(`Error occurred during ${context}...`);
    observer.error(error);
    return throwError('Firebase operation failed - please try again shortly...');
  }

  getEntities(entity: string): Observable<any[]> {
    const entities = this.db.list(`/${entity}`).snapshotChanges();
    return entities;
  }

  async setEntities(key: string, dataset: any[]): Promise<void> { // Observable<any[]> {
    return this.setData(key, dataset).then((result) => {
      console.log(result);
    });
  }

  introspect(): void {
    console.log(this.db);
    console.log(this.db.database);
  }

  // Read data from a specific path
  async getDataset(path: string): Promise<any[]> {
    return await this.db.list(path).valueChanges().pipe(
      map((results) => results)
    ).toPromise();
  }

  // Read data from a specific path
  getData(path: string): Observable<any> {
    return this.db.object(path).valueChanges();
  }

  getDataSet(path: string): Observable<any[]> {
    return this.db.list(path).valueChanges();
  }

  // Write data to a specific path
  setData(path: string, data: any): Promise<void> {
    return this.db.object(path).set(data);
  }

  // Update data at a specific path
  updateData(path: string, data: any): Promise<void> {
    return this.db.object(path).update(data);
  }

  // Delete data at a specific path
  deleteData(path: string): Promise<void> {
    return this.db.object(path).remove();
  }

  // Bulk write (POST/PUT) operation
  bulkWrite(data: { [key: string]: any }): Promise<void> {
    return this.db.object('/').update(data);
  }
}

/**
*/
