<div class="sandbox-table">
  <section class="sandbox-controls">
    <div class="search-controls">
      <mat-icon matPrefix class="find-me">search</mat-icon>
      <input
        matInput
        #searchbar
        type="text"
        autocomplete="off"
        class="sandbox-searchbar"
        (keyup)="siftResults($event)"
        placeholder="Search for records based any ID, Name, Description, etc..." />
      <mat-icon matSuffix class="clear-me" (click)="clearSearch('')">clear</mat-icon>
    </div>
    <div class="info-controls">
      <span class="dataset-info" *ngIf="!loading">
        <b>Viewing: {{ name | titlecase }}</b>
      </span>
    </div>
  </section>
  <section class="sandbox-results">
    <ncal-loader
      *ngIf="loading"
      [active]="true"
      [message]="loadingMessage">
    </ncal-loader>
    <span *ngIf="!loading" class="grid-container">
      <ag-grid-angular
        style="width: 100%; height: 75vh"
        [class]="gridTheme"
        [rowData]="rowdata"
        [gridOptions]="options"
        [columnDefs]="columndefs"
        [pagination]="true"
        [enableFilter]="true"
        [enableSorting]="true"
        [rowHeight]="rowHeight"
        [rowSelection]="'multiple'"
        [tabToNextCell]="tabToNextCell"
        [navigateToNextCell]="navigateToNextCell"
        (cellDoubleClicked)="onCellDoubleClicked($event)"
        (gridReady)="onGridReady($event)">
      </ag-grid-angular>
      <br />
    </span>
  </section>
</div>
