import { Injectable } from '@angular/core';
import FileSaver from 'file-saver';
import * as excel from 'exceljs';
import { AuditLog, ExcelCol, DateRange } from '@data/models';
import { InvoicingService } from './invoicing.service';
import { StorageService } from './storage.service';
import { StorageKeys } from '@data/enums';
import { InvoiceLog } from '@data/classes';
import * as fn from '@data/utils';
import { Logger } from '@logger';

const log = new Logger('ExcelService');

@Injectable({
  providedIn: 'root'
})
export class ExcelService {
  columns: ExcelCol[] = [
    {
      width: 25,
      options: {},
      key: 'entity',
      header: 'Entity',
    },
    {
      width: 75,
      key: 'info',
      options: {},
      header: 'Information',
    }
  ];

  constructor(
    private invoicer: InvoicingService,
    private storage: StorageService,
  ) { }

  async getLogs(): Promise<InvoiceLog[]> {
    return await this.invoicer.getLogs();
  }

  customizeLogEntity(level: number, entity: string): string {
    let custom = entity;
    switch (level) {
      case 1:
        break;
      case 2:
        custom = '-- ' + custom;
        break;
      case 3:
        custom = '---- ' + custom;
        break;
      case 4:
        custom = '------ ' + custom;
        break;
      case 5:
        custom = '-------- ' + custom;
        break;
      default:
        break;
    }
    return custom;
  }

  cleanupLogs(logs: InvoiceLog[]): InvoiceLog[] {
    console.log(logs);
    const exportlogs: InvoiceLog[] = [];
    logs.forEach((log: InvoiceLog) => {
      if (log.entity !== 'HTML') {
        exportlogs.push({
          level: log.level,
          entity: this.customizeLogEntity(log.level, log.entity),
          info: log.info.trim(),
        });
      }
    });
    return exportlogs;
  }

  addStylingToHeader(worksheet: excel.Worksheet, daterange: DateRange): void {
    const bannerRow = worksheet.getRow(1);
    bannerRow.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 16,
    };
    bannerRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '2f7b64' },
      bgColor: { argb: '2f7b64' },
    };
    bannerRow.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
    bannerRow.alignment = {
      vertical: 'middle',
    };
    bannerRow.height = 40;
    bannerRow.values = [
      'NCAL Invoicing',
      `Billable Work from ${daterange.from} to ${daterange.til}`,
    ];
    const headerRow = worksheet.getRow(2);
    headerRow.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 12
    };
    headerRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '2f7b64' },
      bgColor: { argb: '2f7b64' }
    };
    headerRow.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' }
    };
    headerRow.height = 20;
    headerRow.values = [
      'Entity',
      'Information',
    ];
  }

  addStylingToRows(worksheet: excel.Worksheet, exportedlogs: number): void {
    const rows = worksheet.getRows(3, exportedlogs);
    rows.forEach((row: excel.Row) => {
      row.font = {
        size: 10,
      };
      row.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'e2e4e2' },
        bgColor: { argb: 'e2e4e2' },
      };
      row.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      };
      row.alignment = {
        wrapText: true,
      };
    });
  }

  async generateSpreadsheet(): Promise<void> {
    console.log('generate spreadsheet process initiated...');
    const daterange: DateRange = this.invoicer.getCurrentMonthRange();
    const logs = await this.getLogs();
    const workbook = new excel.Workbook();
    const explogs = this.cleanupLogs(logs);
    const worksheet = workbook.addWorksheet(`${daterange.from} to ${daterange.til}`);
    worksheet.columns = this.columns;
    this.addStylingToHeader(worksheet, daterange);
    explogs.forEach((log: InvoiceLog) => {
      worksheet.addRow(log);
    });
    this.addStylingToRows(worksheet, explogs.length);
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      FileSaver.saveAs(blob, `NCAL Engineering Invoices -- ${daterange.from} to ${daterange.til}.xlsx`);
    });
    return;
  }
}
