import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ncal-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css'],
})
export class LoaderComponent implements OnInit {
  @Input() active: boolean;
  @Input() message: string;

  constructor() { }

  ngOnInit(): void {
  }
}
