import { Injectable } from '@angular/core';
import { Title, Meta, MetaDefinition } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(private title: Title, private meta: Meta) { }

  updateTitle(title: string){
    this.title.setTitle(title);
  }

  updateMetaTags(metaTags?: MetaDefinition[]) {
    if (metaTags) {
      console.log(metaTags);
      // metaTags.forEach((m: MetaDefinition) => {
      //   if (m.name) {
      //     this.meta.updateTag({
      //       name: m.name,
      //       content: m.content,
      //     });
      //   } else if (m.property) {
      //     this.meta.updateTag({
      //       property: m.property,
      //       content: m.content,
      //     });
      //   }
      // });
    }
    return;
  }
}
