/**
 * Custom Logger Service
 */

export enum LogLevel {
  Off = 0,
  Error,
  Warning,
  Info,
  Debug,
}

export type LogOutput = (source: string | undefined, level: LogLevel, ...objects: any[]) => void;

export class Logger {

  static level = LogLevel.Debug;
  /**
 * Additional log outputs.
 */
  static outputs: LogOutput[] = [];

  /**
  * Enables production mode.
  * Sets logging level to LogLevel.Warning.
  */
  static enableProductionMode() {
    Logger.level = LogLevel.Warning;
  }

  constructor(private source?: string) {}

  /**
  * Logs messages or objects  with the debug level.
  * Works the same as console.log().
  */
  debug(...objects: any[]) {
    this.log(console.log, LogLevel.Debug, objects);
  }

  /**
  * Logs messages or objects  with the info level.
  * Works the same as console.log().
  */
  info(...objects: any[]) {
    this.log(console.info, LogLevel.Info, objects);
  }

  /**
  * Logs messages or objects  with the warning level.
  * Works the same as console.log().
  */
  warn(...objects: any[]) {
    this.log(console.warn, LogLevel.Warning, objects);
  }

  /**
  * Logs messages or objects  with the error level.
  * Works the same as console.log().
  */
  error(...objects: any[]) {
    this.log(console.error, LogLevel.Error, objects);
  }

  private log(func: (...args: any[]) => void, level: LogLevel, objects: any[]) {
    if (level <= Logger.level) {
      const log = this.source ? ['[' + this.source + ']'].concat(objects) : objects;
      func.apply(console, log);
      Logger.outputs.forEach((output) => output.apply(output, [this.source, level, ...objects]));
    }
  }
}
