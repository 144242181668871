import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { Observable } from 'rxjs/internal/Observable';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { NotificationType } from '@data/enums';
import { INotification } from '@data/models';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  private notification$: Subject<INotification> = new BehaviorSubject(null);

  private notify(message: string, type: NotificationType, duration: number): void {
    duration = !duration ? 3000 : duration;
    this.notification$.next({
      message, type, duration,
    } as INotification);
  }

  get notification(): Observable<INotification> {
    return this.notification$.asObservable();
  }

  primary(message: string, duration: number = null): void {
    this.notify(message, NotificationType.Primary, duration);
  }

  secondary(message: string, duration: number = null): void {
    this.notify(message, NotificationType.Secondary, duration);
  }

  success(message: string, duration: number = null): void {
    this.notify(message, NotificationType.Success, duration);
  }

  info(message: string, duration: number = null): void {
    this.notify(message, NotificationType.Info, duration);
  }

  warning(message: string, duration: number = null): void {
    this.notify(message, NotificationType.Warning, duration);
  }

  error(message: string, duration: number = null): void {
    this.notify(message, NotificationType.Danger, duration);
  }

  cleanup(): void {
    this.notification$.unsubscribe();
  }
}
