import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { TranslateModule } from '@ngx-translate/core';
import { NcalMaterialModule } from '@material/index';
import { LoginComponent, LogoutComponent } from './components';
import { AuthenticationService, AuthService } from './services';
import { AuthRouting } from './auth.routing';

@NgModule({
  declarations: [
    LoginComponent,
    LogoutComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    AngularFireAuthModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NcalMaterialModule,
    AuthRouting,
  ],
  providers: [
    AuthService,
    AuthenticationService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AuthModule {}
