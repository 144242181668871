import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Observable } from 'rxjs/internal/Observable';
import { UtilityService } from './utility.service';
import { CacheService } from '@cache/index';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuditService {

  constructor(
    private db: AngularFireDatabase,
    private utils: UtilityService,
    private cache: CacheService,
  ) {}

  get exports(): any[] {
    return this.exports;
  }

  set exports(exported: any[]) {
    this.exports = exported;
    console.log(this.exports);
  }

  getEntities(entity: string): Observable<any[]> {
    const entities = this.db.list(`/${entity}`).snapshotChanges();
    return entities;
  }

  async setEntities(key: string, dataset: any[]): Promise<void> {
    return this.setData(key, dataset).then((result) => {
      console.log(result);
    });
  }

  introspect(): void {
    console.log(this.db);
    console.log(this.db.database);
  }

  // Read data from a specific path
  async getDataset(path: string): Promise<any[]> {
    return await this.db.list(path).valueChanges().pipe(
      map((results) => results)
    ).toPromise();
  }

  // Read data from a specific path
  getData(path: string): Observable<any> {
    return this.db.object(path).valueChanges();
  }

  getDataSet(path: string): Observable<any[]> {
    return this.db.list(path).valueChanges();
  }

  // Write data to a specific path
  setData(path: string, data: any): Promise<void> {
    return this.db.object(path).set(data);
  }

  // Update data at a specific path
  updateData(path: string, data: any): Promise<void> {
    return this.db.object(path).update(data);
  }

  // Delete data at a specific path
  deleteData(path: string): Promise<void> {
    return this.db.object(path).remove();
  }

  // Bulk write (POST/PUT) operation
  bulkWrite(data: { [key: string]: any }): Promise<void> {
    return this.db.object('/').update(data);
  }
}
