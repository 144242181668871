import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';

const ASSETSURL = environment.asset_api_base_url;
const ASSETSAPI = environment.asset_api_live_url;

@Injectable({
  providedIn: 'root',
})
export class AssetService {
  constructor(private http: HttpClient) { }

  fetchAssetAsBase64(path: string): Promise<any> {
    const url: string = environment.class === 'staging'
      ? `${ASSETSURL}/${path}`
      : `${ASSETSAPI}/${path}`;
    return this.http.get(url, { responseType: 'blob' }).pipe(
      map(async blob  => await this.blobToBase64(blob))
    ).toPromise();
  }

  private async blobToBase64(blob: Blob): Promise<string> {
    return new Observable<string>(observer => {
      const reader = new FileReader();
      reader.onloadend = () => {
        observer.next(reader.result as string);
        observer.complete();
      };
      reader.readAsDataURL(blob);
    }).pipe(
      map(blob => blob)
    ).toPromise();
  }
}
