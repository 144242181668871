import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';
import { DialogResult } from '@data/schema';

export class DialogRef {
  private readonly _afterClosed = new Subject<any>();

  constructor() {}

  afterClosed = <T = any>() =>
    this._afterClosed.asObservable() as Observable<DialogResult<T>>;

  close<T = any>(result?: DialogResult<T>) {
    this._afterClosed.next(result);
  }
}
