<div class="overlay" *ngIf="active">
  <h3 class="on-msg">{{message}}</h3>
  <div class="loader"></div>
</div>
<div class="overlay" *ngIf="!active">
  <h3 class="off-msg">{{message}}</h3>
</div>

<!-- <div style="text-align:center">
  <button mat-raised-button color="primary" [class.spinner]="loading" [disabled]="loading" >Save</button>
</div> -->
