import { Injectable } from '@angular/core';
import { catchError, delay, map, retryWhen, switchMap, take, tap } from 'rxjs/operators';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Observable, throwError, forkJoin, of } from 'rxjs';
import { ActitimeService } from '@services/actitime.service';
import { NotificationService } from './notification.service';
import { MessageSource, CacheKeys } from '@data/enums';
import { QUERYPATHS } from '@data/constants';
import { CacheService } from '@cache/index';
import { QueryParam } from '@data/models';
import { Logger } from '@logger';

const log = new Logger('SyncService');

@Injectable({
  providedIn: 'root',
})
export class SyncService {
  queryvals: string[] = [];
  queryargs: string[] = [];
  querykeys: string[] = [];
  collectvals: string[] = [];
  collectargs: string[] = [];
  collectkeys: string[] = [];
  querypaths: QueryParam[] = QUERYPATHS;
  cacheRestored: boolean = false;
  announcement: string;

  constructor(
    private cache: CacheService,
    private db: AngularFireDatabase,
    private actitime: ActitimeService,
    private notifier: NotificationService,
  ) {}

  fetchFreshData(): Observable<any> {
    const totalreqs: number = 9;
    let completedreqs: number = 0;
    this.queryvals = QUERYPATHS.map((qe) => qe.val);
    this.querykeys = QUERYPATHS.map((qe) => qe.key);
    this.queryargs = QUERYPATHS.map((qe) => qe.path ? qe.path : 'null');
2
    const requests = this.queryargs.map((path, index) =>
      (path === 'timetrack') ? this.actitime.queryTimetracks(path).pipe(
          tap(response => this.handleResponse(response, this.querykeys[index], `Received ${this.queryvals[index]}`, totalreqs, ++completedreqs)),
          catchError(error => this.handleError(error, this.querykeys[index])
        )) : (path !== 'null') ? this.actitime.queryAPI(path).pipe(
          tap(response => this.handleResponse(response, this.querykeys[index], `Received ${this.queryvals[index]}`, totalreqs, ++completedreqs)),
          catchError(error => this.handleError(error, `syncing ${this.querykeys[index]}`)
        )) : of(null)
      );

    return forkJoin(requests).pipe(
      tap(() => {
        this.notifier.notify('Data Sync: Complete', 100, MessageSource.Sync);
      }),
      switchMap(() => {
        return this.collectData().pipe(
          tap(remotedata => {
            console.log('Remote data collected:', remotedata);
            this.notifier.notify('Beginning data pre-processing for invoice automation...', 100, MessageSource.Collect);
          }),
        );
      }),
      catchError(error => {
        this.notifier.error('Data Sync: Failed');
        log.error('Data Sync: Failed', error.message);
        return throwError('Data Sync: Failed');
      })
    );
  }

  private handleResponse(response: any, backupKey: string, message: string, totalreqs: number, completedreqs: number): void {
    console.log(response);
    this.cache.setLocal(backupKey, response);
    this.setEntities(backupKey, response);
    this.updateProgress(message, completedreqs, totalreqs, MessageSource.Sync);
  }

  private updateProgress(msg: string, complete: number, total: number, source: MessageSource): void {
    const progress = Math.round((complete / total) * 100);
    this.notifier.notify(msg, progress, source);
  }

  private handleError(error: any, context: string): Observable<never> {
    this.notifier.error(`Error while fetching ${context}`);
    return throwError('Something went wrong - please try again later...');
  }

  collectData(): Observable<any> {
    console.log('collectData starting...');
    this.collectvals = QUERYPATHS.map((qe) => qe.val);
    this.collectkeys = QUERYPATHS.map((qe) => qe.key);
    this.collectargs = QUERYPATHS.map((qe) => qe.path ? qe.path : qe.key);
    const totaltasks = this.collectargs.length;
    let taskscomplete = 0;

    const collectreqs = this.collectargs.map((path, index) =>
      this.db.object(`'/${path}`).valueChanges().pipe(
        tap(response => this.collectResponse(response, this.collectkeys[index], `Collected ${this.collectvals[index]}`, totaltasks, ++taskscomplete)),
        retryWhen(errors => errors.pipe(delay(1000), take(3))),
        catchError(error => this.handleCollectError(error, `collecting ${this.querykeys[index]}`))
      )
    );
    return forkJoin(collectreqs).pipe(
      map(responses => responses.reduce((acc, curr, index) => {
        acc[this.queryargs[index]] = curr;
        return acc;
      }, {})),
      tap(() => this.notifier.notify('Data Collection: Complete', 100, MessageSource.Collect)),
      catchError(error => {
        this.notifier.error('Data Collection: Failed');
        log.error('Data Collection: Failed', error.message);
        return throwError('Data Collection: Failed');
      })
    );
  }

  private collectResponse(response: any, backupKey: string, message: string, totalreqs: number, completedreqs: number): void {
    console.log(response);
    this.updateCollectProgress(message, completedreqs, totalreqs, MessageSource.Collect);
  }

  private updateCollectProgress(msg: string, complete: number, total: number, source: MessageSource): void {
    const progress = Math.round((complete / total) * 100);
    this.notifier.notify(msg, progress, source);
  }

  private handleCollectError(error: any, context: string): Observable<never> {
    log.error(`Error occurred during ${context}`, error);
    this.notifier.error(`Error occurred during ${context}...`);
    return throwError('Firebase operation failed - please try again shortly...');
  }

  getEntities(entity: string): Observable<any[]> {
    const entities = this.db.list(`/${entity}`).snapshotChanges();
    return entities;
  }

  async setEntities(key: string, dataset: any[]): Promise<void> {
    return this.setData(key, dataset).then((result) => {
      console.log(result);
    });
  }

  introspect(): void {
    console.log(this.db);
    console.log(this.db.database);
  }

  // Read data from a specific path
  async getDataset(path: string): Promise<any[]> {
    return await this.db.list(path).valueChanges().pipe(
      map((results) => results)
    ).toPromise();
  }

  // Read data from a specific path
  getData(path: string): Observable<any> {
    return this.db.object(path).valueChanges();
  }

  getDataSet(path: string): Observable<any[]> {
    return this.db.list(path).valueChanges();
  }

  // Write data to a specific path
  setData(path: string, data: any): Promise<void> {
    return this.db.object(path).set(data);
  }

  // Update data at a specific path
  updateData(path: string, data: any): Promise<void> {
    return this.db.object(path).update(data);
  }

  // Delete data at a specific path
  deleteData(path: string): Promise<void> {
    return this.db.object(path).remove();
  }

  // Bulk write (POST/PUT) operation
  bulkWrite(data: { [key: string]: any }): Promise<void> {
    return this.db.object('/').update(data);
  }
}
