import { Injectable } from '@angular/core';
import { StorageService } from '@services/storage.service';
import {  ICol } from '@data/models';
import {
  F2,
  UP,
  TAB,
  END,
  HOME,
  CTRL,
  PGUP,
  DOWN,
  LEFT,
  RIGHT,
  ENTER,
  SHIFT,
  PGDOWN,
  INSERT,
  SpecialFormatColumns,
} from '@data/constants';
import { Logger } from '@logger';
import { StorageKeys } from '@data/enums';
import { GridApi } from 'ag-grid-community';

const log = new Logger('GridService');

@Injectable({
  providedIn: 'root'
})
export class GridService {

  constructor(
    private storage: StorageService,
  ) { }

  parseCols(data: any[]): ICol[] {
    let icols: any[] = [];
    let keys: string[] = Object.keys(data[0]);
    keys.forEach((key: string) => {
      icols.push({
        headerCheckboxSelection: (key === 'id' ? true : false),
        checkboxSelection: (key === 'id' ? true : false),
        headerName: `${key.toSentenceCase()}`,
        filter: true,
        field: key,
      });
    });
    log.info(icols);
    return icols;
  }

  clearQuickFilter(gridapi: GridApi, searchbar: any, blank: string): void {
    searchbar.value = blank;
    searchbar.nativeElement.value = blank;
    gridapi.setGridOption('quickFilterText', blank);
  }

  navigateToNextCell(gridapi: GridApi, params: any): any {
    let renderedRows: any;
    let nextRowIndex: any;
    let previousCell: any;
    let suggestedCell: any;
    log.debug(params);
    suggestedCell = params.nextCellPosition;
    previousCell = params.previousCellPosition;
    log.debug('params.event.keyCode: ' + params.event.keyCode);
    switch (params.event.keyCode) {
      case UP:
        nextRowIndex = previousCell.rowIndex - 1;
        if (nextRowIndex < 0) {
          return null;
        } else {
          return {
            rowIndex: nextRowIndex,
            column: previousCell.column,
            floating: previousCell.floating
          };
        }
      case DOWN:
        nextRowIndex = previousCell.rowIndex + 1;
        renderedRows = gridapi.getModel().getRowCount();
        if (nextRowIndex >= renderedRows) {
          return null;
        } else {
          return {
            rowIndex: nextRowIndex,
            column: previousCell.column,
            floating: previousCell.floating
          };
        }
      case LEFT:
      case RIGHT:
        return suggestedCell;
      default:
        throw 'Grid navigation is supported using the arrow-keys.';
    }
  }
}
