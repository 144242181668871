import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { QueryMessage } from '@data/models';
import { MessageSource, MessageType } from '@data/enums';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private notificationSubject = new BehaviorSubject<QueryMessage>({
    type: MessageType.Null,
    message: '',
  });
  notifications$ = this.notificationSubject.asObservable();

  notify(message: string, progress?: number, source?: MessageSource) {
    this.notificationSubject.next({
      type: MessageType.Info,
      progress,
      message,
      source,
    });
  }

  error(message: string) {
    this.notificationSubject.next({
      type: MessageType.Error,
      message,
    });
  }
}
