import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { PickerComponent } from './picker.component';
import { NcalMaterialModule } from '@material/index';
// import { NcalPrimeModule } from '@prime';

@NgModule({
  imports: [
    CommonModule,
    NcalMaterialModule,
    // NcalPrimeModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [PickerComponent],
  exports: [PickerComponent],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class PickerModule { }
