import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { NcalMaterialModule } from '@material/index';
import { CommonModule } from '@angular/common';
import { TreeComponent } from './tree.component';
import { TreeService } from './tree.service';

@NgModule({
  imports: [CommonModule, NcalMaterialModule],
  declarations: [TreeComponent],
  exports: [TreeComponent],
  providers: [TreeService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class TreeModule {}
