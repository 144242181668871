import { User } from '@sentry/angular';
import {
  NavLink,
  QueryArg,
  AppError,
  QueryParam,
  QueryElement,
  FormatColumn,
  InvoiceAsset,
  Client,
  DocStore,
  SyncParam,
  QueryFilter,
  Customer,
  Department,
  WorkflowStatus,
  AuditLog,
  UserRate,
  TypeOfWork,
  TimeTrack,
  Task,
  Project,
  PickerOption,
  FiscalMonthInvoiceOption,
  GlobalSetting,
  GridCol,
  QueryParams,
  MarkerFlag,
  DocumentMarker,
  MarkerOption,
} from './models';

export const TAXRATELABEL: string = 'TAX RATE';
export const BUSINESSNUMLABEL: string = 'Business Number';
export const NCALBUSINESSNUM: string = '811818848';
export const NCALFULLNAME: string = 'Nielsen, Clark & Associates Ltd. O/A NCAL Engineering';
export const SALESTAXLABEL: string = 'SALES TAX';
export const OTHERLABEL: string = 'OTHER';
export const TOTALLABEL: string = 'TOTAL';

export const QUERYFILTERS: QueryFilter[] = [
  {
    label: 'This Month',
    filter: [
      { key: 'dateFrom', val: ''},
      { key: 'dateTo', val: ''},
    ],
    entity: 'timetrack',
    query: 'current',
  },
  {
    label: 'Last Month',
    filter: [
      { key: 'dateFrom', val: ''},
      { key: 'dateTo', val: ''},
    ],
    entity: 'timetrack',
    query: 'previous',
  },
  {
    label: 'All Time',
    filter: [],
    entity: 'timetrack',
    query: 'all',
  },
  {
    label: 'Open',
    filter: [
      {key: 'status', val: 'open'},
    ],
    query: 'open',
    entity: 'tasks',
  },
  {
    label: 'Completed',
    filter: [
      {key: 'status', val: 'completed'},
    ],
    query: 'completed',
    entity: 'tasks',
  },
  {
    label: 'All Tasks',
    filter: [],
    query: 'all',
    entity: 'tasks',
  },
  {
    label: 'Billable',
    filter: [
      {key: 'billable', val: 'true'},
    ],
    query: 'billable',
    entity: 'typesOfWork',
  },
  {
    label: 'Non-Billable',
    filter: [
      {key: 'billable', val: 'false'},
    ],
    query: 'non-billable',
    entity: 'typesOfWork',
  },
  {
    label: 'All Types',
    filter: [],
    query: 'all',
    entity: 'typesOfWork',
  },
];

const API_URL = "<your actiTIME URL>/api/v1/swagger"
const USER = "admin"
const PASSWORD = "manager"
const HEADERS = {"Authorization": "Basic " + btoa(USER + ":" + PASSWORD)}
const GET = {headers: HEADERS}
const POST = {method: "POST", headers: HEADERS, "Content-Type": "application/json"}
const TO_JSON = r => r.json()
const SUM = (a, b) => a + b
const DATE = (() => {let date = new Date(); date.setMonth(date.getMonth()-1); return date.toISOString().substring(0,10);})();

function formatTime(minutes: number) {
  let hours = Math.floor(minutes / 60) + ""
  let hourMinutes = (minutes % 60) + ""
  if (hourMinutes.length < 2) hourMinutes = "0" + hourMinutes
  return hours + ":" + hourMinutes
}

export const INVOICEASSETS: InvoiceAsset[] = [
  {label: 'brandlogo', name: 'ncal_logo.jpg'},
  {label: 'endorsement', name: 'ncal-invoice-endorsement.png'},
];

export const APPSETTINGS: { [key: string]: string } = {
  customers: 'Customer Address Settings',
  clients: 'Customer Name Settings',
};

export const STRINGKEYS: string[] = [
  'lastUrlWithParams',
  'datatype',
  'token',
]

export const DATASETKEYS: string[] = [
  'customeraddresses',
  'customers',
  'clients',
  'projects',
  'departments',
  'tasks',
  'timetracks',
  'typesofwork',
  'userrates',
  'users',
  'workflowstatuses',
  'auditlognodes',
  'auditlogs',
];

export const AUDITLOGKEYS: string[] = [
  'auditLogs',
];

export const AUDITLOGNODEKEYS: string[] = [
  'auditLogNodes',
];

export const SYNCPARAMS: SyncParam[] = [
  {
    id: 1,
    error: null,
    progress: 0,
    syncing: false,
    complete: false,
    value: 'unsynced',
    key: 'customeraddresses',
    label: 'Customer Addresses',
  },
  {
    id: 2,
    error: null,
    progress: 0,
    syncing: false,
    complete: false,
    key: 'customers',
    value: 'unsynced',
    label: 'Customers',
  },
  {
    id: 3,
    error: null,
    progress: 0,
    key: 'clients',
    syncing: false,
    complete: false,
    label: 'Clients',
    value: 'unsynced',
  },
  {
    id: 4,
    error: null,
    progress: 0,
    syncing: false,
    complete: false,
    key: 'departments',
    value: 'unsynced',
    label: 'Departments',
  },
  {
    id: 5,
    error: null,
    progress: 0,
    syncing: false,
    complete: false,
    key: 'projects',
    value: 'unsynced',
    label: 'Projects',
  },
  {
    id: 6,
    error: null,
    progress: 0,
    key: 'tasks',
    label: 'Tasks',
    syncing: false,
    complete: false,
    value: 'unsynced',
  },
  {
    id: 7,
    error: null,
    progress: 0,
    syncing: false,
    complete: false,
    key: 'timetracks',
    value: 'unsynced',
    label: 'Timetracks',
  },
  {
    id: 8,
    error: null,
    progress: 0,
    syncing: false,
    complete: false,
    key: 'userratess',
    value: 'unsynced',
    label: 'User Rates',
  },
  {
    id: 9,
    error: null,
    progress: 0,
    key: 'users',
    label: 'Users',
    syncing: false,
    complete: false,
    value: 'unsynced',
  },
  {
    id: 10,
    error: null,
    progress: 0,
    syncing: false,
    complete: false,
    value: 'unsynced',
    key: 'workflowstatuses',
    label: 'Workflow Statuses',
  },
  {
    id: 11,
    error: null,
    progress: 0,
    syncing: false,
    complete: false,
    key: 'invoices',
    value: 'unsynced',
    label: 'Invoices',
  },
];

export const DEFAULTCLIENTS: Client[] = [
  { id: 1, name: 'Alphabow Energy Ltd.', clientid: '' },
  { id: 2, name: 'Arcade Welding', clientid: '' },
  { id: 3, name: 'Area 51 Machine Design', clientid: '' },
  { id: 4, name: 'Aspenleaf Energy Limited', clientid: '' },
  { id: 5, name: 'Carbonex Contractors Ltd.', clientid: '' },
  { id: 6, name: 'Canadian Natural Resources Ltd.', clientid: '' },
  { id: 7, name: 'Hitic Energy Ltd.', clientid: '' },
  { id: 8, name: 'General Electric Canada Ltd.', clientid: '' },
  { id: 19, name: 'GKM Fabrication Inc.', clientid: '' },
  { id: 9, name: 'MacQuarie', clientid: '' },
  { id: 10, name: 'Modern Niagara', clientid: '' },
  { id: 11, name: 'NCAL', clientid: '' },
  { id: 12, name: 'Obsidian Energy', clientid: '' },
  { id: 13, name: 'Ovintiv Inc.', clientid: '' },
  { id: 14, name: 'Sphere Energy Corporation', clientid: '' },
  { id: 15, name: 'Transworld', clientid: '' },
  { id: 16, name: 'Twist Oilfield Manufacturing Ltd.', clientid: '' },
  { id: 17, name: 'Tyke West Limited', clientid: '' },
  { id: 18, name: 'Vital Energy Inc.', clientid: '' },
];

export const ERR_CLASS: { [key: string]: string } = {
  auth: 'UNAUTHORIZED',
  badreq: 'BAD REQUEST',
  forbid: 'FORBIDDEN',
  gen: 'GENERAL',
  nores: 'NOT FOUND',
  permit: 'NOT ALLOWED',
  rate: 'RATE-LIMIT',
  server: 'SERVER ERROR',
  unknown: 'UNKNOWN',
};

export const UNKNOWN_ERROR: AppError = {
  message: 'An unexpected error occurred. Refresh the application?',
  header: 'Error Notification',
  type: 'UNKNOWN'
};

export const AUTH_ERROR: AppError = {
  message: 'An unexpected error occurred. You\'ll need to log in before continuing.',
  header: 'Error Notification',
  type: 'UNAUTHORIZED'
};

export const BADREQ_ERROR: AppError = {
  message: 'An unexpected error occurred. Refresh the application?',
  header: 'Error Notification',
  type: 'BAD REQUEST'
};

export const FORBID_ERROR: AppError = {
  message: 'An unexpected error occurred. You\'ll need to log in before continuing.',
  header: 'Error Notification',
  type: 'FORBIDDEN'
};

export const NORES_ERROR: AppError = {
  message: 'An unexpected error occurred. After refreshing the application,\n'
    + 'contact support@peloton.com if the error persists.',
  header: 'Error Notification',
  type: 'NOT FOUND'
};

export const PERMIT_ERROR: AppError = {
  message: 'An unexpected error occurred. After refreshing the application,\n'
    + 'contact support@peloton.com if the error persists.',
  header: 'Error Notification',
  type: 'NOT ALLOWED'
};

export const RATE_ERROR: AppError = {
  message: 'An unexpected error occurred. Wait 30 ' +
    'seconds before refreshing the application?',
  header: 'Error Notification',
  type: 'RATE-LIMIT'
};

export const SERVER_ERROR: AppError = {
  message: 'An unexpected error occurred. After refreshing the application,\n'
    + 'contact support@peloton.com if the error persists.',
  header: 'Error Notification',
  type: 'SERVER'
};

export const GEN_ERROR: AppError = {
  message: 'An unexpected error occurred. Refresh the application?',
  header: 'Error Notification',
  type: 'ERROR'
};

export const NAVLINKS: NavLink[] = [
  {
    active: false,
    icon: 'dns',
    name: 'explore',
    label: 'Explore',
    route: '/explore',
    summary: `Use the controls below to explore data queried directly from ActiTime's API.`,
    details: `Save and customize the data you want to include and properties you want to filter the data by,
    before the results can be exported as CSV, Excel and/or PDF files for external
    use on the Export page.`,
    showing: true,
    visible: true,
  },
  // {
  //   active: false,
  //   icon: 'refresh',
  //   name: 'syncing',
  //   label: 'Syncing',
  //   route: '/syncing',
  //   summary: `Syncing latest NCAL Data for Automated Invoice Export`,
  //   details: `You will shortly be redirected to the Export page...`,
  //   showing: false,
  //   visible: true,
  // },
  {
    active: false,
    icon: 'backup',
    name: 'export',
    label: 'Export',
    route: '/export',
    summary: `Use the fields below to specify the data you'd like to
    query from ActiTime's API and have included in a
    PDF Invoice.`,
    details: `Historical Invoices can also be generated and compared against existing documents
    as a means of testing the accuracy, precision & formatting of the generated invoices.`,
    showing: false,
    visible: true,
  },
  {
    active: false,
    icon: 'refresh',
    name: 'revisions',
    label: 'Revisions',
    route: '/revisions',
    summary: `Upload NCAL Invoices below, make corrections, and then re-generate a new export.`,
    details: `Historical Invoices can also be generated and compared against existing documents
    as a means of testing the accuracy, precision & formatting of the generated invoices.`,
    showing: false,
    visible: true,
  },
  {
    active: false,
    icon: 'settings',
    name: 'settings',
    label: 'Settings',
    route: '/settings',
    summary: `Certain features can be configured to behave in one way or another - others can be toggled on and/or off.`,
    details: `This is also an area where you can take a chance to leave some direct feedback - something not working?
    Shoot me a message, send me an email, or use the keyboard shortcut for pinning your feedback to a specific element
    on the page you'd like me to look at.`,
    showing: false,
    visible: true,
  },
];

export const ROLEOPTIONS: any[] = [
  {name: 'Developer', key: 'developer'},
  {name: 'Admin', key: 'admin'},
  {name: 'User', key: 'user'},
];

export const TAB = 9;
export const UP = 38;
export const F2 = 113;
export const END = 35;
export const HOME = 36;
export const CTRL = 17;
export const PGUP = 33;
export const DOWN = 40;
export const LEFT = 37;
export const RIGHT = 39;
export const ENTER = 13;
export const SHIFT = 16;
export const PGDOWN = 34;
export const INSERT = 45;

export const MetaKeyboardEventKeys = [
  'Backspace',
  'Tab',
  'Enter',
  'Backspace',
  'Control',
  'Shift',
  'Alt',
  'Pause',
  'CapsLock',
  'Escape',
  'PageUp',
  'PageDown',
  'End',
  'Home',
  'ArrowLeft',
  'ArrowRight',
  'ArrowDown',
  'ArrowUp',
  'PrintScreen',
  'Insert',
  'Delete',
  'Meta',
  'ContextMenu',
  'NumLock',
  'ScrollLock',
  'AudioVolumeMute',
  'AudioVolumeDown',
  'AudioVolumeUp',
  'LaunchMediaPlayer',
  'LaunchApplication1',
  'LaunchApplication2',
  'F1',
  'F2',
  'F3',
  'F4',
  'F5',
  'F6',
  'F7',
  'F8',
  'F9',
  'F10',
  'F11',
  'F12'
];

export const isUserOnMobile = (): boolean => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
}

export const REVISIONSNOTE: string = 'Make revisions here, and they will be parsed and reflected in the preview pane - if ' +
  'the reflected changes are correct, please finalize the revision by clicking the Submit Changes button below. If there ' +
  'the change has been misinterpreted, please select Make Revision, try again and contact support@ncalweb.ca';

// "app-state" entity at the end of this app...
// export enum StorageKeys {
//   LastUrlWithParams = 'lastUrlWithParams',
//   LastRequest = 'lastRequest',
//   TimeTracked = 'timeTracked',
//   AppState = 'appState',
//   History = 'history',
//   CustomerAddresses = 'customeraddresses',
//   Clients = 'clients',
//   Customers = 'customers',
//   Departments = 'departments',
//   Projects = 'projects',
//   Tasks = 'tasks',
//   Timetracks = 'timetracks',
//   TypesOfWork = 'typesOfWork',
//   UserRates = 'userRates',
//   Users = 'users',
//   WorkflowStatuses = 'workflowStatuses',
//   AuditLogNodes = 'auditLogNodes',
//   AuditLogs = 'auditLogs',
//   AppSettings = 'appSettings',
//   InvoiceEndDate = 'invoiceEndDate',
//   InvoiceStartDate = 'invoiceStartDate',
//   FeedbackComments = 'feedbackComments',
//   ActivePreviewInvoiceNumber = 'activepreviewinvoicenumber',
//   StartingInvoicesFrom = 'startinginvoicesfrom',
//   CurrentInvoiceNumber = 'currentinvoicenumber',
//   ManualInvoiceNumber = 'manualInvoiceNumber',
//   CurrentFiscalMonth = 'currentFiscalMonth',
//   InvoiceFiscalMonth = 'invoiceFiscalMonth',
//   DocDefSchemaSet = 'docdefschemaset',
//   TotalsByInvoice = 'totalsByInvoice',
//   HoursByInvoice = 'hoursByInvoice',
//   InvoicedHours = 'invoicesHours',
//   InvoicedTotal = 'invoicedTotal',
//   Credentials = 'credentials',
//   MyTokens = 'mytokens',
//   WhoAmI = 'whoami',
//   Token = 'token',
//   Me = 'me',
// }

// export enum CacheKeys {
//   LastUrlWithParams = 'lastUrlWithParams',
//   LastRequest = 'lastRequest',
//   TimeTracked = 'timeTracked',
//   AppState = 'appState',
//   History = 'history',
//   CustomerAddresses = 'customeraddresses',
//   Clients = 'clients',
//   Customers = 'customers',
//   Departments = 'departments',
//   Projects = 'projects',
//   Tasks = 'tasks',
//   Timetracks = 'timetracks',
//   TypesOfWork = 'typesofwork',
//   UserRates = 'userrates',
//   Users = 'users',
//   WorkflowStatuses = 'workflowstatus',
//   AuditLogNodes = 'auditlognodes',
//   AuditLogs = 'auditlogs',
//   AppSettings = 'appSettings',
//   InvoiceStartDate = 'invoiceStartDate',
//   InvoiceEndDate = 'invoiceEndDate',
//   FeedbackComments = 'feedbackcomments',
//   CurrentFiscalMonth = 'currentFiscalMonth',
//   InvoiceFiscalMonth = 'invoiceFiscalMonth',
//   TotalsByInvoice = 'totalsByInvoice',
//   HoursByInvoice = 'hoursByInvoice',
//   InvoicedHours = 'invoicesHours',
//   InvoicedTotal = 'invoicedTotal',
//   Credentials = 'credentials',
//   Token = 'token',
// }

// export enum BackupKeys {
//   GeneratedInvoices = 'invoices-generated',
//   CorrectedInvoices = 'invoices-corrected',
//   ApprovedInvoices = 'invoices-approved',
//   VerifiedInvoices = 'invoices-verified',
//   TotalsByInvoice = 'totalsbyinvoice',
//   HoursByInvoice = 'hoursbyinvoice',
//   CustomerAddresses = 'customeraddresses',
//   WorkflowStatuses = 'workflowstatuses',
//   AuditLogNodes = 'auditlognodes',
//   Departments = 'departments',
//   TypesOfWork = 'typesofwork',
//   Timetracks = 'timetracks',
//   Customers = 'customers',
//   UserRates = 'userrates',
//   AuditLogs = 'auditlogs',
//   Projects = 'projects',
//   Invoices = 'invoices',
//   Clients = 'clients',
//   History = 'history',
//   DocDefs = 'docdefs',
//   Tasks = 'tasks',
//   Users = 'users',
// }

export const DocumentMarkerFlags: MarkerFlag[] = [
  {flag: 'docheader', section: 'Invoice Sumamry', options: {}},
  {flag: 'invoiceblockheader', section: 'Invoice Sumamry', options: {}},
  {flag: 'invoiceblocktext', section: 'Invoice Sumamry', options: {}},
  {flag: 'blocktext', section: 'Invoice Sumamry', options: {}},
  {flag: 'blocktextval', section: 'Invoice Sumamry', options: {}},
  {flag: 'invoicetable', section: 'Invoice Sumamry Table', options: {}},
  {flag: 'signaturefooter', section: 'Invoice Sumamry', options: {}},
  {flag: 'pageheader', section: 'Invoice Attachment', options: {}},
  {flag: 'pagesubheader', section: 'Invoice Sumamry', options: {}},
  {flag: 'timesheetlogo', section: 'Invoice Sumamry', options: {}},
  {flag: 'timesheetsummarytable', section: 'Timesheet Table', options: {}},
  {flag: 'timesheettable', section: 'Timesheet Table', options: {}},
  {flag: 'tablerowlabel', section: 'Timesheet Table', options: {}},
  {flag: 'tablerowvalue', section: 'Timesheet Table', options: {}},
  {flag: 'timesheetcolheader', section: 'Timesheet Table', options: {}},
  {flag: 'timesheetfirstcolcell', section: 'Timesheet Table', options: {}},
  {flag: 'timesheetmidcolcell', section: 'Timesheet Table', options: {}},
  {flag: 'timesheetlastcolcell', section: 'Timesheet Table', options: {}},
  {flag: 'timesheetmultirowrightcol', section: 'Timesheet Table', options: {}},
  {flag: 'timesheetfirstcolcell-pad1left', section: 'Timesheet Table', options: {}},
  {flag: 'timesheetfirstcolcell-pad2left', section: 'Timesheet Table', options: {}},
];

export const DocumentMarkerOptions: MarkerOption[] = [
  {id: 1, key: 'docheader', label: 'Invoice Sumamry'},
  {id: 2, key: 'invoiceblockheader', label: 'Invoice Sumamry'},
  {id: 3, key: 'invoiceblocktext', label: 'Invoice Sumamry'},
  {id: 4, key: 'blocktext', label: 'Invoice Sumamry'},
  {id: 5, key: 'blocktextval', label: 'Invoice Sumamry'},
  {id: 6, key: 'invoicetable', label: 'Invoice Sumamry Table'},
  {id: 7, key: 'signaturefooter', label: 'Invoice Sumamry'},
  {id: 8, key: 'pageheader', label: 'Invoice Attachment'},
  {id: 9, key: 'pagesubheader', label: 'Invoice Sumamry'},
  {id: 10, key: 'timesheetlogo', label: 'Invoice Sumamry'},
  {id: 11, key: 'timesheetsummarytable', label: 'Timesheet Table'},
  {id: 12, key: 'timesheettable', label: 'Timesheet Table'},
  {id: 13, key: 'tablerowlabel', label: 'Timesheet Table'},
  {id: 14, key: 'tablerowvalue', label: 'Timesheet Table'},
  {id: 15, key: 'timesheetcolheader', label: 'Timesheet Table'},
  {id: 16, key: 'timesheetfirstcolcell', label: 'Timesheet Table'},
  {id: 17, key: 'timesheetmidcolcell', label: 'Timesheet Table'},
  {id: 18, key: 'timesheetlastcolcell', label: 'Timesheet Table'},
  {id: 19, key: 'timesheetmultirowrightcol', label: 'Timesheet Table'},
  {id: 20, key: 'timesheetfirstcolcell-pad1left', label: 'Timesheet Table'},
  {id: 21, key: 'timesheetfirstcolcell-pad2left', label: 'Timesheet Table'},
];

export const DocumentMarkers: DocumentMarker[] = [
  {id: 1, key: 'docheader', label: 'Invoice Sumamry', includes: {}, editable: true},
  {id: 2, key: 'invoiceblockheader', label: 'Invoice Sumamry', includes: {}, editable: true},
  {id: 3, key: 'invoiceblocktext', label: 'Invoice Sumamry', includes: {}, editable: true},
  {id: 4, key: 'blocktext', label: 'Invoice Sumamry', includes: {}, editable: true},
  {id: 5, key: 'blocktextval', label: 'Invoice Sumamry', includes: {}, editable: true},
  {id: 6, key: 'invoicetable', label: 'Invoice Sumamry Table', includes: {}, editable: true},
  {id: 7, key: 'signaturefooter', label: 'Invoice Sumamry', includes: {}, editable: true},
  {id: 8, key: 'pageheader', label: 'Invoice Attachment', includes: {}, editable: true},
  {id: 9, key: 'pagesubheader', label: 'Invoice Sumamry', includes: {}, editable: true},
  {id: 10, key: 'timesheetlogo', label: 'Invoice Sumamry', includes: {}, editable: true},
  {id: 11, key: 'timesheetsummarytable', label: 'Timesheet Table', includes: {}, editable: true},
  {id: 12, key: 'timesheettable', label: 'Timesheet Table', includes: {}, editable: true},
  {id: 13, key: 'tablerowlabel', label: 'Timesheet Table', includes: {}, editable: true},
  {id: 14, key: 'tablerowvalue', label: 'Timesheet Table', includes: {}, editable: true},
  {id: 15, key: 'timesheetcolheader', label: 'Timesheet Table', includes: {}, editable: true},
  {id: 16, key: 'timesheetfirstcolcell', label: 'Timesheet Table', includes: {}, editable: true},
  {id: 17, key: 'timesheetmidcolcell', label: 'Timesheet Table', includes: {}, editable: true},
  {id: 18, key: 'timesheetlastcolcell', label: 'Timesheet Table', includes: {}, editable: true},
  {id: 19, key: 'timesheetmultirowrightcol', label: 'Timesheet Table', includes: {}, editable: true},
  {id: 20, key: 'timesheetfirstcolcell-pad1left', label: 'Timesheet Table', includes: {}, editable: true},
  {id: 21, key: 'timesheetfirstcolcell-pad2left', label: 'Timesheet Table', includes: {}, editable: true},
];

export const INVOICENAMINGCONVENTION: string = 'NCAL_Engineering_Invoice'
export const DISPCHARGE: number = 8;
export const TAXRATE: number = 0.05;
export const TAXRATEDISPLAY: string = '5.00%';
export const SpecialFormatColumns: FormatColumn[] = [
  {
    hidden: true,
    datatype: 'object',
    label: 'allowedActions',
    display: 'Allowed Actions',
  },
  {
    hidden: false,
    datatype: 'text',
    label: 'customerName',
    display: 'Customer Name',
  },
  {
    hidden: false,
    label: 'email',
    datatype: 'text',
    display: 'Email',
  },
  {
    masked: true,
    hidden: false,
    datatype: 'number',
    label: 'userId',
    display: 'User ID',
  },
  {
    masked: true,
    hidden: false,
    datatype: 'number',
    label: 'taskId',
    display: 'Task ID',
  },
  {
    masked: true,
    hidden: false,
    datatype: 'number',
    label: 'projectId',
    display: 'Project ID',
  },
  {
    masked: true,
    hidden: false,
    datatype: 'text',
    label: 'customerId',
    display: 'Customer ID',
  },
  {
    masked: true,
    hidden: false,
    datatype: 'number',
    label: 'departmentId',
    display: 'Department ID',
  },
  {
    masked: true,
    hidden: false,
    datatype: 'number',
    label: 'timeZoneGroupId',
    display: 'Timezone Group ID',
  },
  {
    hidden: false,
    label: 'releaseDate',
    datatype: 'dateString',
    display: 'Release Date',
  },
  {
    hidden: false,
    datatype: 'text',
    label: 'lastName',
    display: 'Last Name',
  },
  {
    hidden: false,
    datatype: 'text',
    label: 'middleName',
    display: 'Middle Name',
  },
  {
    hidden: false,
    datatype: 'text',
    label: 'firstName',
    display: 'First Name',
  },
  {
    hidden: false,
    datatype: 'text',
    label: 'fullName',
    display: 'Full Name',
  },
  {
    hidden: false,
    datatype: 'text',
    label: 'dayOffset',
    display: 'Day Offset',
  },
  {
    hidden: false,
    datatype: 'text',
    label: 'estimatedTime',
    display: 'Estimated Time',
  },
  {
    hidden: false,
    datatype: 'text',
    label: 'projectName',
    display: 'Project Name',
  },
  {
    masked: true,
    hidden: false,
    datatype: 'number',
    label: 'typeOfWorkId',
    display: 'Type of Work ID',
  },
  {
    hidden: false,
    datatype: 'text',
    label: 'typeOfWorkName',
    display: 'Type of Work Name',
  },
  {
    masked: true,
    hidden: false,
    datatype: 'number',
    label: 'workflowStatusId',
    display: 'Workflow Status ID',
  },
  {
    hidden: false,
    datatype: 'text',
    label: 'workflowStatusName',
    display: 'Workflow Status Name',
  },
  {
    hidden: false,
    label: 'active',
    display: 'Active',
    datatype: 'boolean',
  },
  {
    hidden: false,
    label: 'archived',
    display: 'Archived',
    datatype: 'boolean',
  },
  {
    hidden: true,
    label: 'dayOffset',
    datatype: 'number',
    display: 'Day Offset',
  },
  {
    hidden: false,
    label: 'date',
    datatype: 'string',
    display: 'Date',
  },
  {
    hidden: true,
    label: 'url',
    display: 'Url',
    datatype: 'string',
  },
];
export const HiddenGridColumns: GridCol[] = [
  {
    hidden: true,
    field: 'customerId',
    entity: 'customers',
  },
  {
    hidden: true,
    field: 'allowedActions',
    entity: 'customers',
  },
  {
    hidden: true,
    field: 'departmentId',
    entity: 'departments',
  },
  {
    hidden: true,
    field: 'projectId',
    entity: 'projects',
  },
  {
    hidden: true,
    field: 'customerId',
    entity: 'projects',
  },
  {
    hidden: true,
    field: 'allowedActions',
    entity: 'projects',
  },
  {
    hidden: true,
    field: 'taskId',
    entity: 'tasks',
  },
  {
    hidden: true,
    field: 'workflowStatusId',
    entity: 'tasks',
  },
  {
    hidden: true,
    field: 'typeOfWorkId',
    entity: 'tasks',
  },
  {
    hidden: true,
    field: 'allowedActions',
    entity: 'tasks',
  },
  {
    hidden: true,
    field: 'estimatedTime',
    entity: 'tasks',
  },
  {
    hidden: true,
    field: 'workflowStatusName',
    entity: 'tasks',
  },
  {
    hidden: true,
    field: 'customerId',
    entity: 'tasks',
  },
  {
    hidden: true,
    field: 'projectId',
    entity: 'tasks',
  },
  {
    hidden: true,
    field: 'dayOffset',
    entity: 'timetrack',
  },
  {
    hidden: true,
    field: 'typesOfWorkId',
    entity: 'typesOfWork',
  },
  {
    hidden: true,
    field: 'userId',
    entity: 'users',
  },
  {
    hidden: true,
    field: 'middleName',
    entity: 'users',
  },
  {
    hidden: true,
    field: 'allowedActions',
    entity: 'users',
  },
  {
    hidden: true,
    field: 'departmentId',
    entity: 'users',
  },
  {
    hidden: true,
    field: 'timeZoneGroupId',
    entity: 'users',
  },
  {
    hidden: true,
    field: 'releaseDate',
    entity: 'users',
  },
  {
    hidden: true,
    field: 'allowedActions',
    entity: 'workflowStatuses',
  },
  {
    hidden: true,
    field: 'workflowStatusId',
    entity: 'workflowStatuses',
  },
];

export const FISCALDATET0: string = '12-01';

export const DefaultGlobalSettings: GlobalSetting[] = [
  // TODO
];

export const FISCALMONTHT0: FiscalMonthInvoiceOption = {
  params: {
    label: 'December',
    value: 0,
    key: 0,
  },
  fiscalId: 0,
  calendarId: 11,
  shortLabel: 'Dec',
  fullLabel: 'December',
};

export const FISCALMONTHS: PickerOption[] = [
  {
    value: 0,
    key: 'december',
    label: 'December',
  },
  {
    value: 1,
    key: 'january',
    label: 'January',
  },
  {
    value: 2,
    key: 'february',
    label: 'February',
  },
  {
    value: 3,
    key: 'march',
    label: 'March',
  },
  {
    value: 4,
    key: 'april',
    label: 'April',
  },
  {
    value: 5,
    key: 'may',
    label: 'May',
  },
  {
    value: 6,
    key: 'june',
    label: 'June',
  },
  {
    value: 7,
    key: 'july',
    label: 'July',
  },
  {
    value: 8,
    key: 'august',
    label: 'August',
  },
  {
    value: 9,
    key: 'september',
    label: 'September',
  },
  {
    value: 10,
    key: 'october',
    label: 'October',
  },
  {
    value: 11,
    key: 'november',
    label: 'November',
  },
];

export const DAYS: PickerOption[] = [
  {
    key: 0,
    label: 'Sun',
    value: 'Sunday',
  }, {
    key: 1,
    label: 'Mon',
    value: 'Monday',
  }, {
    key: 2,
    label: 'Tue',
    value: 'Tuesday',
  }, {
    key: 3,
    label: 'Wed',
    value: 'Wednesday',
  }, {
    key: 4,
    label: 'Thu',
    value: 'Thursday',
  }, {
    key: 5,
    label: 'Fri',
    value: 'Friday',
  }, {
    key: 6,
    label: 'Sat',
    value: 'Saturday',
  },
]

export const MONTHS: PickerOption[] = [
  {
    value: 1,
    key: 'january',
    label: 'January',
  },
  {
    value: 2,
    key: 'february',
    label: 'February',
  },
  {
    value: 3,
    key: 'march',
    label: 'March',
  },
  {
    value: 4,
    key: 'april',
    label: 'April',
  },
  {
    value: 5,
    key: 'may',
    label: 'May',
  },
  {
    value: 6,
    key: 'june',
    label: 'June',
  },
  {
    value: 7,
    key: 'july',
    label: 'July',
  },
  {
    value: 8,
    key: 'august',
    label: 'August',
  },
  {
    value: 9,
    key: 'september',
    label: 'September',
  },
  {
    value: 10,
    key: 'october',
    label: 'October',
  },
  {
    value: 11,
    key: 'november',
    label: 'November',
  },
  {
    value: 12,
    key: 'december',
    label: 'December',
  },
];

export const YEARS: PickerOption[] = [
  {
    key: 2015,
    value: '2015',
  },
  {
    key: 2016,
    value: '2016',
  },
  {
    key: 2017,
    value: '2017',
  },
  {
    key: 2018,
    value: '2018',
  },
  {
    key: 2019,
    value: '2019',
  },
  {
    key: 2020,
    value: '2020',
  },
  {
    key: 2021,
    value: '2021',
  },
  {
    key: 2022,
    value: '2022',
  },
  {
    key: 2023,
    value: '2023',
  },
  {
    key: 2024,
    value: '2024',
  },
  {
    key: 2025,
    value: '2025',
  },
  {
    key: 2026,
    value: '2026',
  },
  {
    key: 2027,
    value: '2027',
  },
  {
    key: 2028,
    value: '2028',
  },
  {
    key: 2029,
    value: '2029',
  },
];

export const MAXPAGESIZE: number = 1000;
export const MINDATEFROM: string = '1970-01-01';
export const MAXDATETO: string = '3000-12-31';

export const QUERYPARAMS: QueryParam[] = [
  { key: 'offset', val: 0 },
  { key: 'limit', val: MAXPAGESIZE },
];

export const DEFAULTTTPARAMS: QueryParam[] = [
  { key: 'stopAfter', val: MAXPAGESIZE },
];

export const DEFAULTPARAMS: QueryParam[] = [
  { key: 'offset', val: 0 },
  { key: 'limit', val: MAXPAGESIZE },
];

export const NEXTPARAMS: QueryParam[] = [
  { key: 'offset', val: MAXPAGESIZE },
  { key: 'limit', val: MAXPAGESIZE },
];

export const TTPARAMS: QueryParam[] = [
  { key: 'dateFrom', val: MINDATEFROM },
  { key: 'dateTo', val: MAXDATETO },
  { key: 'stopAfter', val: MAXPAGESIZE },
];

export const INVPARAMS: QueryParam[] = [
  { key: 'archived', val: false },
  { key: 'dateFrom', val: '' },
  { key: 'dateTo', val: '' },
];

export const QUERYARGS: QueryArg[] = [
  {
    label: 'Customers',
    param: 'customers',
    icon: 'group',  //contact_mail  //receipt
  },
  {
    label: 'Departments',
    param: 'departments',
    icon: 'domain', //business
  },
  {
    label: 'Projects',
    param: 'projects',
    icon: 'apps', //developer_dashboard //extension
  },
  {
    label: 'Tasks',
    param: 'Tasks',
    icon: 'view_list',  //event_note  //today
  },
  {
    label: 'Timetracks',
    param: 'timetrack',
    icon: 'timer',  //timelapse //watch
  },
  {
    label: 'Types of Work',
    param: 'typesOfWork',
    icon: 'recent_actors',  //work
  },
  {
    label: 'User Rates',
    param: 'userRates',
    icon: 'multiline_chart', //monetization_on  //attach_money
  },
  {
    label: 'Users',
    param: 'users',
    icon: 'portrait', //account_circle
  },
  {
    label: 'Workflow Statuses',
    param: 'workflowStatuses',
    icon: 'tune', //camera  //track_changes
  }
];

export const QUERYELEMENTS: QueryElement[] = [
  {
    priority: true,
    cached: false,
    param: {defaults: true, extras: true},
    label: 'Customers',
    url: {
      path: 'customers',
      defaults: true,
      extras: true,
    },
  },
  {
    priority: true,
    cached: false,
    param: {defaults: true, extras: true},
    label: 'Departments',
    url: {
      path: 'departments',
      defaults: true,
      extras: true,
    },
  },
  {
    priority: true,
    cached: false,
    param: {defaults: true, extras: true},
    label: 'Projects',
    url: {
      path: 'projects',
      defaults: true,
      extras: true,
    },
  },
  {
    priority: true,
    cached: false,
    param: {defaults: true, extras: true},
    label: 'Tasks',
    url: {
      path: 'tasks',
      defaults: true,
      extras: true,
    },
  },
  {
    priority: true,
    cached: false,
    param: {defaults: true, extras: true},
    label: 'Timetracks',
    url: {
      path: 'timetrack',
      defaults: true,
      extras: true,
    },
  },
  {
    priority: false,
    cached: true,
    param: {defaults: true, extras: false},
    label: 'Types of Work',
    url: {
      path: 'typesOfWork',
      defaults: true,
      extras: true,
    },
  },
  {
    priority: false,
    cached: true,
    param: {defaults: true, extras: false},
    label: 'User Rates',
    url: {
      path: 'userRates',
      defaults: true,
      extras: true,
    },
  },
  {
    priority: true,
    cached: false,
    param: {defaults: true, extras: true},
    label: 'Users',
    url: {
      path: 'users',
      defaults: true,
      extras: true,
    },
  },
  {
    priority: false,
    cached: false,
    param: {defaults: true, extras: false},
    label: 'Workflow Statuses',
    url: {
      path: 'workflowStatuses',
      defaults: true,
      extras: true,
    },
  },
];

export const DOCSERVERS: any = {
  assets: 'assets',
  engine: 'engine',
  sync: 'sync',
  fast: 'fast',
  io: 'dbio',
};

export const DOCUMENTENTITIES: DocStore[] = [
  {
    path: 'clients',
    name: 'Clients',
    model: [] as Client[],
  },
  {
    path: 'customers',
    name: 'Customers',
    model: [] as Customer[],
  },
  {
    path: 'departments',
    name: 'Departments',
    model: [] as Department[],
  },
  {
    path: 'projects',
    name: 'Projects',
    model: [] as Project[],
  },
  {
    path: 'tasks',
    name: 'Tasks',
    model: [] as Task[],
  },
  {
    path: 'timetracks',
    name: 'Timetracks',
    model: [] as TimeTrack[],
  },
  {
    path: 'typesofwork',
    name: 'Types of Work',
    model: [] as TypeOfWork[],
  },
  {
    path: 'userrates',
    name: 'User Rates',
    model: [] as UserRate[],
  },
  {
    path: 'users',
    name: 'Users',
    model: [] as User[],
  },
  {
    path: 'workflowstatuses',
    name: 'Workflow Statuses',
    model: [] as WorkflowStatus[],
  },
  {
    path: 'auditlogs',
    name: 'Audit Logs',
    model: [] as AuditLog[],
  },
];

export const QUERYPATHS: QueryParams[] = [
  {
    path: 'customers',
    key: 'customers',
    val: 'Customers',
  },
  {
    path: 'departments',
    key: 'departments',
    val: 'Departments',
  },
  {
    path: 'projects',
    key: 'projects',
    val: 'Projects',
  },
  {
    path: 'tasks',
    key: 'tasks',
    val: 'Tasks',
  },
  {
    path: 'timetrack',
    key: 'timetracks',
    val: 'Timetracks',
  },
  {
    path: 'typesOfWork',
    key: 'typesofwork',
    val: 'Types Of Work',
  },
  {
    path: 'userRates',
    key: 'userrates',
    val: 'User Rates',
  },
  {
    path: 'users',
    key: 'users',
    val: 'Users',
  },
  {
    path: 'workflowStatuses',
    key: 'workflowstatuses',
    val: 'Workflow Statuses',
  },
  {
    path: null,
    key: 'clients',
    val: 'Clients',
  },
  {
    path: null,
    key: 'customeraddresses',
    val: 'Customer Addresses',
  },
  {
    path: null,
    key: 'customernames',
    val: 'Customer Names',
  },
  {
    path: null,
    key: 'useraccounts',
    val: 'User Accounts',
  },
  {
    path: null,
    key: 'auditlogs',
    val: 'Audit Logs',
  },
  {
    path: null,
    key: 'invoices',
    val: 'Invoices',
  },
];

export const DEFAULTDOCMARKERS: DocumentMarker[] = [
  {
    editable: true,
    includes: null,
    key: 'invoicesummaryheader',
    label: 'Invoice Summary Header'
  },
  {
    editable: true,
    includes: null,
    key: 'invoicesummaryheader',
    label: 'Invoice Summary Table'
  },
  {
    editable: true,
    includes: null,
    key: 'timesheettablesummary',
    label: 'Timesheet Tracking Header'
  },
  {
    editable: true,
    includes: null,
    key: 'timesheettable',
    label: 'Timesheet Tracking Table'
  },
];
