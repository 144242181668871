import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Customer, Project, Task, TimeTrack, TypeOfWork, User, UserRate, WorkflowStatus, CustomerAddress, Client, Backups, SyncParam, Department } from '@data/models';
import customeraddresses from '@jsonassets/customeraddresses.json';
import customers from '@jsonassets/customers.json';
import clients from '@jsonassets/clients.json';
import departments from '@jsonassets/departments.json';
import projects from '@jsonassets/projects.json';
import tasks from '@jsonassets/tasks.json';
import timetracks from '@jsonassets/timetracks.json';
import typesofwork from '@jsonassets/typesofwork.json';
import users from '@jsonassets/users.json';
import userrates from '@jsonassets/userrates.json';
import workflowstatuses from '@jsonassets/workflowstatuses.json';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  private store: Map<string, any> = new Map();
  private backup: {
    cache: Backups;
  };

  constructor(private db: AngularFireDatabase) {
    this.backup =  {
      cache: this.generateBackups(),
    };
  }

  normalizeDataSet(set: any, key: string): any[] {
    return (!Array.isArray(set) && set.hasOwnProperty(key)) ? set[key] : set;
  }

  generateBackups(): Backups {
    return {
      customeraddresses: this.normalizeDataSet(customeraddresses, 'customeraddresses') as CustomerAddress[],
      customers: this.normalizeDataSet(customers, 'customers') as Customer[],
      clients: this.normalizeDataSet(clients, 'clients') as Client[],
      departments: this.normalizeDataSet(departments, 'departments') as Department[],
      projects: this.normalizeDataSet(projects, 'projects') as Project[],
      tasks: this.normalizeDataSet(tasks, 'tasks') as Task[],
      timetracks: this.normalizeDataSet(timetracks, 'timetracks') as TimeTrack[],
      typesofwork: this.normalizeDataSet(typesofwork, 'typesofwork') as TypeOfWork[],
      userrates: this.normalizeDataSet(userrates, 'userrates') as UserRate[],
      users: this.normalizeDataSet(users, 'users') as User[],
      workflowstatuses: this.normalizeDataSet(workflowstatuses, 'workflowstatuses') as WorkflowStatus[],
      invoices: [],
      docdefs: [],
    };
  }

  broadcast(): void {
    console.log('broadcasting backup cache...');
    console.log(this.backup);
    console.log(this.store);
  }

  public query(predicate: (key: string, value: any) => boolean): Array<any> {
    let results: Array<any> = [];
    this.store.forEach((value: any[], key: string) => {
      if (predicate(key, value)) {
        results.push(value);
      }
    });
    return results;
  }

  public async get(key: string): Promise<any> {
    this.broadcast();
    if (this.has(key)) {
      return await this.store.get(key);
    } else {
      const backupdata = this.backup.cache[key];
      this.store.set(key, backupdata);
      return backupdata;
    }
  }

  async checkMultiple(
    payload: {
      keys: string[],
      values: number[],
    }
  ): Promise<any> {
    const cachedata = payload.keys.map((key: string) => {
      return {[key]: this.has(key) ? 1 : 0 };
    });
    return {
      input: payload,
      data: {results: cachedata},
    };
  }

  public async setLocal(key: string, value: any[]): Promise<void> {
    console.log('setLocal...');
    console.log(key);
    console.log(value);
    this.broadcast();
    this.store.set(key, Array.isArray(value) ? [...value] : value);
  }

  public async set(key: string, value: any[]): Promise<void> {
    this.store.set(key, Array.isArray(value) ? [...value] : value);
    this.broadcast();
    await this.sendToDatabase(key, value);
  }

  async sendToDatabase(key: string, value: any[]): Promise<void> {
    console.log('sending to firebase...');
    await this.setEntities(key, value)
  }

  public async setTo(key: string, value: any): Promise<any> {
    console.log('setting new docdef to collection...');
    await this.getDataset(key).then(async (resp: any[]) => {
      let definitions: any[] = resp;
      if (definitions && definitions.length) {
        console.log('found existing document definitions...');
        definitions.push(value);
      } else {
        console.log('first entry to collection of document definitions...');
        definitions = [value];
      }
      await this.setEntities(key, definitions).then(() => {
        console.log('new docdef synced with database...');
        this.broadcast();
      }).catch(error => {
        console.log('error occurred syncing new docdef to database: ' + error);
      });
    });
  }

  has(key: string): boolean {
    return this.store.has(key);
  }

  clear(): void {
    this.store.clear();
  }

  getEntities(entity: string): Observable<any[]> {
    const entities = this.db.list(`/${entity}`).snapshotChanges();
    return entities;
  }

  async setEntities(key: string, dataset: any[]): Promise<void> { // Observable<any[]> {
    return this.setData(key, dataset).then((result) => {
      console.log(result);
    });
  }

  introspect(): void {
    console.log(this.db);
    console.log(this.db.database);
  }

  // Read data from a specific path
  async getDataset(path: string): Promise<any> {
    return await this.db.list(path).valueChanges().pipe(
      map((results) => results)
    ).toPromise();
  }

  // Read data from a specific path
  getData(path: string): Observable<any> {
    return this.db.object(path).valueChanges();
  }

  getDataSet(path: string): Observable<any[]> {
    return this.db.list(path).valueChanges();
  }

  // Write data to a specific path
  setData(path: string, data: any): Promise<void> {
    return this.db.object(path).set(data);
  }

  // Update data at a specific path
  updateData(path: string, data: any): Promise<void> {
    return this.db.object(path).update(data);
  }

  // Delete data at a specific path
  deleteData(path: string): Promise<void> {
    return this.db.object(path).remove();
  }

  // Bulk write (POST/PUT) operation
  bulkWrite(data: { [key: string]: any }): Promise<void> {
    return this.db.object('/').update(data);
  }
}
