import { AuditNode, InvoiceLog } from "./classes";
import { ReleaseRecord, NotificationType, PickerType, MessageType, MessageSource } from "./enums";

export interface NavLink {
  showing?: boolean;
  details?: string;
  summary?: string;
  active?: boolean;
  visible: boolean;
  icon?: string;
  label: string;
  route: string;
  name: string;
}

export interface FiscalMonthInvoiceOption {
  params?: PickerOption,
  shortLabel?: string;
  fullLabel?: string;
  calendarId: number;
  fiscalId: number;
}

export interface QueryFilter {
  filter: QueryParam[];
  entity: string;
  label: string;
  query: string;
}

export interface DocStore {
  path: string;
  name: string;
  model: any;
}

export interface GlobalSetting {
  permanent: boolean;
  settingid: number;
  custom: boolean;
  route?: string;
  name: string;
}

export interface PickerOption {
  label?: string;
  value: any;
  key: any;
}

export interface DateEntity {
  lom: Date | string;
  fom: Date | string;
  dayofmonth: number;
  dayofweek: number;
  monthname: string;
  weekday: string;
  param: string;
  label: string;
  month: number;
  year: number;
  day: number;
}

export interface PickerContext {
  options: PickerOption[];
  type: PickerType;
}

/** Error entity for consistent error-handling. */
export interface AppError {
  message: string;
  header: string;
  type: string;
}

export interface AppUser {
  username: string;
  role: string;
}

/** Custom constant for global date formatting. */
export class DateConstants {
  static readonly DATEFORMAT = 'MMM dd, yyyy';
  static readonly DTTMFORMAT = `${DateConstants.DATEFORMAT} hh:mm:ss`;
}

export interface InvoiceParam {
  month: {value: number; key: string; label: string};
  year: {key: number; value: string};
  validDateRange: boolean;
  dateFrom: Date | string;
  dateTo: Date | string;
  customer?: Customer;
  project?: Project;
  content?: any;
}

export interface FormatColumn {
  masked?: boolean;
  datatype: string;
  display: string;
  hidden: boolean;
  label: string;
}

export interface EntityContext {
  entity: string;
  dataset: any[];
  options?: any;
  url: string;
}

export interface ICol {
  cellDataType?: string;
  headerName?: string;
  cellRenderer?: any;
  cellClass?: string;
  minWidth?: number;
  filter?: boolean;
  hidden?: boolean;
  masked?: boolean;
  field?: string;
  label?: string;
  title?: string;
  data?: string;
}

export interface INotification {
  type: NotificationType;
  duration: number;
  message: string;
}

export interface PendingChanges {
  hasUnsavedChanges(): boolean;
}

export interface QueryEndpoint {
  defaults?: boolean;
  extras?: boolean;
  path: string;
}

export interface QueryParam {
  key: string;
  val: any;
}

export interface QueryParams {
  path?: string | null;
  key: string;
  val: any;
}

export interface QueryArg {
  label: string;
  param: string;
  icon: string;
}

export interface QueryElement {
  priority?: boolean;
  url: QueryEndpoint;
  param: {
    defaults: boolean;
    extras: boolean;
  },
  cached?: boolean;
  label: string;
}

export interface FiscalYear {
  start: string;
  year: number;
  end: string;
}

export interface DocumentMarker {
  editable: boolean;
  label: string;
  includes?: any | null;
  key: string;
  id?: number;
}

export interface MarkerFlag {
  section: string;
  options?: any;
  flag: string;
}

export interface MarkerOption {
  label: string;
  key: string;
  id?: number;
}

export interface ContentSection {
  columns?: DocColDef[];
  style?: string;
  image?: string;
  fit?: number[];
  text?: string;
  table?: any;
}

export interface MarkedDocumentContent {
  id: string;
  name: string;
  info: { title: string; };
  sections: ContentSection[];
  markers: Map<DocumentMarker, ContentSection>;
}

export interface DocColDef {
  marker?: DocumentMarker;
  height?: string | number | string[] | number[];
  width?: string | number | string[] | number[];
  border?: boolean[] | boolean | boolean[][];
  margin?: number[] | number | number[][];
  paddingBottom?: number | number[];
  paddingRight?: number | number[];
  paddingLeft?: number | number[];
  paddingTop?: number | number[];
  marginBottom?: number | number[];
  marginRight?: number | number[];
  marginLeft?: number | number[];
  marginTop?: number | number[];
  alignment?: string | string[];
  fillColor?: string | string[];
  wordWrap?: string | string[];
  fontSize?: number | number[];
  display?: string | string[];
  bold?: boolean | boolean[];
  style?: string | string[];
  color?: string | string[];
  text?: string | string[];
  type?: string | null;
  ul?: string[];
}

export interface DocTableRowDefs {
  [key: string]: DocColDef[];
}

export interface DocStyleSchema {
  [key: string]: DocColDef;
}

export interface ExcelCol {
  header: string;
  width: number;
  options?: any;
  key: string;
}

export interface RecordOps {
  canSubmitTimetrack?: boolean;
  canModify?: boolean;
  canDelete?: boolean;
}

export interface IRow {
  [key: string]: [value: any];
}

export interface AuditLog {
  title: string;
  entity: string;
  info: string;
  level: number;
  status?: string;
  linked?: string;
}

export interface AuditLogNode {
  nodeid: string;
  title: string;
  entity: string;
  info: string;
  level: number;
  status?: string;
  linked?: string;
  selected: boolean;
  parent?: AuditLogNode | null;
  children?: AuditLogNode[];
  parentid?: string | null;
}

export interface Backups {
  customeraddresses: CustomerAddress[];
  customers: Customer[];
  clients: Client[];
  departments: Department[];
  projects: Project[];
  tasks: Task[];
  timetracks: TimeTrack[];
  typesofwork: TypeOfWork[];
  userrates: UserRate[];
  users: User[];
  workflowstatuses: WorkflowStatus[];
  auditlogs?: InvoiceLog[];
  auditlognodes?: AuditNode[];
  invoices?: any[];
  docdefs?: any[];
}

export interface DocumentDefinition {
  content: {
    text: string;
    style: string;
  }[];
  styles: {
    [key: string]: {
      fontSize: number;
      margin: number[];
      bold: boolean;
    };
  };
}

export interface Client {
  address?: CustomerAddress;
  customers?: Customer[];
  clientid: string;
  name: string;
  id: number;
}

export interface NamedInvoice {
  exported?: boolean;
  approved?: boolean;
  previewed?: boolean;
  customername: string;
  projectname?: string;
  customerid?: number;
  projectid?: any;
  name: string;
  ix: number;
}

export interface CustomerAddress {
  customerid: number;
  streetaddress: string;
  province: string;
  country: string;
  postal: string;
  city: string;
}

export interface Customer {
  id: number;
  url: string;
  name: string;
  created: number;
  archived: boolean;
  description: string;
  allowedActions: RecordOps;
  address?: CustomerAddress;
}

export interface Department {
  id: number;
  name: string;
  default: boolean;
}

export interface Project {
  id: number;
  url: string;
  name: string;
  archived: boolean;
  created: number;
  customerId: number;
  customerName: string;
  description: string;
  allowedActions: RecordOps;
}

export interface InvoiceProject {
  id: number;
  name: string;
  customerId: number;
  customerName: string;
  allowedActions: RecordOps;
  project: {
    id: number;
    name: string;
    archived: boolean;
    created: number;
    url: string;
    description: string;
  };
  tasks: InvoiceTask[];
}

export interface InvoiceTask {
  id: number;
  name: string;
  customerId: number;
  customerName: string;
  allowedActions: RecordOps;
  task: {
    id: number;
    url: string;
    name: string;
    created: number;
    archived: boolean;
    projectId: number;
    projectName: string;
    description: string;
  }
  status: string;
  deadline: string;
  estimatedTime: number;
  typeOfWorkId: number;
  typeOfWork: TypeOfWork;
  typeOfWorkName: string;
  workflowStatusId: number;
  workflowStatusName: string;
  subtasks: InvoiceSubtask[];
}

export interface InvoiceSubtask {
  id: number;
  url: string;
  name: string;
  archived: boolean;
  created: number;
  customerId: number;
  customerName: string;
  deadline: string;
  description: string;
  estimatedTime: number;
  projectId: number;
  projectName: string;
  status: string;
  typeOfWorkId: number;
  typeOfWorkName: string;
  workflowStatusId: number;
  workflowStatusName: string;
  allowedActions: RecordOps;
  timetracks: UserRatedTimetrack[];
  typeOfWork: TypeOfWork;
}

export interface WorkOnSubtask {
  name: string;
  hours: string;
  rate: number;
}

export interface InvoiceAsset {
  label: string;
  name: string;
  data?: string;
}

export interface InvoiceWorkType {
  task: {
    id: number;
    name: string;
    description: string;
    projectTaskId: number;
    projectTaskName: string;
    subtaskwork: WorkOnSubtask[];
    typeOfWork: TypeOfWork;
  };
}

export interface UserTrackedTime {
  id: number;
  name: string;
  dayOffset: number;
  userId: number;
  date: string;
  user: User;
  userRate: UserRate;
  records: TimeTrackRecord[];
}

export interface UserTimeTracked {
  user: User;
  task: string;
  subtask: string;
  project: string;
  typeofwork: string;
  id: number;
  name: string;
  date: any;
  total: string;
  records: string[];
}

export interface UserWorkedTimetrackRecords {
  userId: number;
  userName: string;
  trackedtime: UserTimeTracked[];
  timetracked: UserTrackedTime[];
}

export interface UserWork {
  userworkedtime: UserWorkedTimetrackRecords[];
}

export interface TaskSubtaskUserWork {
  id: number;
  name: string;
  taskId: number;
  taskName: string;
  customerId: number;
  customerName: string;
  typeOfWork: TypeOfWork;
  timetracked: UserTrackedTime[];
}

export interface Task {
  id: number;
  url: string;
  name: string;
  archived: boolean;
  created: number;
  customerId: number;
  customerName: string;
  deadline: string;
  description: string;
  estimatedTime: number;
  projectId: number;
  projectName: string;
  status: string;
  typeOfWorkId: number;
  typeOfWorkName: string;
  workflowStatusId: number;
  workflowStatusName: string;
  allowedActions: RecordOps;
}

export interface InvoiceRecipients {
  customers: CustomerInvoiceBlob[] | any[];
}

export interface CustomerInvoiceBlob {
  id: number;
  url: string;
  name: string;
  created: number;
  archived: boolean;
  description: string;
  allowedActions: RecordOps;
  project?: InvoiceProject;
  projects?: ProjectWithTasks[];
  address?: CustomerAddress;
  client?: Client;
}

export interface ProjectWithTasks {
  id: number;
  url: string;
  name: string;
  archived: boolean;
  created: number;
  customerId: number;
  customerName: string;
  description: string;
  allowedActions: RecordOps;
  tasks: WorkTypedTimetrackedTask[];
  address?: CustomerAddress;
}

export interface WorkTypedTimetrackedTask {
  id: number;
  url: string;
  name: string;
  archived: boolean;
  created: number;
  customerId: number;
  customerName: string;
  deadline: string;
  description: string;
  estimatedTime: number;
  projectId: number;
  projectName: string;
  status: string;
  typeOfWorkId: number;
  typeOfWorkName: string;
  workflowStatusId: number;
  workflowStatusName: string;
  allowedActions: RecordOps;
  timetracks: UserRatedTimetrack[];
  typeOfWork: TypeOfWork;
}

export interface QueryMessage {
  source?: MessageSource;
  progress?: number;
  type: MessageType;
  message: string;
}

export interface ExportComments {
  pdfid: number;
  comments: string;
}

export interface UserRatedTimetrack {
  dayOffset: number;
  userId: number;
  date: string;
  user: User;
  userRate: UserRate;
  records: TimeTrackRecord[];
}

export interface GridCol {
  entity: string;
  hidden: boolean;
  field: string;
}

export interface DateRange {
  from: Date | string;
  til: Date | string;
}

export interface TimetrackPayload {
  dateFrom: string;
  dateTo: string;
  nextDateFrom: string;
  data: TimeTrack[];
}

export interface TimeTrackRecord {
  taskId: number;
  time: number;
}

export interface TimeTrack {
  userId: number;
  records: TimeTrackRecord[];
  dayOffset: number;
  date: string;
}

export interface TypeOfWork {
  id: number;
  name: string;
  rate: number;
  default: boolean;
  archived: boolean;
  billable: boolean;
}

export interface LeaveType {
  id: number;
  name: string;
  balance: string;
  archived: boolean;
}

export interface LeaveRate {
  leaveTypeId: number;
  rate: number;
}

export interface Rate {
  dateFrom: string;
  leaveRates: LeaveRate[];
  overtimeRate: number | null;
  regularRate: number;
}

export interface UserRate {
  userId: number;
  rates: Rate[];
}

export interface User {
  id: number;
  name: string;
  allowedActions: RecordOps;
  active: boolean;
  email: string;
  hired: string;
  departmentId: number;
  firstName: string;
  lastName: string;
  middleName: string;
  fullName: string;
  timeZoneGroupId: number;
  username: string;
}

export interface WorkflowStatus {
  id: number;
  name: string;
  type: string;
  allowedActions: RecordOps;
}

export interface IStorage {
  value: any;
  expiresin: number;
  timestamp: number;
}

export enum CardView {
  Grid = 1,
  Summary = 2,
  Metrics = 3,
}

export interface NcalCard {
  loading: boolean;
  view: CardView;
  title: string;
  cols: number;
  rows: number;
  key: string;
  data: any[],
}

export interface SyncParam {
  complete: boolean;
  syncing: boolean;
  progress: number;
  label: string;
  value: string;
  key: string;
  error: any;
  id: number;
}
