import {
  Component,
  Input,
  Output,
  OnInit,
  OnDestroy,
  ViewChild,
  Renderer2,
  ElementRef,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';
import { takeWhile } from 'rxjs';
import { notificationEffect } from '@app/app.animations';
import { AlertService } from './alert.service';
import { NotificationType } from '@data/enums';
import { INotification } from '@data/models';

@Component({
  selector: 'ncal-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [notificationEffect],
})
export class AlertComponent implements OnInit, OnDestroy {
  @ViewChild('ntfy', {static: false}) ntfy: ElementRef<HTMLDivElement>;
  private classMap: Map<NotificationType, string>;
  private _subscribed: boolean = true;
  notebox: HTMLDivElement;
  note: INotification;

  constructor(
    private notifier: AlertService,
    private renderer: Renderer2,
  ) { }

  ngOnInit(): void {
    this.classMap = new Map<NotificationType, string>();
    this.classMap.set(NotificationType.Primary, 'primary');
    this.classMap.set(NotificationType.Secondary, 'secondary');
    this.classMap.set(NotificationType.Success, 'success');
    this.classMap.set(NotificationType.Info, 'info');
    this.classMap.set(NotificationType.Warning, 'warning');
    this.classMap.set(NotificationType.Danger, 'danger');
    this.notifier.notification
      .pipe(
        takeWhile(() => this._subscribed)
      )
      .subscribe((notification?: INotification) => {
        if (notification) this.render(notification);
      });
  }

  ngOnDestroy(): void {
    this._subscribed = false;
  }

  dismiss(): void {
    setTimeout(() => {
      this.renderer.setStyle(this.notebox, 'opacity', '0');
      setTimeout(() => {
        this.renderer.removeChild(this.ntfy.nativeElement, this.notebox);
      }, 200);
    }, 200);
  }

  fade(): void {
    setTimeout(() => {
      this.renderer.setStyle(this.notebox, 'opacity', '0');
      setTimeout(() => {
        this.renderer.removeChild(this.ntfy.nativeElement, this.notebox);
      }, this.note.duration/2);
    }, this.note.duration/2);
  }

  private render(notification: INotification): void {
    let ntfybox = this.renderer.createElement('div');
    let header = this.renderer.createElement('b');
    let content = this.renderer.createElement('div');

    const boxcolorclass = this.classMap.get(notification.type);
    let extraclasses = ['message-box', boxcolorclass];
    extraclasses.forEach(c => this.renderer.addClass(ntfybox, c));

    this.renderer.setStyle(ntfybox, 'transition', `opacity ${notification.duration}ms`);
    this.renderer.setStyle(ntfybox, 'opacity', '1');

    const text = this.renderer.createText(notification.message);
    this.renderer.appendChild(content, text);

    if (this.ntfy) {
      this.renderer.appendChild(this.ntfy.nativeElement, ntfybox);
      // this.renderer.appendChild(ntfybox, header);
      this.renderer.appendChild(ntfybox, content);
      this.note = notification;
      this.notebox = ntfybox;
      this.fade();
    }
  }
}
