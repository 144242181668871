interface SuffixedNumberStringOptions {
  insertSpace: boolean;
  suffixes: string[];
  defaultSuffix: string | null;
  divisor: number;
}

declare global {
  interface Number {
    toFileSize(fractionDigits: number): string;
    roundTo(fractionDigits: number): number;
    roundTo(fractionDigits: number, roundStrategy: 'normal' | 'down' | 'up'): number;
    toNumberString(fractionDigits: number): string;
    toNumberString(minimumFractionDigits: number, maximumFractionDigits: number): string;
    toSuffixedNumberString(fractionDigits: number, options?: SuffixedNumberStringOptions): string;
    toWords(): string;
  }
}

function extend() {
  Object.defineProperty(Number.prototype, 'roundTo', {
    value: function (fractionDigits: number, roundStrategy: 'normal' | 'down' | 'up' = 'normal') {
      const power = Math.pow(10, fractionDigits);
      let value = Number(this) * power;
      switch (roundStrategy) {
        case 'up':
          value = Math.ceil(value);
          break;
        case 'down':
          value = Math.floor(value);
      }
      value /= power;
      return Number(value.toFixed(fractionDigits));
    },
    enumerable: false,
  });

  Object.defineProperty(Number.prototype, 'toNumberString', {
    value: function (minimumFractionDigits: number, maximumFractionDigits: number) {
      const minDigits = minimumFractionDigits || 0;
      const maxDigits = maximumFractionDigits || minDigits;
      return this.toLocaleString('en-us', { minimumFractionDigits: minDigits, maximumFractionDigits: maxDigits });
    },
    enumerable: false,
  });

  Object.defineProperty(Number.prototype, 'toFileSize', {
    value: function (fractionDigits: number) {
      const value = Number(this);
      const options: SuffixedNumberStringOptions = {
        defaultSuffix: 'bytes',
        insertSpace: true,
        suffixes: ['kB', 'MB', 'GB', 'TB'],
        divisor: 1024,
      };
      return value.toSuffixedNumberString(fractionDigits, options);
    },
  });

  Object.defineProperty(Number.prototype, 'toSuffixedNumberString', {
    value: function (fractionDigits: number, pipeOptions?: Partial<SuffixedNumberStringOptions>) {
      const options: SuffixedNumberStringOptions = {
        insertSpace: false,
        divisor: 1000,
        suffixes: ['k', 'M', 'G'],
        defaultSuffix: null,
        ...pipeOptions,
      };

      let value = Number(this);
      const sign = Math.sign(value);
      value = Math.abs(value);

      if (value <= options.divisor || options.suffixes.length === 0) {
        let result = (sign * value).toNumberString(0);
        if (options.defaultSuffix) {
          if (options.insertSpace) result += ' ';
          result += options.defaultSuffix;
        }
        return result;
      }
      let index = -1;
      while (value > options.divisor && index < options.suffixes.length) {
        value /= options.divisor;
        index++;
      }
      value *= sign;
      let result = (sign * value).toNumberString(fractionDigits);
      if (options.insertSpace) result += ' ';
      result += options.suffixes[index];
      return result;
    },
  });

  Object.defineProperty(Number.prototype, 'toWords', {
    value: function () {
      const number = Number(this);
      switch (number) {
        case 0:
          return 'zero';
        case 1:
          return 'one';
        case 2:
          return 'two';
        case 3:
          return 'three';
        case 4:
          return 'four';
        case 5:
          return 'five';
        case 6:
          return 'six';
        case 7:
          return 'seven';
        case 8:
          return 'eight';
        case 9:
          return 'nine';
        default:
          return number.toString();
      }
    },
    enumerable: false,
  });
}
extend();
export { extend as numberExtensions };
