<mat-toolbar
  class="mat-elevation-z6 navbar"
  class="ncal-toolbar"
  [ngClass]="{
    'mobile': responsive.deviceMobile(),
    'web': responsive.deviceDesktop(),
    'tablet': responsive.deviceTablet()
  }">
  <div
    class="branding"
    [ngClass]="{
      'mobile': responsive.deviceMobile(),
      'web': responsive.deviceDesktop(),
      'tablet': responsive.deviceTablet()
    }">
    <button
      mat-icon-button
      class="menu-button"
      (click)="sidenav.toggle()">
      <mat-icon>menu</mat-icon>
    </button>
    <a class="brand" href="https://github.com/mllowenstein" translate>NCALWeb</a>
  </div>
  <button
    size="large"
    matTooltipHideDelay="60000"
    class="override-invoice-period-btn"
    badge="{{currentInvoicePeriod*(-1)}}"
    matTooltipClass="invoice-period-tooltip"
    [matMenuTriggerFor]="invoicePeriodsOverrideMenu"
    matTooltip="This shows the current invoice periods for the current fiscal year - click and select a prior period to invoice retroactively...">
    Invoice Period
  </button>
  <mat-menu
    class="invoice-override-menu-panel"
    #invoicePeriodsOverrideMenu="matMenu">
    <mat-list class="override-periods-available-list">
      <mat-list-item
        class="invoice-period-override-item"
        (click)="updateInvoicePeriod(period)"
        *ngFor="let period of overridePeriods"
        [ngClass]="{'selected-period': period.value === invoicingfor}">
        <span class="invoice-period-override-label" translate>
          {{ period.label }}
        </span>
        <span class="invoice-period-indicator" *ngIf="period.value === invoicingfor">
          <mat-icon class="invoice-period-icon">check_circle</mat-icon>
        </span>
      </mat-list-item>
    </mat-list>
  </mat-menu>
  <div
    class="toolbar-links"
    [ngClass]="{
      'mobile': responsive.deviceMobile(),
      'web': responsive.deviceDesktop(),
      'tablet': responsive.deviceTablet()
    }">
    <ng-container *ngFor="let link of links">
      <button *ngIf="link.label !== 'Export'"
        class="page-navigation-link"
        routerLink="{{ link.route }}"
        routerLinkActive="active"
        mat-button
        >
        <mat-icon class="link-icon">{{ link.icon }}</mat-icon>
        <span translate>{{ link.label }}</span>
      </button>
      <button *ngIf="link.label === 'Export'"
        (click)="manualNavigation()"
        class="page-navigation-link"
        mat-button
        >
        <mat-icon class="link-icon">{{ link.icon }}</mat-icon>
        <span translate>{{ link.label }}</span>
      </button>
    </ng-container>
  </div>
  <span
    class="spacer"
    [ngClass]="{
      'mobile': responsive.deviceMobile(),
      'web': responsive.deviceDesktop(),
      'tablet': responsive.deviceTablet()
    }"></span>
  <div
    class="toolbar-controls"
    [ngClass]="{
      'mobile': responsive.deviceMobile(),
      'web': responsive.deviceDesktop(),
      'tablet': responsive.deviceTablet()
    }">
    <button mat-icon-button [matMenuTriggerFor]="userMenu">
      <mat-icon>person</mat-icon>
    </button>
    <button
      *ngIf="locally"
      mat-icon-button
      [matMenuTriggerFor]="entityMenu"
      matTooltip="This will fetch the server backup of specific entity datasets..."
      >
      <mat-icon class="dash-icon">storage</mat-icon>
    </button>
    <mat-menu #entityMenu="matMenu" class="entity-store-menu-panel">
      <mat-list class="doc-entity-list">
        <mat-list-item
          class="doc-entity-list-item"
          (click)="fetchDocFromStore(docentity)"
          *ngFor="let docentity of documentEntities">
          <span class="doc-entity-label" translate>
            {{ docentity.name }}
          </span>
        </mat-list-item>
      </mat-list>
    </mat-menu>
  </div>
  <mat-menu #userMenu="matMenu" class="user-status-menu-panel">
    <mat-list>
      <mat-list-item>
        <span translate>Logged in as</span>&nbsp;<b>{{ currentuser.name }}</b>
      </mat-list-item>
      <mat-divider></mat-divider>
    </mat-list>
    <button mat-menu-item (click)="logout()" translate>Logout</button>
  </mat-menu>
  <button
    *ngIf="locally"
    (click)="checkAuthStatus()"
    class="global-app-settings-btn"
    matTooltip="Look at the details and properties that constitute your authenticated state...">
    Check Auth
  </button>
  <button
    (click)="dismiss()"
    *ngIf="cacheRestored"
    class="global-app-settings-btn"
    matTooltip="{{announcement}}">
    Success
  </button>
</mat-toolbar>
<ncal-alert></ncal-alert>
