<div>
  <ncal-header [sidenav]="sidenav"></ncal-header>
  <mat-sidenav-container>
    <mat-sidenav class="ncal-sidenav" #sidenav mode="side">
      <nav>
        <mat-list class="sidenav-list">
          <span class="sidenav-logo">
            <img class="ncal-branding" src="assets/brand/ncal.webp" alt="NCAL" />
          </span>
          <mat-divider></mat-divider>
          <span class="popup-zone">
            <mat-list-item
              class="nav-link-item"
              *ngFor="let link of links">
              <span (pointerover)="showLink(link)">
                <a class="link-item" routerLink="{{ link.route }}" routerLinkActive="active" (click)="closeNav()">
                  <mat-icon class="list-item-icon">{{ link.icon }}</mat-icon>
                  <span class="list-item-label" translate>{{ link.label }}</span>
                </a>
                <mat-divider class="nav-link-divider"></mat-divider>
              </span>
            </mat-list-item>
          </span>
        </mat-list>
      </nav>
    </mat-sidenav>
    <mat-sidenav-content class="page-contents">
      <ncal-dropdown
        class="invoice-override"
        [config]="dropdownConfig"
        (selectionChanged)="updateUuid($event)">
      </ncal-dropdown>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
