import { Component, inject, Input, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Route, Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { map, forkJoin, Subject, takeUntil } from 'rxjs';
import {
  NAVLINKS,
  FISCALMONTHS,
  FISCALDATET0,
  DOCUMENTENTITIES,
  DefaultGlobalSettings,
} from '@data/constants';
import {
  NavLink,
  DocStore,
  PickerOption,
  GlobalSetting,
} from '@data/models';
import { NavigationService } from '@services/navigation.service';
import { ResponsiveService } from '@services/responsive.service';
import { FirebaseService } from '@services/firebase.service';
import { ShortcutService } from '@services/shortcut.service';
import { StorageService } from '@services/storage.service';
import { differenceInCalendarMonths } from 'date-fns';
import { environment } from '@env/environment';
import { AuthService } from '@auth/services';
import { CacheService } from '@cache/index';
import { WhoAmI } from '@auth/auth.model';
import { StorageKeys } from '@data/enums';
import { Logger } from '@logger';

const log = new Logger('HeaderComponent');

@Component({
  selector: 'ncal-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  startOfCurrentFiscalYear: string = new Date(Date.now()).getFullYear() + `-${FISCALDATET0}`;
  protected links: NavLink[] = NAVLINKS.filter((link: NavLink) => { return link.visible });
  globalSettingsOptions: GlobalSetting[] = DefaultGlobalSettings;
  protected currentRange?: 'Small' | 'Medium' | 'Large';
  documentEntities: DocStore[] = DOCUMENTENTITIES;
  protected isVisible: boolean = false;
  #navigate = inject(NavigationService);
  overridePeriods: PickerOption[] = [];
  #keydown = inject(ShortcutService);
  @Input() sidenav!: MatSidenav;
  currentInvoicePeriod: number;
  defaultTrue: boolean = true;
  #destroy$ = new Subject();
  customerData: any[];
  departmentData: any[];
  projectData: any[];
  taskData: any[];
  timetrackData: any[];
  typesOfWorkData: any[];
  userRatesData: any[];
  userData: any[];
  workflowStatusData: any[];
  queryargs: string[] = [];
  routes: Route[] = [];
  cacheRestored: boolean = false;
  locally: boolean = false;
  announcement: string;
  invoicingfor: number;
  currentuser: WhoAmI;

  constructor(
    private router: Router,
    private auth: AuthService,
    public cache: CacheService,
    private titleService: Title,
    private storage: StorageService,
    private firebase: FirebaseService,
    public responsive: ResponsiveService,
  ) {
  }

  ngOnInit(): void {
    this.checkEnv();
    this.firebase.introspect();
    const timeSinceFiscalYearStarted = this.checkCurrentInvoicePeriod();
    const fiscalMonth = (timeSinceFiscalYearStarted+12);
    this.setFiscalPeriod(fiscalMonth);
    this.overridePeriods = this.setAvailableOverrideOptions(fiscalMonth);
    this.routes = this.getRoutes();
    this.#keydown.keyevents$
      .pipe(takeUntil(this.#destroy$))
      .subscribe((key) => {
        this.navigateByKey(key);
      });
  }

  checkEnv(): void {
    this.locally = !environment.production;
    this.currentuser = JSON.parse(localStorage.getItem(StorageKeys.WhoAmI));
  }

  setFiscalPeriod(current: number): void {
    const fiscalmonth = FISCALMONTHS[current-1];
    const invoicemonth = FISCALMONTHS[current-2];
    this.storage.stash(StorageKeys.CurrentFiscalMonth, JSON.stringify(fiscalmonth));
    this.storage.stash(StorageKeys.InvoiceFiscalMonth, JSON.stringify(invoicemonth));
    this.invoicingfor = invoicemonth.value;
  }

  updateActiveFiscalMonth(option: PickerOption): void {
    this.storage.stash(StorageKeys.InvoiceFiscalMonth, JSON.stringify(option));
    this.invoicingfor = option.value;
  }

  private setAvailableOverrideOptions(stopat: number): PickerOption[] {
    let overrides: PickerOption[] = [];
    FISCALMONTHS.forEach((po: PickerOption) => {
      if (overrides.length < stopat) {
        overrides.push(po);
      }
    });
    return overrides;
  }

  private checkCurrentInvoicePeriod(): number {
    const today = new Date(Date.now());
    const monthNow = differenceInCalendarMonths(
      `${today.getFullYear()}-${String(today.getMonth()+1).padStart(2, '0')}- ${String(today.getDate()).padStart(2, '0')}`,
      `${this.startOfCurrentFiscalYear}`,
    );
    return monthNow;
  }

  updateInvoicePeriod(event: any): void {
    console.log(event);
    this.updateActiveFiscalMonth(event);
  }

  private getRoutes(): Route[] {
    return this.#navigate.getNavigationRoutes();
  }

  private navigateByKey(key: string): void {
    const index = parseInt(key, 10) - 1;
    if (index >= 0 && index < this.routes.length) {
      this.#navigate.navigateTo(this.routes[index].path as string);
    }
  }

  async logout(): Promise<void> {
    console.log('signing out...');
    this.storage.clean(StorageKeys.Me);
    this.storage.clean(StorageKeys.WhoAmI);
    this.storage.clean(StorageKeys.MyTokens);
    this.auth.logout()
      .then(() => {
        this.router.navigate(['/auth/login']);
      })
      .catch(error => console.error('Logout error', error));
  }

  manualNavigation(): void {
    console.log('manually redirecting to syncing progress screen...');
    this.router.navigate(['/syncing']);
  }

  checkAuthStatus(): void {
    this.auth.inspectUserMetadata();
  }

  get username(): string | null {
    const credentials = this.storage.grab(StorageKeys.Credentials);
    return credentials && credentials.hasOwnProperty('username') ? credentials.username : null;
  }

  get email(): string | null {
    const credentials = this.storage.grab(StorageKeys.Credentials);
    return credentials && credentials.hasOwnProperty('email') ? credentials.email : null;
  }

  get title(): string {
    return this.titleService.getTitle();
  }

  checkCache(): void {
    console.log(this.cache);
  }

  fetchDocFromStore(doc: DocStore): void {
    console.log(doc);
    this.firebase.getDataSet(doc.path).subscribe({
      next: (response) => this.cacheDocument(doc, response),
      error: (error) => console.log('Failed to load document...', error),
    });
  }

  cacheDocument(doc: DocStore, set: any[]): void {
    doc.model = set;
    console.log(doc);
    console.log(set);
    this.notify(`${doc.name} dataset (${set.length} records) retrieved from server cache...`);
    this.cache.broadcast();
  }

  notify(message: string): void {
    this.announcement = message;
    this.cacheRestored = true;
    setTimeout(() => {
      this.announcement = null;
      this.cacheRestored = false;
    }, 3000);
  }

  dismiss(): void {
    this.announcement = null;
    this.cacheRestored = false;
  }

  openGlobalSettingsModal(event: any): void {
    console.log(event);
  }

  ngOnDestroy(): void {
    this.#destroy$.next(true);
    this.#destroy$.complete();
  }
}
