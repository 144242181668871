import { Injectable, inject } from '@angular/core';
import { IStorage, Project } from '@data/models';
import { environment } from '@env/environment';
import {  STRINGKEYS } from '@data/constants';
import { ApiService } from './api.service';
import { StorageKeys } from '@data/enums';
import { Logger } from '@logger';
import CryptoJS from 'crypto-js';

const log = new Logger('StorageLogger');

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  stateAPI: string = environment.state_api_base_url;
  stateAPIget: string = `${this.stateAPI}/retrieve`;
  stateAPIcheck: string = `${this.stateAPI}/check`;
  stateAPIpost: string = `${this.stateAPI}/store`;
  private api$: ApiService = inject(ApiService);
  key = environment.version;
  state: any;

  private get shelf(): Storage {
    return localStorage;
  }

  private get storage(): Storage {
    return sessionStorage;
  }

  private encrypt(txt: string): string {
    const enctxt = CryptoJS.AES.encrypt(txt, this.key).toString();
    log.debug('encryption of ' + txt + ': ' + enctxt);
    return enctxt;
  }

  private decrypt(encrypted: string): string {
    const txt = CryptoJS.AES.decrypt(encrypted, this.key).toString(CryptoJS.enc.Utf8);
    log.debug('decryption of ' + encrypted + ': ' + txt);
    return txt;
  }

  secure_shelve(key: string, value: any): void {
    log.info('adding ' + key + ' to shelf...');
    log.info('contents are in ' + typeof value + ' form');
    const shelved = typeof value === 'string'
      ? this.encrypt(value)
      : this.encrypt(JSON.stringify(value));
    this.shelf.setItem(key, shelved);
  }

  secure_grab(key: string): any {
    if (this.shelf.getItem(key)) {
      let data = this.shelf.getItem(key) || '';
      return JSON.parse(this.decrypt(data));
    }
    return null;
  }

  async shelve(key: string, value: any): Promise<void> {
    log.info('adding ' + key + ' to shelf...');
    log.info('contents are in ' + typeof value + ' form');
    const shelved = typeof value === 'string'
      ? value : JSON.stringify(value);
    const payload = {
      key: key,
      value: shelved,
    };
    try {
      return await this.api$.post(this.stateAPIpost, payload);
    } catch (err) {
      alert(err.message);
      return;
    }
  }

  async restore(key: string): Promise<any> {
    let data = this.shelf.getItem(key) || '';
    try {
      const apidata = await this.api$.get(`${this.stateAPIget}/${key}`);
      log.debug(apidata);
      return JSON.parse(apidata ?? data);
    } catch (err) {
      log.warn('No entries found for ' + key + ' on insyncserver...');
      return [];
    }
  }

  stash(key: string, value: any): void {
    log.info('adding ' + key + ' to shelf...');
    log.info('contents are in ' + typeof value + ' form');
    const shelved = typeof value === 'string'
      ? value : JSON.stringify(value);
    this.shelf.setItem(key, shelved);
  }

  grab(key: string): any {
    if (this.shelf.getItem(key)) {
      let data = this.shelf.getItem(key) || '';
      return STRINGKEYS.includes(key) ? data : JSON.parse(data);
    }
    return null;
  }

  clean(key: string): void {
    this.shelf.removeItem(key);
  }

  wipe(): void {
    this.shelf.clear();
  }

  store(key: string, value: any): void {
    this.storage.setItem(key, JSON.stringify(value));
  }

  retrieve(key: string): any {
    return JSON.parse(this.storage.getItem(key));
  }

  remove(key: string): void {
    this.storage.removeItem(key);
  }

  sanitize(): void {
    this.storage.clear();
  }

  setFormat(fmt: string): void {
    localStorage.setItem('format', fmt);
  }

  getFormat(): string {
    if (localStorage.getItem('format')) {
      return localStorage.getItem('format');
    }
    return '';
  }

  setDatatype(datatype: string): void {
    localStorage.setItem('datatype', datatype);
  }

  getDatatype(): string {
    if (localStorage.getItem('datatype')) {
      return localStorage.getItem('datatype');
    }
    return '';
  }

  setLastUrl(urlwithparams: string): void {
    localStorage.setItem('lastUrlWithParams', urlwithparams);
  }

  getLastUrl(): string {
    if (localStorage.getItem('lastUrlWithParams')) {
      return localStorage.getItem('lastUrlWithParams');
    }
    return '';
  }

  setDataset(dataset: any[]): void {
    sessionStorage.setItem('lastRequest', JSON.stringify(dataset));
  }

  getLastDataset(): any[] {
    if (sessionStorage.getItem('lastRequest')) {
      return JSON.parse(sessionStorage.getItem('lastRequest'));
    }
    return [];
  }

  setProjects(projects: Project[]): void {
    localStorage.setItem('projects', JSON.stringify(projects));
  }

  getProjects(): any[] {
    if (localStorage.getItem('projects')) {
      return JSON.parse(localStorage.getItem('projects'));
    }
    return [];
  }

  wipeInvoicingCustomer(): void {
    localStorage.removeItem('invoicingCustomer');
  }

  setInvoicingCustomer(ip: any): void {
    localStorage.setItem('invoicingCustomer', JSON.stringify(ip));
  }

  getInvoicingCustomer(): any {
    if (localStorage.getItem('invoicingCustomer')) {
      return JSON.parse(localStorage.getItem('invoicingProject'));
    }
    return null;
  }

  wipeInvoicingProject(): void {
    localStorage.removeItem('invoicingProject');
  }

  setInvoicingProject(ip: any): void {
    localStorage.setItem('invoicingProject', JSON.stringify(ip));
  }

  getInvoicingProject(): any {
    if (localStorage.getItem('invoicingProject')) {
      return JSON.parse(localStorage.getItem('invoicingProject'));
    }
    return null;
  }

  wipeInvoicingMonth(): void {
    localStorage.removeItem('invoicingMonth');
  }

  setInvoicingMonth(im: any): void {
    localStorage.setItem('invoicingMonth', JSON.stringify(im));
  }

  getInvoicingMonth(): any {
    if (localStorage.getItem('invoicingMonth')) {
      return JSON.parse(localStorage.getItem('invoicingMonth'));
    }
    return null;
  }

  wipeInvoicingYear(): void {
    localStorage.removeItem('invoicingYear');
  }

  setInvoicingYear(iy: any): void {
    localStorage.setItem('invoicingYear', JSON.stringify(iy));
  }

  getInvoicingYear(): any {
    if (localStorage.getItem('invoicingYear')) {
      return JSON.parse(localStorage.getItem('invoicingYear'));
    }
    return null;
  }
}
