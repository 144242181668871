<!-- <div
  class="cssload-container"
  *ngIf="this.loader.getLoading()">

</div> -->
<div *ngIf="isLoading" >
  <div class="overlay global"></div>
  <div class="spinner global">
    <div>
      <img class="ncal-branding" src="assets/brand/ncal.webp" alt="NCAL" /> 
      <div class="cssload-speeding-wheel"></div>
    </div>
  </div>
</div>
