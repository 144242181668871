import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { MatTreeFlattener, MatTreeFlatDataSource } from '@angular/material/tree';
import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { StorageService } from '@services/storage.service';
import { AuditLogNode, AuditLog } from '@data/models';
import { AuditNode, InvoiceLog } from '@data/classes';
import { TreeService } from './tree.service';
import { TreeNode } from '@data/classes';
import { Logger } from '@logger';

const log = new Logger('TreeComponent');

@Component({
  selector: 'ncal-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.css'],
})
export class TreeComponent implements OnInit {
  dataSource: MatTreeFlatDataSource<TreeNode, TreeNode>;
  treeFlattener: MatTreeFlattener<TreeNode, TreeNode>;
  selected = new SelectionModel<TreeNode>(true);
  treeControl: FlatTreeControl<TreeNode>;
  levels = new Map<TreeNode, number>();
  @Input() auditlognodes!: AuditNode[];
  @Input() auditlogs!: InvoiceLog[];
  @Input() nodes: TreeNode[];
  dataset: any[];

  constructor(
    private changeRef: ChangeDetectorRef,
    private storage: StorageService,
    private service: TreeService,
  ) {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<TreeNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  }

  ngOnInit(): void {
    console.log('init tree component');
    console.log(this.nodes);
    this.dataSource.data = this.nodes;
  }

  /** Event-handler for select/deselect all nodes in tree */
  toggleTree(all: boolean): void {
    if (all) {
      this.treeControl.dataNodes.forEach((node) => {
        if (!this.selected.isSelected(node)) {
          this.toggleNode(node);
        }
      });
    } else {
      this.treeControl.dataNodes.forEach((node) => {
        if (this.selected.isSelected(node)) {
          this.toggleNode(node);
        }
      });
    }
  }

  /** Expand the entire tree if the number of nodes
   * if our tree contains a single parent node. */
  expandAll(): void {
    this.treeControl.expandAll();
  }

  /** Collapse the entire tree (default) if there
   * are multiple parent nodes being rendered. */
  collapseAll(): void {
    this.treeControl.collapseAll();
  }

  /** Getter for depth of a node in the tree. */
  getLevel = (node: TreeNode): number => this.levels.get(node) || 0;

  /** Boolean checker for whether
   * a tree node has children */
  isExpandable = (node: TreeNode): boolean => node.children.length > 0;

  /** Getter for children of
   * a particular tree node */
  getChildren = (node: TreeNode) => node.children;

  /** During tree construction, this function
   * turns array of data into proper tree format */
  transformer = (node: TreeNode, level: number) => { this.levels.set(node, level); return node; };

  /** Property indicator for a
   * non-leaf node in the tree. */
  hasChildren = (index: number, node: TreeNode) => this.isExpandable(node);

  /** UI-handler for when user has
   * selected all of a parent's children */
  allChildrenSelected(node: TreeNode): boolean {
    const descents = this.treeControl.getDescendants(node);
    return descents.every(child => this.selected.isSelected(child));
  }

  /** UI-handler for when some,
   * but not all, of a parent's
   * children have been selected. */
  someChildrenSelected(node: TreeNode): boolean {
    const descents = this.treeControl.getDescendants(node);
    const result = descents.some(child => this.selected.isSelected(child));
    return result && !this.allChildrenSelected(node);
  }

  /** Event-handler for user
   * selecting/deselecting a tree node. */
  toggleNode(node: TreeNode): void {
    this.selected.toggle(node);
    const descents = this.treeControl.getDescendants(node);
    this.selected.isSelected(node)
      ? this.selected.select(...descents, node)
      : this.selected.deselect(...descents, node);
    this.changeRef.markForCheck();
  }
}
