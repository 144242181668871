import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { StorageService } from '@services/storage.service';
import { WhoAmI, MyTokens, Me } from '@auth/auth.model';
import { environment } from '@env/environment';
import firebase from 'firebase/compat/app';
import { StorageKeys } from '@data/enums';
import { map } from 'rxjs/operators';
import { Logger } from '@logger';

const log = new Logger('AuthService');

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private storage: StorageService,
    public auth: AngularFireAuth,
    private router: Router,
  ) {}

  get idToken() { return this.auth.idToken; }
  get authState() { return this.auth.authState; }
  get currentUser() { return this.auth.currentUser; }
  get idTokenResult() { return this.auth.idTokenResult; }

  async register(email: string, password: string): Promise<any> {
    return this.auth.createUserWithEmailAndPassword(email, password)
      .then((usercred: firebase.auth.UserCredential) => {
        log.info('user account creation in progress...');
        log.info('sending verification email...');
        usercred.user?.sendEmailVerification();
      })
      .catch((err: any) => {
        log.error('Error Occurred During Registration', err);
      }
    );
  }

  async resetPassword(email: string): Promise<any> {
    return this.auth.sendPasswordResetEmail(email);
  }

  async basicLogin(email: string, password: string): Promise<firebase.auth.UserCredential> {
    log.info('attempting standard login via email and password...');
    return this.auth.signInWithEmailAndPassword(email, password);
  }

  async googleLogin(): Promise<firebase.auth.UserCredential> {
    log.info('attempting login via google account sso...');
    const provider = new firebase.auth.GoogleAuthProvider();
    return this.auth.signInWithPopup(provider);
  }

  async otpEmailLogin(email: string): Promise<any> {
    log.info('attempting login via magic link sent to email...');
    const loginUrl = environment.production
      ? 'https://ncalweb.ca/auth/login'
      : 'https://localhost:4200/auth/login';
    const actionCodeSettings = {
      handleCodeInApp: true,
      url: loginUrl,
    };
    return this.auth.sendSignInLinkToEmail(email, actionCodeSettings).then(() => {
      window.localStorage.setItem('emailForSignIn', email);
    });
  }

  async completeOtpEmailLogin(
    email: string,
    magicLink: string,
  ): Promise<void | firebase.auth.UserCredential> {
    log.info('completing login from magic link sent to email...');
    return this.auth.signInWithEmailLink(email, magicLink).then(() => {
      window.localStorage.removeItem('emailForSignIn');
    });
  }

  async sendPasswordReset(email: string): Promise<any> {
    log.info('attempting password reset via magic link sent to email...');
    const loginUrl = environment.production
      ? 'https://ncalweb.ca/auth/login'
      : 'https://localhost:4200/auth/login';
    const actionCodeSettings = {
      handleCodeInApp: true,
      url: loginUrl,
    };
    return this.auth.sendPasswordResetEmail(email, actionCodeSettings).then(() => {
      window.localStorage.setItem('emailForSignIn', email);
    });
  }

  async logout(): Promise<void> {
    return this.auth.signOut();
  }

  async inspectUserMetadata(): Promise<void> {
    const localkeys = Object.keys(localStorage);
    console.log(localkeys);
    const authstorage = (
      localkeys.includes(StorageKeys.MyTokens) &&
      localkeys.includes(StorageKeys.WhoAmI) &&
      localkeys.includes(StorageKeys.Me)
    );
    console.log('already have auth state stored: ' + authstorage);
    this.authState.subscribe(async (authstate: firebase.User) => {
      log.debug('Auth State: ' + authstate);
      log.debug(authstate);
      if (!authstate || typeof authstate === undefined || !authstorage) {
        console.log('Need to re-authenticate...');
        await this.googleLogin().then((usercred: firebase.auth.UserCredential) => {
          log.info('Re-Authentication Succeeded');
          this.storeAuthState(usercred);
        });
      }
    });
  }

  async checkTokenMetadata(): Promise<firebase.auth.IdTokenResult> {
    return await this.idTokenResult.pipe(
      map((result: firebase.auth.IdTokenResult) => result),
    ).toPromise();
  }

  storeAuthState(state: firebase.auth.UserCredential): void { //Promise<void> {
    console.log('storing authenticated state...');
    const credentials = state.credential;
    const profile = state.additionalUserInfo?.profile.valueOf();
    this.idTokenResult.subscribe({
      next: (result: firebase.auth.IdTokenResult) => {
        console.log(result);
        const mytokens: MyTokens = {
          accesstoken: credentials['accessToken'],
          refreshtoken: state.user.refreshToken,
          expires: result.expirationTime,
          since: result.authTime,
          idtoken: result.token,
        };
        const whoami: WhoAmI = {
          lastname: profile['family_name'],
          firstname: profile['given_name'],
          tenantid: state.user.tenantId,
          name: state.user.displayName,
          email: state.user.email,
          uid: state.user.uid,
        };
        const me: Me = {
          loginmethod: state.credential.signInMethod,
          provider: result.signInProvider,
          claims: result.claims,
          mytokens,
          whoami,
        };
        localStorage.setItem(StorageKeys.MyTokens, JSON.stringify(mytokens));
        localStorage.setItem(StorageKeys.WhoAmI, JSON.stringify(whoami));
        localStorage.setItem(StorageKeys.Me, JSON.stringify(me));
      },
      error: (err: any) => {
        console.error(err);
      },
    });
  }
}
