import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NcalMaterialModule } from '@material/index';
import { CustomContentDirective } from './directives/custom-content.directive';
import { DialogComponent } from './dialog.component';

@NgModule({
  declarations: [CustomContentDirective, DialogComponent],
  imports: [
    CommonModule,
    NcalMaterialModule,
    TranslateModule,
  ],
  exports: [DialogComponent],
})
export class DialogModule {}
