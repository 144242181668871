import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { ResponsiveService } from '@services/responsive.service';
import { NavigationService } from '@services/navigation.service';
import { ReleaseService } from '@services/release.service';
import { Observable } from 'rxjs/internal/Observable';
import { map, shareReplay } from 'rxjs/operators';
import { NavLink, NAVLINKS } from '@data/index';
import { environment } from '@env/environment';
import { AuthService } from '@auth/services/auth.service';

@UntilDestroy()
@Component({
  selector: 'ncal-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.css'],
})
export class ShellComponent implements OnInit {
  protected links: NavLink[] = NAVLINKS.filter((link: NavLink) => { return link.visible });
  @ViewChild('sidenav', {static: false}) sidenav!: MatSidenav;
  dropdownConfig = {
    selectedValue: -1,
    valueField: 'uId',
    labelField: 'invoiceSuffix',
    dropDownList: [
      { uId: 1, optionDescription: 'December' },
      { uId: 2, optionDescription: 'January' },
      { uId: 3, optionDescription: 'February' },
      { uId: 4, optionDescription: 'March' },
      { uId: 5, optionDescription: 'April' },
      { uId: 6, optionDescription: 'May' },
    ],
    heading: 'Invoice Period'
  };
  isHandset$!: Observable<boolean>;
  releaseinfo: any;

  constructor(
    private breakpoint: BreakpointObserver,
    public responsive: ResponsiveService,
    private release: ReleaseService,
    private nav: NavigationService,
    private titleService: Title,
    private auth: AuthService,
    private router: Router,
  ) {
    this.isHandset$ = this.breakpoint.observe(Breakpoints.Handset).pipe(
      map((result) => result.matches),
      shareReplay()
    );
  }

  get isMobile(): boolean {
    return (
      this.breakpoint.isMatched(Breakpoints.Small) ||
      this.breakpoint.isMatched(Breakpoints.XSmall)
    );
  }

  get title(): string {
    return this.titleService.getTitle();
  }

  ngOnInit(): void {
    this.auth.inspectUserMetadata();
  }

  updateUuid(event: any): void {
    console.log(event);
  }

  checkLastRelease(): void {
    this.release.getLatestRelease(environment.repository_path).subscribe((data: any) => {
      this.releaseinfo = data;
    }, error => {
      console.error('Error fetching release data: ', error);
    });
  }

  closeNav(): void {
    this.sidenav.close();
  }

  showLink(link: NavLink): void {
    link.showing = true;
    setTimeout(() => {
      link.showing = false;
    }, 3000);
  }
}
