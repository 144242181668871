import { Injectable } from '@angular/core';
import { HttpService } from '@services/http.service';
import { DEFAULTPARAMS, MAXDATETO, MAXPAGESIZE, NEXTPARAMS, TTPARAMS, DEFAULTTTPARAMS } from '@data/constants';
import { QueryParam, TimetrackPayload } from '@data/models';
import { environment } from '@env/environment';
import { Observable, of } from 'rxjs';
import { expand, reduce } from 'rxjs/operators';
import { ApiService } from './api.service';

const BASEURL = environment.actitime_base_url;

@Injectable({
  providedIn: 'root'
})
export class ActitimeService {
  defaultParams: QueryParam[] = DEFAULTPARAMS;
  timetrackParams: QueryParam[] = TTPARAMS;
  nextParams: QueryParam[] = NEXTPARAMS;

  constructor(
    private http: HttpService,
    private api: ApiService,
  ) {}

  filterTimetracks(param: string, query: QueryParam[]): Observable<any[]> {
    const maxDate = query[1];
    const url = `${BASEURL}/${param}`;
    const filterParams: QueryParam[] = query.concat(...DEFAULTTTPARAMS);
    return this.fetchTimetracks(url, filterParams).pipe(
      expand((response: TimetrackPayload) => {
        if (response.data && response.data.length > 0 && response.nextDateFrom) {
          const nextDateFrom = response.nextDateFrom;
          const queryParams: QueryParam[] = [
            {key: 'dateFrom', val: nextDateFrom},
            {key: 'dateTo', val: maxDate.val},
            DEFAULTTTPARAMS[0],
          ];
          return this.fetchTimetracks(url, queryParams);
        } else {
          return of();
        }
      }),
      reduce((acc, response: any) => acc.concat(response.data), [])
    );
  }

  queryTimetracks(param: string): Observable<any[]> {
    const url = `${BASEURL}/${param}`;
    return this.fetchTimetracks(url, this.timetrackParams).pipe(
      expand((response: TimetrackPayload) => {
        if (response.data && response.data.length > 0 && response.nextDateFrom) {
          const nextDateFrom = response.nextDateFrom;
          const queryParams: QueryParam[] = [
            {key: 'dateFrom', val: nextDateFrom},
            {key: 'dateTo', val: MAXDATETO},
            DEFAULTTTPARAMS[0],
          ];
          return this.fetchTimetracks(url, queryParams);
        } else {
          return of();
        }
      }),
      reduce((acc, response: any) => acc.concat(response.data), [])
    );
  }

  private fetchTimetracks(url: string, query: QueryParam[]): Observable<any> {
    const params = this.stringifyQueryParams(query);
    return this.http.get(url, params);
  }

  filterAPI(param: string, query: QueryParam[]): Observable<any[]> {
    const url = `${BASEURL}/${param}`;
    return this.fetchData(url, query.concat(...DEFAULTPARAMS)).pipe(
      expand((response: any) => {
        if (response.items && response.items.length > 0 && response.items.length === MAXPAGESIZE) {
          const page = response.offset / response.limit;
          const queryParams = this.nextPageParams(page+1);
          const filterParams: QueryParam[] = queryParams.concat(...query);
          return this.fetchData(url, filterParams);
        } else {
          return of();
        }
      }),
      reduce((acc, response: any) => acc.concat(response.items), [])
    );
  }

  queryAPI(param: string): Observable<any[]> {
    const url = `${BASEURL}/${param}`;
    return this.fetchData(url, this.defaultParams).pipe(
      expand((response: any) => {
        if (response.items && response.items.length > 0 && response.items.length === MAXPAGESIZE) {
          const page = response.offset / response.limit;
          const queryParams = this.nextPageParams(page+1);
          return this.fetchData(url, queryParams);
        } else {
          return of();
        }
      }),
      reduce((acc, response: any) => acc.concat(response.items), [])
    );
  }

  private fetchData(url: string, query: QueryParam[]): Observable<any> {
    const params = this.stringifyQueryParams(query);
    return this.http.get(url, params);
  }

  queryAPIFor(param: string): any {
    const url = `${BASEURL}/${param}`;
    const params = this.stringifyQueryParams(this.defaultParams);
    return this.http.get(url, params);
  }

  nextQueryFor(param: string, page: number): any {
    const url = `${BASEURL}/${param}`;
    const queryParams = this.nextPageParams(page);
    const params = this.stringifyQueryParams(queryParams);
    return this.http.get(url, params);
  }

  nextTimetrackQueryFor(param: string, query: QueryParam[]): any {
    const url = `${BASEURL}/${param}`;
    const params = this.stringifyQueryParams(query);
    console.log(params);
    return this.http.get(url, params);
  }

  private nextPageParams(page: number): QueryParam[] {
    const nextQP = this.nextParams;
    nextQP[0].val = nextQP[1].val * page;
    return nextQP;
  }

  stringifyQueryParams(qparams: QueryParam[]): string {
    let query = '';
    qparams.forEach((qp: {key: string; val: any}) => {
      query += `${qp.key}=${qp.val}&`;
    });
    return query.slice(0, -1);
  }

  //  gets time-track and leave time of all users for the selected date range:
  // async function example01_getTimetrack(outputTable = console.table, outputHeader = console.log) {
  //   let timetrack = await fetch(`${API_URL}/timetrack?dateFrom=${DATE}&stopAfter=100&includeReferenced=users`, GET).then(TO_JSON)
  //   let leaves = await fetch(`${API_URL}/leavetime?dateFrom=${DATE}&dateTo=${timetrack.dateTo}&includeReferenced=users`, GET).then(TO_JSON)
  //   let dateFrom = new Date(DATE)
  //   let dateTo = new Date(timetrack.dateTo)
  //   let userIds = new Set(timetrack.data.map(d => d.userId).concat(leaves.data.map(d => d.userId)))
	// outputHeader(`Tracked time for ${dateFrom.toDateString()} - ${dateTo.toDateString()}`);
  //   outputTable([
  //       ["User", "Work time", "Leave time"],
  //       ...Array.from(userIds).map(id => [
  //           (timetrack.users[id] || leaves.users[id]).fullName,
  //           formatTime(
  //               timetrack.data
  //                   .filter(d => d.userId === id)
  //                   .map(d => d.records.map(r => r.time).reduce(SUM, 0))
  //                   .reduce(SUM, 0)
  //           ),
  //           formatTime(
  //               leaves.data
  //                   .filter(d => d.userId === id)
  //                   .map(d => d.leaveTime)
  //                   .reduce(SUM, 0)
  //           )
  //       ])
  //   ])
  // }

  // gets the list of active system users with their IDs, full names, departments, and emails:
  // async function example03_userList(outputTable = console.table) {
  //   let users = await fetch(`${API_URL}/users?` + new URLSearchParams({"active":"true", "includeReferenced":"departments"}).toString(), GET).then(TO_JSON);
  //   outputTable([
  //       ["ID", "Full name", "Department", "Email"],
  //       ...users.items.map(user => [
  //           user.id + "",
  //           user.fullName,
  //           user.departmentId === -1 ? "" : users.departments[user.departmentId].name,
  //           user.email || ""
  //       ])
  //   ])
  // }

  // gets the full list of tasks existing in the system sorted by task name, along with their statuses and customers and projects they belong to:
//   async function example04_taskList(outputTable = console.table) {
//     let tasks = await fetch(`${API_URL}/tasks?` + new URLSearchParams({"includeReferenced": "projects,customers", "sort": "+name"}), GET).then(TO_JSON);

//     outputTable([
//         ["Customer", "Project", "Task", "Status"],
//         ...tasks.items.map(task => [
//             tasks.customers[task.customerId].name,
//             tasks.projects[task.projectId].name,
//             task.name,
//             task.status
//         ])
//     ])
// }

// gets the time-track grouped by customers and types of work for selected date range:
// async function example05_customersVsTypesOfWork(outputTable = console.table) {
//   let timetrack = await fetch(`${API_URL}/timetrack?dateFrom=${DATE}&includeReferenced=customers,tasks,typesOfWork`, GET).then(TO_JSON);
//   let dateTo = timetrack.dateTo;
//   let typesOfWork = Object.values(timetrack.typesOfWork);
//   let customers = Object.values(timetrack.customers);

//   let timetrackRecords = timetrack.data.reduce((result,daytt) => result.concat(daytt.records), [])

//   outputTable([
//       ["Type Of Work/Customer", ...customers.map(customer => customer.name)],
//       ...typesOfWork.map(tow => [
//           tow.name,
//           ...customers.map(customer => (
//               formatTime(
//                   timetrackRecords
//                       .filter(tt => timetrack.tasks[tt.taskId].customerId === customer.id && timetrack.tasks[tt.taskId].typeOfWorkId === tow.id)
//                       .map(tt => tt.time)
//                       .reduce(SUM, 0)
//               )
//           ))
//       ])
//   ])
// }

}
