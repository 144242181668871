import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoginCredentials, credentialsKey } from '@auth/auth.model';
import { StorageService } from '@services/storage.service';
import { AuthGuard, PublicGuard } from '../guards';
import { Shell } from '@shell/shell.service';
import { AuthService } from './auth.service';
import firebase from 'firebase/compat/app';
import { StorageKeys } from '@data/enums';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private _credentials: LoginCredentials | null = null;

  constructor(
    private storage: StorageService,
    private auth: AuthService,
    private router: Router,
  ) {
    const savedcreds = this.storage.grab(credentialsKey) ?? this.storage.secure_grab(credentialsKey);
    if (savedcreds) {
      this._credentials = savedcreds;
    }
  }

  /**
   * Checks is the user is authenticated.
   * @return True if the user is authenticated.
   */
  isAuthenticated(): boolean {
    return !!this.auth.authState.subscribe({
      next: (user: firebase.User) => {
        console.log('Logged In...');
        return true;
      },
      error: (error: any) => {
        console.log('Not Logged In...');
        return false;
      },
    });
  }

  /**
   * Gets the user credentials.
   * @return The user credentials or null if the user is not authenticated.
   */
  get credentials(): LoginCredentials | null {
    return this._credentials;
  }

  /**
   * Sets the user credentials.
   * The credentials may be persisted across sessions by setting the `remember` parameter to true.
   * Otherwise, the credentials are only persisted for the current session.
   * @param credentials The user credentials.
   * @param remember True to remember credentials across sessions.
   */
  setCredentials(credentials?: LoginCredentials, remember?: boolean): void {
    this._credentials = credentials || null;
    if (credentials) {
      const storage = remember ? localStorage : sessionStorage;
      console.log(storage);
      this.storage.stash(credentialsKey, JSON.stringify(credentials));
    } else {
      sessionStorage.removeItem(credentialsKey);
      localStorage.removeItem(credentialsKey);
    }
  }

  /**
   * Remove encrypted credentials from localstorage
   */
  // Failed login
  clearCredentials(): void {
    this.setCredentials(null);
  }

  setLoggedIn(creds: LoginCredentials, remember: boolean, resp: any): void {
    if (resp.success && resp.data.length) {
      this.storage.stash(StorageKeys.Token, resp.data);
      this.setCredentials(creds, remember);
    }
  }

  isLoggedIn(): boolean {
    return (this.storage.grab(StorageKeys.Token) && this.isAuthenticated());
  }

  updateRoutes(role: string) {
    this.router.resetConfig(role === 'developer' ? this.getDeveloperRoutes() : this.getUserRoutes());
    this.router.navigateByUrl(role === 'developer' ? '/explore' : '/export', { replaceUrl: true });
  }

  private getDeveloperRoutes() {
    return [
      Shell.childRoutes([
        // {
        //   path: 'auth',
        //   canActivate: [PublicGuard],
        //   loadChildren: () => import('@auth/auth.module').then((m) => m.AuthModule),
        //   data: {
        //     showInNavbar: false,
        //     seo: {
        //       title: 'NCAL Web Platform Login',
        //       metaTags: [
        //         { name: 'description', content: 'Login or Register to NCAL Web Platform' },
        //         { property: 'og:url', content:'https://ncaleng.ca' },
        //       ]
        //     }
        //   }
        // },
        {
          path: 'explore',
          canActivate: [AuthGuard],
          loadChildren: () => import('@explore/explore.module').then((m) => m.ExploreModule),
          data: {
            showInNavbar: true,
            seo: {
              title: 'Explore NCAL Data',
              metaTags: [
                { name: 'description', content: 'View Historical and Real-time NCAL Data' },
                { property: 'og:url', content:'https://ncaleng.ca' },
              ]
            }
          }
        },
        {
          path: 'syncing',
          canActivate: [AuthGuard],
          loadChildren: () => import('@syncing/syncing.module').then((m) => m.SyncingModule),
          data: {
            showInNavbar: true,
            seo: {
              title: 'Sync Latest NCAL Data',
              metaTags: [
                { name: 'description', content: 'Sync, Load & Calculate Invoice based on Latest NCAL Data' },
                { property: 'og:url', content:'https://ncaleng.ca' },
              ]
            }
          }
        },
        {
          path: 'export',
          canActivate: [AuthGuard],
          loadChildren: () => import('@export/export.module').then((m) => m.ExportModule),
          data: {
            showInNavbar: true,
            seo: {
              title: 'Export NCAL Reports',
              metaTags: [
                { name: 'description', content: 'Automate Business Processes & Maximize Efficiency' },
                { property: 'og:url', content:'https://ncaleng.ca' },
              ]
            }
          }
        },
        {
          path: 'settings',
          canActivate: [AuthGuard],
          loadChildren: () => import('@settings/settings.module').then((m) => m.SettingsModule),
          data: {
            showInNavbar: true,
            seo: {
              title: 'Configure NCAL Platform',
              metaTags: [
                { name: 'description', content: 'Configure how your platform works for you' },
                { property: 'og:url', content:'https://ncaleng.ca' },
              ]
            }
          }
        },
        {
          path: 'error',
          canActivate: [AuthGuard],
          loadChildren: () => import('@error/error.module').then((m) => m.ErrorModule),
          data: {
            showInNavbar: false,
            seo: {
              title: 'NCAL Error Zone',
              metaTags: [
                { name: 'description', content: 'Contact support if you find yourself here again - we\'ll fix the issue as soon as possible' },
                { property: 'og:url', content:'https://ncaleng.ca' },
              ]
            }
          }
        },
      ]),
    ];
  }

  private getUserRoutes() {
    return [
      Shell.childRoutes([
        // {
        //   path: 'auth',
        //   canActivate: [PublicGuard],
        //   loadChildren: () => import('@auth/auth.module').then((m) => m.AuthModule),
        //   data: {
        //     showInNavbar: false,
        //     seo: {
        //       title: 'NCAL Web Platform Login',
        //       metaTags: [
        //         { name: 'description', content: 'Login or Register to NCAL Web Platform' },
        //         { property: 'og:url', content:'https://ncaleng.ca' },
        //       ]
        //     }
        //   }
        // },
        {
          path: 'explore',
          canActivate: [AuthGuard],
          loadChildren: () => import('@explore/explore.module').then((m) => m.ExploreModule),
          data: {
            showInNavbar: true,
            seo: {
              title: 'Explore NCAL Data',
              metaTags: [
                { name: 'description', content: 'View Historical and Real-time NCAL Data' },
                { property: 'og:url', content:'https://ncaleng.ca' },
              ]
            }
          },
        },
        {
          path: 'syncing',
          canActivate: [AuthGuard],
          loadChildren: () => import('@syncing/syncing.module').then((m) => m.SyncingModule),
          data: {
            showInNavbar: true,
            seo: {
              title: 'Sync Latest NCAL Data',
              metaTags: [
                { name: 'description', content: 'Sync, Load & Calculate Invoice based on Latest NCAL Data' },
                { property: 'og:url', content:'https://ncaleng.ca' },
              ]
            }
          }
        },
        {
          path: 'export',
          canActivate: [AuthGuard],
          loadChildren: () => import('@export/export.module').then((m) => m.ExportModule),
          data: {
            showInNavbar: true,
            seo: {
              title: 'Export NCAL Reports',
              metaTags: [
                { name: 'description', content: 'Automate Business Processes & Maximize Efficiency' },
                { property: 'og:url', content:'https://ncaleng.ca' },
              ]
            }
          }
        },
        {
          path: 'error',
          canActivate: [AuthGuard],
          loadChildren: () => import('@error/error.module').then((m) => m.ErrorModule),
          data: {
            showInNavbar: false,
            seo: {
              title: 'NCAL Error Zone',
              metaTags: [
                { name: 'description', content: 'Contact support if you find yourself here again - we\'ll fix the issue as soon as possible' },
                { property: 'og:url', content:'https://ncaleng.ca' },
              ]
            }
          }
        },
      ])
    ];
  }
}
