// data-types that may require instantiation
// as well as unique functions that are available
// only to instances of the data-type itself
import { NodeType, EntityType, LogType } from './enums';

export class AuditNode {
  parentid?: string | null;
  url: string | null;
  nodeid: string;
  type: LogType;
  info: string;

  constructor(nodeid: string, type: LogType, info: string, url: string | null, parentid?: string | null) {
    this.nodeid = nodeid;
    this.type = type;
    this.info = info;
    this.url = url;
    this.parentid = parentid;
  }
}

export class InvoiceLog {
  info: string;
  level: number;
  status?: string;
  linked?: string;
  entity: LogType | string;

  constructor(entity: LogType | string, info: string, level: number, linked?: string | null) {
    this.entity = entity;
    this.info = info;
    this.level = level;
    this.linked = linked;
  }
}

export class TreeNode {
  children: TreeNode[] = [];
  parentid?: string | null;
  selected: boolean;
  parent?: TreeNode;
  content?: string;
  status?: string;
  linked?: string;
  entity?: string;
  type: NodeType;
  level?: number;
  title?: string;
  info?: string;
  id: string;

  constructor(
    id: string,
    type: NodeType,
    info?: string,
    title?: string,
    entity?: string,
    status?: string,
    linked?: string,
    parentid?: string | null,
    parent?: TreeNode,
  ) {
    this.id = id;
    this.type = type;
    this.children = [];
    this.parent = parent;
    this.selected = false;
    this.parentid = parentid;
    this.addTypeProps(this, type, status, linked, entity, title, info);
  }

  addTypeProps(
    node: TreeNode,
    type: NodeType,
    status: string,
    linked: string,
    entity: string,
    title: string,
    info: string,
  ) {
    switch(type) {
      case NodeType.Actitime:
        break;
      case NodeType.Invoice:
        break;
      case NodeType.AuditLog:
        node.info = info;
        node.title = title;
        node.entity = entity;
        node.status = status;
        node.linked = linked;
        break;
    }
  }
}

// IDLE TIMER CLASS FOR DETERMINING STALE SESSIONS
import { environment } from '@env/environment';

export class IdleTimer {
  constructor({ onTimeout }) {
    this.onTimeout = onTimeout;

    const expiredTime = parseInt(localStorage.getItem('_expired'), 10);
    if (expiredTime > 0 && expiredTime < Date.now()) {
      onTimeout();
      return;
    }
    this.startInterval();
  }

  onTimeout: () => void;
  interval: NodeJS.Timeout;

  static isDateExpiryBeforeNow() {
    const expiredString = localStorage.getItem('_expired');
    if (!expiredString) {
      // if this token was removed it means a separate tab unauthenticated - token was revoked so logout with client_id
      return true;
    } else {
      const expired = parseInt(localStorage.getItem('_expired'), 10);
      return expired < Date.now();
    }
  }

  static wasUserDeauthorizedFromExpiry() {
    const wasDeauthorizedFromExpiry = !!localStorage.getItem(
      'deauthorized_from_expiry'
    );
    localStorage.removeItem('deauthorized_from_expiry');
    return wasDeauthorizedFromExpiry;
  }

  static cleanUp(timer: IdleTimer, timeoutDeauth: boolean) {
    localStorage.removeItem('_expired');
    if (timer) {
      clearInterval(timer.interval);
    }
    if (timeoutDeauth) {
      localStorage.setItem('deauthorized_from_expiry', 'true');
    } else {
      localStorage.removeItem('deauthorized_from_expiry');
    }
  }

  updateExpiredTime() {
    localStorage.setItem(
      '_expired',
      (
        Date.now() +
        environment.constants.idle_timer_length_secs * 1000
      ).toString()
    );
  }

  startInterval() {
    this.updateExpiredTime();
    this.interval = setInterval(() => {
      if (IdleTimer.isDateExpiryBeforeNow()) {
        if (this.onTimeout) {
          this.onTimeout();
          IdleTimer.cleanUp(this, true);
        }
      }
    }, environment.constants.idle_timer_check_interval_secs * 1000); // check every 20 min
  }
}
