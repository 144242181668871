import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard, PublicGuard } from '@auth/guards';
import { Shell } from '@shell/shell.service';

const routes: Routes = [
  Shell.childRoutes([
    // {
    //   path: 'auth',
    //   canActivate: [PublicGuard],
    //   loadChildren: () => import('@auth/auth.module').then((m) => m.AuthModule),
    //   data: {
    //     showInNavbar: false,
    //     seo: {
    //       title: 'NCAL Web Platform Login',
    //       metaTags: [
    //         { name: 'description', content: 'Login or Register to NCAL Web Platform' },
    //         { property: 'og:url', content:'https://ncaleng.ca' },
    //       ]
    //     }
    //   }
    // },
    {
      path: 'explore',
      canActivate: [AuthGuard],
      loadChildren: () => import('@explore/explore.module').then((m) => m.ExploreModule),
      data: {
        showInNavbar: true,
        seo: {
          title: 'Explore NCAL Data',
          metaTags: [
            { name: 'description', content: 'View Historical and Real-time NCAL Data' },
            { property: 'og:url', content:'https://ncaleng.ca' },
          ]
        }
      }
    },
    {
      path: 'syncing',
      canActivate: [AuthGuard],
      loadChildren: () => import('@syncing/syncing.module').then((m) => m.SyncingModule),
      data: {
        showInNavbar: true,
        seo: {
          title: 'Sync Latest NCAL Data',
          metaTags: [
            { name: 'description', content: 'Sync, Load & Calculate Invoice based on Latest NCAL Data' },
            { property: 'og:url', content:'https://ncaleng.ca' },
          ]
        }
      }
    },
    {
      path: 'export',
      canActivate: [AuthGuard],
      loadChildren: () => import('@export/export.module').then((m) => m.ExportModule),
      data: {
        showInNavbar: true,
        seo: {
          title: 'Export NCAL Reports',
          metaTags: [
            { name: 'description', content: 'Automate Business Processes & Maximize Efficiency' },
            { property: 'og:url', content:'https://ncaleng.ca' },
          ]
        }
      }
    },
    {
      path: 'settings',
      canActivate: [AuthGuard],
      loadChildren: () => import('@settings/settings.module').then((m) => m.SettingsModule),
      data: {
        showInNavbar: true,
        seo: {
          title: 'Configure NCAL Platform',
          metaTags: [
            { name: 'description', content: 'Configure how your platform works for you' },
            { property: 'og:url', content:'https://ncaleng.ca' },
          ]
        }
      }
    },
    {
      path: 'revisions',
      canActivate: [AuthGuard],
      loadChildren: () => import('@revisions/revisions.module').then((m) => m.RevisionsModule),
      data: {
        showInNavbar: true,
        seo: {
          title: 'Revise NCAL Exports',
          metaTags: [
            { name: 'description', content: 'Make corrections as needed to exported NCAL documents' },
            { property: 'og:url', content:'https://ncaleng.ca' },
          ]
        }
      }
    },
    {
      path: 'error',
      canActivate: [AuthGuard],
      loadChildren: () => import('@error/error.module').then((m) => m.ErrorModule),
      data: {
        showInNavbar: false,
        seo: {
          title: 'NCAL Error Zone',
          metaTags: [
            { name: 'description', content: 'Contact support if you find yourself here again - we\'ll fix the issue as soon as possible' },
            { property: 'og:url', content:'https://ncaleng.ca' },
          ]
        }
      }
    },
    {
      path: '',
      redirectTo: 'explore',
      pathMatch: 'full',
    },
    {
      path: '**',
      redirectTo: 'explore',
    }
  ]),
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    })
  ],
  exports: [RouterModule],
})
export class AppRouting { }
