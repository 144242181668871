<div class="tree-viewer">
  <!-- <h5 class="tree-header">
    <mat-form-field>
      <input matInput [(ngModel)]="searchval" (keyup)="applyFilter(searchval)"
        placeholder="Filter Hierarchy">
      <span style="float:right;" (click)="clearFilter()">
        <mat-icon>clear</mat-icon>
      </span>
    </mat-form-field>
  </h5> -->
  <div class="button-controls">
    <button class="select-all-btn" mat-raised-button (click)="toggleTree(true)">All</button>
    <button class="deselect-all-btn" mat-raised-button (click)="toggleTree(false)">None</button>
  </div>
  <div class="tree-container">
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
        <button mat-icon-button disabled></button>
        <mat-checkbox
          [checked]="selected.isSelected(node)"
          (change)="selected.toggle(node)">
          {{node.info}}
        </mat-checkbox>
      </mat-tree-node>
      <mat-tree-node *matTreeNodeDef="let node; when: hasChildren" matTreeNodePadding>
        <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.title">
          <mat-icon>{{treeControl.isExpanded(node) ? 'keyboard_arrow_down' : 'chevron_right'}}</mat-icon>
        </button>
        <mat-checkbox
          [checked]="allChildrenSelected(node)"
          [indeterminate]="someChildrenSelected(node)"
          (change)="toggleNode(node)">
          {{node.info}}
        </mat-checkbox>
      </mat-tree-node>
    </mat-tree>
  </div>
</div>
