import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NcalMaterialModule } from '@material/index';
import { SpinnerModule } from '@spinner/index';
import { HeaderComponent } from './header';
import { ShellComponent } from './shell.component';
import { DialogModule } from '@dialog/index';
import { AuthModule } from '@auth/auth.module';
import { LanguageModule } from '@common/components/language';
import { BreakpointPipe } from '@pipes/breakpoint.pipe';
import { MultisearchPipe } from '@pipes/multisearch.pipe';
import { PickerModule } from '@components/picker';
import { SplitCamelCasePipe } from '@pipes/split-camel-case.pipe';
import { FilterPipe } from '@pipes/filter.pipe';
import { SafePipe } from '@pipes/safe.pipe';

@NgModule({
  declarations: [
    SplitCamelCasePipe,
    BreakpointPipe,
    MultisearchPipe,
    HeaderComponent,
    ShellComponent,
    FilterPipe,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    TranslateModule,
    NcalMaterialModule,
    PickerModule,
    LanguageModule,
    SpinnerModule,
    DialogModule,
    AuthModule,
    SafePipe,
    RouterModule,
  ],
  exports: [
    SplitCamelCasePipe,
    BreakpointPipe,
    MultisearchPipe,
    FilterPipe,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class ShellModule {}
