import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DEFAULTPARAMS, NEXTPARAMS, TTPARAMS } from '@data/constants';
import { Customer, Project, QueryParam, Task, TimeTrack, TypeOfWork, User, UserRate, TimeTrackRecord } from '@data/models';
import { environment } from '@env/environment';
import { StorageKeys } from '@data/enums';
import { retry, throttleTime } from 'rxjs';

const BASEURL = environment.actitime_base_url;

@Injectable({
  providedIn: 'root',
})
export class ApiService {

  constructor(private http: HttpClient) {}

  constructQueryString(params: HttpParams | {key: string, val: string;}[] | string): string {
    let qs = '';
    const kvpairs = Object.entries(params);
    kvpairs.forEach(([key, val], index) => {
      qs += index < index - 1 ? `${key}=${val}&` : `${key}=${val}`;
    });
    return qs;
  }

  get(
    url: string,
    params?: HttpParams | {key: string, val: string;}[] | string,
  ): Promise<any> | Promise<void> {
    return params
      ? this.http.get(`${url}?${params}`).toPromise()
      : this.http.get(`${url}`).toPromise();
  }

  fetch(
    url: string,
    params?: HttpParams | {key: string, val: string;}[] | string,
  ): Promise<any> | Promise<void> {
    return params
      ? this.http.get(`${BASEURL}/${url}?${params}`).toPromise()
      : this.http.get(`${BASEURL}/${url}`).toPromise();
  }

  patch(
    url: string,
    payload: HttpParams | any,
    params?: HttpParams | {key: string, val: string;}[] | string,
  ): Promise<any> | Promise<void> {
    const body = { payload };
    const querystring = params ? this.constructQueryString(params) : '';
    return this.http.patch(`${url}?${querystring}`, body).toPromise();
  }

  put(
    url: string,
    payload: HttpParams | any,
    params?: HttpParams | {key: string, val: string;}[] | string,
  ): Promise<any> | Promise<void> {
    const body = { payload };
    const querystring = params ? this.constructQueryString(params) : '';
    return this.http.put(`${url}?${querystring}`, body).toPromise();
  }

  checkpost(url: string, payload: HttpParams | {key: string, value: any;} | any): Promise<any> | Promise<void>  {
    const body = { payload };
    return this.http.post(url, body).pipe(
      retry(3),
      throttleTime(300),
    ).toPromise();
  }

  post(url: string, payload: HttpParams | {key: string, value: any;}): Promise<any> | Promise<void>  {
    const body = { payload };
    return this.http.post(url, body).pipe(
      retry(3),
      throttleTime(300),
    ).toPromise();
  }

  delete(
    url: string,
    params?: HttpParams | {key: string, val: string;}[] | string,
  ): Promise<any> | Promise<void>  {
    const querystring = params ? this.constructQueryString(params) : '';
    return this.http.delete(`${url}?${querystring}`).toPromise();
  }
}
