import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReleaseService {

  constructor(private http: HttpClient) { }

  getLatestRelease(repoPath: string): Observable<any> {
    const url = `https://api.github.com/repos/${repoPath}/releases/latest`;
    return this.http.get(url).pipe(
      map(release => ({
        timestamp: release['published_at'],
        commitLink: release['html_url']
      }))
    );
  }
}
