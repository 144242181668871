import firebase from 'firebase/compat/app';

export interface LoginContext {
  username: string;
  password: string;
  remember?: boolean;
}

export interface LoginCredentials {
  email: string;
  password: string;
  role: string;
}

export interface LoggedInPayload {
  success: boolean;
  message: string;
  data: string;
}

export interface Credentials {
  username: string;
  token: string;
}

export interface WhoAmI {
  tenantid: string | null;
  firstname?: string;
  lastname?: string;
  email: string;
  name: string;
  uid: string;
}

export interface MyTokens {
  refreshtoken: string;
  accesstoken: string;
  idtoken: string;
  expires: any;
  since: any;
}

export interface Me {
  claims: firebase.auth.OAuthCredentialOptions,
  loginmethod: string;
  mytokens: MyTokens;
  provider: string;
  whoami: WhoAmI;
}

export const credentialsKey = 'credentials';
