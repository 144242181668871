export const environment = {
  version: '0.0.1',
  default_format: 'json',
  repository_path: 'mllowenstein/ncalops',
  scraper_api_url: 'https://online.actitime.com/nielsen/tasks/tasklist.do',
  asset_api_live_url: 'https://ncalassetapi.vercel.app/assets',
  asset_api_base_url: 'https://ncalassetapi.vercel.app/assets',
  auth_api_base_url: 'https://insyncapi.vercel.app/api/auth',
  auth_api_data_url: 'https://insyncapi.vercel.app/api/actitime',
  sync_api_base_url: 'https://localhost:3333/store/backups',
  state_api_base_url: 'https://insyncserver.vercel.app/server/actitime',
  production: true,
  class: 'prod',
  defaultLanguage: 'en-US',
  supportedLanguages: ['en-US', 'en-CA'],
  verify_invoice_base_url: 'https://online.actitime.com/nielsen/tasks/tasklist.do',
  actitime_token: 'Basic bWljaGFlbC5sb3dlbnN0ZWluOlBlcmtpZGdlNTQzNDMk',
  actitime_base_url: 'https://online.actitime.com/nielsen/api/v1',
  actitime_accept: 'application/json; charset=UTF-8',
  content_type: 'application/json',
  origin: 'https://ncalweb.ca',
  sync_api: 'https://insyncapi.vercel.app/api/ping',
  sync_server: 'https://insyncserver.vercel.app/server/ping',
  asset_api: 'https://ncalassetapi.vercel.app/assets/ncal.png',
  dbio: 'https://ncaldbio.vercel.app/io/api',
  fast: 'https://ncalfastapi.vercel.app/fast/api',
  engine: 'https://ncalengine.vercel.app/engine/api',
  sync: 'https://ncalsync.vercel.app/sync',
  constants: {
    idle_timer_check_interval_secs: 1200,
    idle_timer_length_secs: 3600
  },
  firebase: {
  	apiKey: 'AIzaSyBZa7UQfYLgMXX8GtT3hWraOUaAhvwBa3Y',
    authDomain: 'ncalweb.firebaseapp.com',
    databaseURL: 'https://ncalwebstore.firebaseio.com',
    projectId: 'ncalweb',
    storageBucket: 'ncalweb.appspot.com',
    messagingSenderId: '879543924629',
    appId: '1:879543924629:web:3b75599d13adab4054689c',
    measurementId: 'G-66EJ7TGYW1'
  },
  fusionauth: {
    shouldAutoRefresh: true,
    // serverUrl: 'http://localhost:9011',
    serverUrl: 'https://remoteinked.fusionauth.io',
    redirectUri: 'https://localhost:4200',
    scope: 'openid email profile offline_access',
    postLogoutRedirectUri: 'http://localhost:4200',
    clientId: '9ae35dc4-3c5d-4997-be4b-7e7a0cff3b3d',
    clientSecret: 'Y8F-wwodVxQhBhq5EIYqA5NUYJUWmY6lK9mplRuFVcQ',
    apiKey: 'Nd1z-WsJHJ00tkmE6Y47YEGkVTjWsIaimtWmu6rFaAUrfA_v7-OJdv-L',
    // clientId: 'a098dd4a-727d-481f-90e5-b573d84b1c50',
    // clientSecret: 'F9oDsQhkOhQSv5s8Zm3fCT34maZZggbD-jbtytGTjUQ',
    // apiKey: 'Fh7dVheSNSMZvtP9nw9E13ZrselltrAc78X38qgrR6jpEUPIvXz_dGk-',
  }
};
