import { BrowserType } from '@data/enums';

export class BrowserDefinderService {
  private _currentBrowser: BrowserType = BrowserType.Unknown;

  public get currentBrowser(): BrowserType {
    return this._currentBrowser;
  }

  constructor() {
    this.defineBrowser();
  }

  public defineBrowser(): BrowserType {
    let browsertype = BrowserType.Unknown;
    let useragent = navigator.userAgent;
    // Note: Order matters here - may report false positives for unlisted browsers
    if (useragent.indexOf(BrowserType.Firefox) > -1) {
      browsertype = BrowserType.Firefox;
    } else if (useragent.indexOf(BrowserType.Opera) > -1 || useragent.indexOf('OPR') > -1) {
      browsertype = BrowserType.Opera;
    } else if (useragent.indexOf('Trident') > -1) {
      browsertype = BrowserType.InternetExplorer;
    } else if (useragent.indexOf('Edge') > -1) {
      browsertype = BrowserType.Edge;
    } else if (useragent.indexOf('Chrome') > -1) {
      browsertype = BrowserType.Chrome;
    } else if (useragent.indexOf(BrowserType.Safari) > -1) {
      browsertype = BrowserType.Safari;
    }
    this._currentBrowser = browsertype;
    return browsertype;
  }
}
