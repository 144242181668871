/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-explicit-any */
declare global {
  interface Array<T> {
    all(predicate: (item: T) => boolean): boolean;
    any(): boolean;
    any(predicate: (item: T) => boolean): boolean;
    any(predicate: (item: T, index: number) => boolean): boolean;
    as<V>(): V[];
    average(): number;
    average(predicate: (item: T) => number): number;
    distinct(equalityComparer?: (item1: T, item2: T) => boolean): T[];
    contains(item: T): boolean;
    contains(item: T, equalityComparer: (item1: T, item2: T) => boolean): boolean;
    except(other: T[], equalityComparer?: (item1: T, item2: T) => boolean): T[];
    filterOutNull(): NonNullable<T>[];
    find(predicate: (search: T) => boolean): T;
    first(predicate: (item: T) => boolean): T;
    first(): T;
    getPage(pageNumber: number, pageSize: number): T[];
    groupBy(keySelector: (item: T) => string): { [key: string]: T[] };
    groupBy(keySelector: (item: T) => number): { [key: number]: T[] };
    groupBy<V>(keySelector: (item: T) => string, itemSelector: (item: T) => V): { [key: string]: V[] };
    groupBy<V>(keySelector: (item: T) => number, itemSelector: (item: T) => V): { [key: number]: V[] };
    insertAt(index: number, ...items: T[]): void;
    last(): T;
    last(predicate: (item: T) => boolean): T;
    max(): T;
    max<V>(predicate?: (item: T) => V): V;
    min(): T;
    min<V>(predicate?: (item: T) => V): V;
    orderBy(predicate: (item: T) => any, descending?: boolean): T[];
    orderBy(predicate: (item: T) => any, descending?: boolean, defaultValue?: T): T[];
    orderBy(...clauses: (IOrderByClause<T>[])): T[];
    remove(item: T): T[];
    remove(predicate: (item: T) => boolean): T[];
    select<V>(map: (item: T) => V): V[];
    select<V>(map: (item: T, index: number) => V): V[];
    selectMany<V>(map: (item: T) => V[]): V[];
    selectMany<V>(map: (item: T, index: number) => V[]): V[];
    skip(amount: number): T[];
    tap(predicate: (item: T) => void): T[];
    sum(): number;
    sum(predicate: (item: T) => number): number;
    take(amount: number): T[];
    toRecordDictionary<K extends string | number>(keySelector: (item: T) => K): Record<K, T>;
    toRecordDictionary<K extends string | number, V>(keySelector: (item: T) => K): Record<K, V>;
    toDictionary(keySelector: (item: T) => string): { [key: string]: T };
    toDictionary(keySelector: (item: T) => number): { [key: number]: T };
    toDictionary<V>(keySelector: (item: T) => string, itemSelector: (item: T) => V): { [key: string]: V };
    toDictionary<V>(keySelector: (item: T) => number, itemSelector: (item: T) => V): { [key: number]: V };
    union(other: T[], equalityComparer?: (item1: T, item2: T) => boolean): T[];
    where(predicate: (item: T) => boolean): T[];
    where(predicate: (item: T, index: number) => boolean): T[];
  }
}

export function firstBy<T>(predicate: (item: T) => any,
  descending?: boolean,
  defaultValue?: T): IOrderByClause<T> {
  return {
    predicate,
    descending,
    defaultValue
  };
}

export function thenBy<T>(predicate: (item: T) => any,
  descending?: boolean,
  defaultValue?: T): IOrderByClause<T> {
  return {
    predicate,
    descending,
    defaultValue
  };
}

export interface IOrderByClause<T> {
  predicate: (item: T) => any,
  descending?: boolean;
  defaultValue?: T;
}

function orderBy(items: any[], clauses: IOrderByClause<any>[]) {
  const clause = clauses.first();
  if (clause.defaultValue == null)
    clause.defaultValue = getDefaultValue(items, clause.predicate);

  if (clauses.length == 1)
    return orderBySingle(items, clause.predicate, clause.descending, clause.defaultValue);

  const values = items.select(i => ({ item: i, value: clause.predicate(i) }));
  const keys = [...values.distinct((l, r) => l.value == r.value).select(i => i.value)];
  const byKeys = values.groupBy(i => keys.indexOf(i.value));

  clauses = clauses.skip(1);
  keys.forEach(k => {
    const index = keys.indexOf(k);
    byKeys[index] = orderBy(byKeys[index].select(k => k.item), clauses);
  });

  let keyIndicies = keys.select((k: any, i: any) => {
    return {
      key: k,
      index: i
    };
  });

  const results: { item: any; value: any; }[] = [];
  keyIndicies = orderBySingle(keyIndicies, i => i.key, clause.descending, clause.defaultValue);
  keyIndicies.forEach(k => {
    results.push(...byKeys[k.index]);
  });
  return results;
}

function orderBySingle(items: any[], predicate: (i: any) => any, descending = false, defaultValue = null) {
  if (items.length == 1)
    return items;

  const sort = (left: any, right: any) => {
    if (left == null || left === '')
      left = defaultValue;
    if (right == null || right === '')
      right = defaultValue;
    if (left < right)
      return -1;
    if (left > right)
      return 1;
    return 0;
  };
  let result = items.sort((l, r) => sort(predicate(l), predicate(r)));
  if (descending)
      result = result.reverse();
  return result;
}

function getDefaultValue(items: any[], predicate: (i: any) => any) {
  let values = items.select(v => predicate(v)).where(v => v != null);
  if (!values.any())
      return '';

  values = values.select(v => typeof v).distinct();
  if (values.length > 1)
      return new Object();

  const v = values.first(v => v != null);
  switch (typeof v) {
      case 'number':
          return Number.MAX_VALUE;
      case 'string':
          return '_____';
          break;
      case 'boolean':
          return false;
      default:
          if (v instanceof Date)
              return new Date(9999, 11, 31);
          else
              return new Object();
  }
}

function extend() {

  //#region Math

  if (!Array.prototype.average) {
      Object.defineProperty(Array.prototype, 'average', {
          value: function (predicate?: any) {
              let array = <any[]>this;
              if (predicate)
                  array = array.map(v => <number>predicate(v));
              array = array.filter(v => v != null && v != undefined);
              if (array.length === 0)
                  return null;

              const sum = array.sum();
              return sum / array.length;
          },
          enumerable: false
      });
  }

  if (!Array.prototype.max) {
      Object.defineProperty(Array.prototype, 'max', {
          value: function (predicate?: any) {

              let array = <any[]>this;
              if (predicate)
                  array = array.select(predicate);
              array = array.where(a => a != null);
              if (array.length === 0)
                  return null;
              if (array.length === 1)
                  return array[0];

              return array.orderBy(a => a).last();
          },
          enumerable: false
      });
  }

  if (!Array.prototype.min) {
      Object.defineProperty(Array.prototype, 'min', {
          value: function (predicate?: (v: any) => any) {

              let array = <any[]>this;
              if (predicate)
                  array = array.select(predicate);
              array = array.where(a => a != null);
              if (array.length === 0)
                  return null;
              if (array.length === 1)
                  return array[0];

              return array.orderBy(a => a).first();
          },
          enumerable: false
      });
  }

  if (!Array.prototype.sum) {
      Object.defineProperty(Array.prototype, 'sum', {
          value: function (predicate?: any) {
              let array = <number[]>this;
              if (predicate)
                  array = array.map(v => <number>predicate(v));

              array = array.filter(v => v != null && v != undefined);
              if (array.length === 0)
                  return null;
              if (array.length === 1)
                  return array[0];

              return array.reduce((p, c) => p + c, 0);
          },
          enumerable: false
      });
  }

  //#endregion

  //#region Navigation

  if (!Array.prototype.first) {
      Object.defineProperty(Array.prototype, 'first', {
          value: function (predicate: (item: any) => boolean) {
              const array = <any[]>this;
              if (!array || array.length === 0)
                  return null;
              if (!predicate)
                  return array[0];
              for (const i of array) {
                  if (predicate(i))
                      return i;
              }
              return null;
          },
          enumerable: false
      });
  }

  if (!Array.prototype.getPage) {
      Object.defineProperty(Array.prototype, 'getPage', {
          value: function (pageNumber: number, pageSize: number): any[] {
              const array = <any[]>this;
              if (!array.any() || pageNumber < 0)
                  return [];
              const skip = (pageNumber - 1) * pageSize;
              return array.skip(skip).take(pageSize);
          },
          enumerable: false
      });
  }
  if (!Array.prototype.last) {
      Object.defineProperty(Array.prototype, 'last', {
          value: function (predicate: (item: any) => boolean) {
              const array = <any[]>this;
              if (!array || array.length === 0)
                  return null;
              if (!predicate)
                  return array[array.length - 1];
              for (let k = array.length - 1; k >= 0; k--) {
                  const i = array[k];
                  if (predicate(i))
                      return i;
              }
              return null;
          },
          enumerable: false
      });
  }

  if (!Array.prototype.skip) {
      Object.defineProperty(Array.prototype, 'skip', {
          value: function (amount: number) {

              const array = <any[]>this;
              if (!array)
                  return null;
              if (amount >= array.length)
                  return [];

              return array.slice(amount);
          },
          enumerable: false
      });
  }

  if (!Array.prototype.take) {
      Object.defineProperty(Array.prototype, 'take', {
          value: function (amount: number) {

              const array = <any[]>this;
              if (!array)
                  return null;
              if (amount >= array.length)
                  return array;

              return array.slice(0, amount);
          },
          enumerable: false
      });
  }

  //#endregion

  if (!Array.prototype.insertAt) {
      Object.defineProperty(Array.prototype, 'insertAt', {
          value: function (index: number, ...items: any[]) {
              const array = <any[]>this;
              array.splice(index, 0, ...items);
          },
          enumerable: false
      });
  }

  if (!Array.prototype.remove) {
      Object.defineProperty(Array.prototype, 'remove', {
          value: function (predicate: (v: any) => boolean | any) {
              const array = <any[]>this;

              if (typeof predicate !== 'function') {
                  const item = predicate;
                  predicate = i => i === item;
              }
              const itemsToRemove = array.where(predicate);
              for (const i of itemsToRemove) {
                  const index = array.indexOf(i);
                  if (~index)
                      array.splice(index, 1);
              }
              return itemsToRemove;
          },
          enumerable: false
      });
  }

  //#region Other LINQ

  if (!Array.prototype.tap) {
      Object.defineProperty(Array.prototype, 'tap', {
          value: function (predicate: (i: any) => boolean) {
              const array = <any[]>this;
              array.forEach(predicate);
              return array;
          },
          enumerable: false
      });
  }


  if (!Array.prototype.all) {
      Object.defineProperty(Array.prototype, 'all', {
          value: function (predicate: (i: any) => boolean) {
              const array = <any[]>this;
              return array.every(predicate);
          },
          enumerable: false
      });
  }

  if (!Array.prototype.any) {
      Object.defineProperty(Array.prototype, 'any', {
          value: function (predicate: (v: any, i: any) => boolean) {
              const array = <any[]>this;
              if (!predicate)
                  return array.length > 0;
              return array.some(predicate);
          },
          enumerable: false
      });
  }

  // if (!Array.prototype.contains) {
  //     Object.defineProperty(Array.prototype, 'contains', {
  //         value: function (item: { [x: string]: any; } | null, equalityComparer?: (l: any, r: any) => any): boolean {
  //             if (item == null)
  //                 return false;
  //             const array = <any[]>this;

  //             if (array.length === 0)
  //                 return false;
  //             if (!equalityComparer && item['equals'])
  //                 equalityComparer = (l: { equals: (arg0: any) => any; }, r: any) => l.equals(r);

  //             if (equalityComparer != null)
  //                 return array.some(i => equalityComparer?.(i, item));

  //             return array.indexOf(item) > -1;
  //         },
  //         enumerable: false
  //     });
  // }

  // if (!Array.prototype.except) {
  //     Object.defineProperty(Array.prototype, 'except', {
  //         value: function (other: any[], equalityComparer?: { (l: any, r: any): boolean; (item1: any, item2: any): boolean; }): any[] {
  //             const array = <any[]>this;
  //             if (other == null || !other.any())
  //                 return array;
  //             const realEqualityComparer = equalityComparer || ((l: any, r: any): boolean => l === r);
  //             return this.where((i: any) => !other.contains(i, realEqualityComparer));

  //         },
  //         enumerable: false
  //     });
  // }

  if (!Array.prototype.union) {
      Object.defineProperty(Array.prototype, 'union', {
          value: function (other: any[], equalityComparer?: ((item1: any, item2: any) => boolean) | undefined): any[] {
              const array = <any[]>this;
              if (other == null || !other.any())
                  return array;
              equalityComparer = equalityComparer || ((l: any, r: any): boolean => l === r);
              return array.concat(other).distinct(equalityComparer);
          },
          enumerable: false
      });
  }

  if (!Array.prototype.distinct) {
      Object.defineProperty(Array.prototype, 'distinct', {
          value: function (equalityComparer: (l: any, r: any) => any) {

              let array = <any[]>this;
              if (!array)
                  return null;

              if (array.some(a => a === null || a === undefined)) {
                  array = array.filter(a => a !== null && a !== undefined);
                  array.push(null);
              }

              if (array.length <= 1)
                  return array;

              if (!equalityComparer && array[0]['equals'])
                  equalityComparer = (l: { equals: (arg0: any) => any; }, r: any) => l.equals(r);

              if (!equalityComparer) {
                  const isFirst = (value: any, index: number, self: any[]) => self.indexOf(value) === index;
                  return array.filter(isFirst);
              }

              const results: any[] = [];
              array.forEach(i => {
                  if (!results.some(r => equalityComparer(r, i)))
                      results.push(i);
              });
              return results;
          },
          enumerable: false
      });
  }

  // if (!Array.prototype.groupBy) {
  //     Object.defineProperty(Array.prototype, 'groupBy', {
  //         value: function (keySelector: (arg0: any) => any, itemSelector: (i: any) => any): { [key: string]: any[] } {

  //             const array = <any[]>this;
  //             if (array.length === 0)
  //                 return {};

  //             itemSelector = itemSelector || ((i: any) => i);

  //             return array.reduce((d, i) => {
  //                 const key = keySelector(i);
  //                 if (!d[key])
  //                     d[key] = [];
  //                 d[key].push(itemSelector(i));
  //                 return d;
  //             }, {});
  //         },
  //         enumerable: false
  //     });
  // }

  if (!Array.prototype.orderBy) {
      Object.defineProperty(Array.prototype, 'orderBy', {
          value: function (...args: any[]) {

              const array = [...(<any[]>this)];
              if (!array || array.length == 0)
                  return [];

              let clauses: IOrderByClause<any>[];
              if (typeof args[0] === 'function')
                  clauses = [{ predicate: args[0], descending: args[1] || false, defaultValue: args[2] }];
              else
                  clauses = [...args];

              if (clauses.length === 0)
                  clauses.push({ predicate: i => i, descending: false, defaultValue: null });

              return orderBy(array, clauses);
          },
          enumerable: false
      });
  }

  if (!Array.prototype.where) {
      Object.defineProperty(Array.prototype, 'where', {
          value: function (predicate: (i: any) => boolean) {
              const array = <any[]>this;
              return array.filter(predicate);
          },
          enumerable: false
      });
  }

  if (!Array.prototype.select) {
      Object.defineProperty(Array.prototype, 'select', {
          value: function (map: (i: any) => any) {
              const array = <any[]>this;
              return array.map(map);
          },
          enumerable: false
      });
  }


  if (!Array.prototype.as) {
      Object.defineProperty(Array.prototype, 'as', {
          value: function() {
              return this;
          },
          enumerable: false
      });
  }


  if (!Array.prototype.filterOutNull) {
      Object.defineProperty(Array.prototype, 'filterOutNull', {
          value: function () {
              const array = <any[]>this;
              return array.where(i => !!i);
          },
          enumerable: false
      });
  }


  if (!Array.prototype.selectMany) {
      Object.defineProperty(Array.prototype, 'selectMany', {
          value: function (map: ((v: any, i?: number) => any)): any[] {
              if (map == null || map.length === 0)
                  return [];
              const array: any[] = [];
              const source = <any[][]>this;
              source.forEach((v, i) => array.push(...map(v, i)));
              return array;
          },
          enumerable: false
      });
  }

  if (!Array.prototype.toDictionary) {
      Object.defineProperty(Array.prototype, 'toDictionary', {
          value: function (keySelector: (arg0: any) => any, itemSelector: (i: any) => any): { [key: string]: any[] } {

              const array = <any[]>this;
              if (array.length === 0)
                  return {};

              itemSelector = itemSelector || ((i: any) => i);

              return array.reduce((d, i) => {
                  const key = keySelector(i);
                  d[key] = itemSelector(i);
                  return d;
              }, {});
          },
          enumerable: false
      });
  }

  if (!Array.prototype.toRecordDictionary) {
      Object.defineProperty(Array.prototype, 'toRecordDictionary', {
          value: function (keySelector: (arg0: any) => any, itemSelector: (i: any) => any): { [key: string]: any[] } {

              const array = <any[]>this;
              if (array.length === 0)
                  return {};

              itemSelector = itemSelector || ((i: any) => i);

              return array.reduce((d, i) => {
                  const key = keySelector(i);
                  d[key] = itemSelector(i);
                  return d;
              }, {});
          },
          enumerable: false
      });
  }
  //#endregion

}

extend();
export { extend as ArrayExtensions };
