import { APP_INITIALIZER, ErrorHandler, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule, isDevMode } from '@angular/core';
import { RouteReuseStrategy } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AgGridModule } from 'ag-grid-angular';
import { NcalMaterialModule } from '@material/index';
import { LoadingInterceptor } from '@interceptors/loading.interceptor';
import { RequestInterceptor } from '@interceptors/request.interceptor';
import { ReuseRouteStrategy } from '@interceptors/reuse-route.strategy';
import {
  ApiService,
  SeoService,
  SimService,
  SyncService,
  I18nService,
  HttpService,
  ErrorService,
  AssetService,
  AsyncService,
  OfflineService,
  ReleaseService,
  StorageService,
  UtilityService,
  DocumentService,
  ShortcutService,
  ActitimeService,
  FirebaseService,
  InvoicingService,
  MonitoringService,
  NavigationService,
  ResponsiveService,
  ConfirmationService,
  NotificationService,
  BrowserDefinderService,
} from '@services/index';
import {
  GlobalDialogComponent,
  QueryDialogComponent,
  ConfirmDialogComponent,
  SettingsDialogComponent,
  NotificationDialogComponent,
} from '@global/dialog';
import { WebStoreModule, LocalService, SessionService } from '@webstore/index';
import { DialogModule, DialogService } from '@dialog/index';
import { ShellModule } from '@shell/shell.module';
import { SkeletonModule } from '@skeleton/index';
import { BadgeModule } from '@core/directives';
import { SpinnerModule } from '@spinner/index';
import { LoadingModule } from '@loading/index';
import { LoaderModule } from '@loader/index';
import { HistoryModule } from '@history/index';
import { IframeModule } from '@iframe/index';
import { AlertModule } from '@alert/index';
import { TreeModule } from '@tree/index';
import { GridModule} from '@grid/index';
import { AppComponent } from './app.component';
import { AppRouting } from './app.routing';
import { CacheService } from '@cache/index';
import { SafePipe } from '@pipes/safe.pipe';
import * as Sentry from "@sentry/angular";
import { Router } from "@angular/router";
import { environment } from '@env/environment';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { DynamicComponentService, DynamicContentDirective } from '@dynamic/index';

@NgModule({
  declarations: [
    AppComponent,
    GlobalDialogComponent,
    QueryDialogComponent,
    ConfirmDialogComponent,
    SettingsDialogComponent,
    DynamicContentDirective,
    NotificationDialogComponent,
  ],
  imports: [
    BrowserModule,
    WebStoreModule.forRoot({
      prefix: 'ncal',
    }),
    BrowserAnimationsModule,
    TranslateModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    NcalMaterialModule,
    HttpClientModule,
    AgGridModule,
    SafePipe,
    SkeletonModule,
    SpinnerModule,
    LoadingModule,
    HistoryModule,
    IframeModule,
    LoaderModule,
    DialogModule,
    AlertModule,
    ShellModule,
    BadgeModule,
    GridModule,
    TreeModule,
    AppRouting,
  ],
  providers: [
    ApiService,
    SeoService,
    SimService,
    SyncService,
    I18nService,
    HttpService,
    CacheService,
    ErrorService,
    AssetService,
    AsyncService,
    DialogService,
    OfflineService,
    ReleaseService,
    StorageService,
    UtilityService,
    DocumentService,
    ShortcutService,
    ActitimeService,
    FirebaseService,
    InvoicingService,
    MonitoringService,
    NavigationService,
    ResponsiveService,
    ConfirmationService,
    NotificationService,
    BrowserDefinderService,
    DynamicComponentService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    },
    {
      provide: RouteReuseStrategy,
      useClass: ReuseRouteStrategy,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule {
  public constructor(
    private readonly session: SessionService,
    private readonly local: LocalService,
  ) {
    this.session.setItem('sessionStorage', 'ncal session webstore');
    this.local.setItem('localStorage', 'ncal local webstore');
  }
}
