import {
  ErrorHandler,
  Injectable,
  Injector,
} from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Logger } from '../logger/logger.service';
import { OfflineService } from './offline.service';
import { ErrorService } from './error.service';

const log = new Logger('GlobalErrorHandler');

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandler implements ErrorHandler {

  constructor(private injector: Injector) { }

  handleError(error: Error | HttpErrorResponse) {
    const errors = this.injector.get(ErrorService);
    const offline = this.injector.get(OfflineService);

    let message: any;
    if (error instanceof HttpErrorResponse) {
      message = errors.getServerErrorMessage(error);
    } else {
      message = errors.getClientErrorMessage(error);
    }
    log.error(message, error);
    console.error(error);
  }

  fetchOfflineDataset(): void {
    console.log('fetch offline dats: coming soon...');
  }
}
