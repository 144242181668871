/* eslint-disable prettier/prettier */

declare global {
  interface String {
    cleanForRegex(): string;
    toSentenceCase(): string;
    toPropertyCase(): string;
  }
}

function extend() {
  Object.defineProperty(String.prototype, 'cleanForRegex', {
    value: function () {
      return (this as string).replace(/([.[\]*?()])/g, '\\$1');
    },
    enumerable: false,
  });

  Object.defineProperty(String.prototype, 'toSentenceCase', {
    value: function () {
      const value = this as string;
      if (value.length === 0) return value;
      return value[0].toUpperCase() + value.substring(1);
    },
    enumerable: false,
  });

  Object.defineProperty(String.prototype, 'toPropertyCase', {
    value: function () {
      const value = this as string;
      if (value.length === 0) return value;
      return value[0].toLowerCase() + value.substring(1);
    },
    enumerable: false,
  });
}
extend();
export { extend as stringExtensions };
