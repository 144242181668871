import { Injectable } from '@angular/core';
import { Observable, of, throwError, from, forkJoin, BehaviorSubject } from 'rxjs';
import { catchError, delay, map, switchMap, tap, toArray } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AsyncService {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  loading$ = this.loadingSubject.asObservable();

  constructor() {}

  // optimized chaining of rxjs operators
  optimizedChain(requests: Observable<any>[]): Observable<any[]> {
    return forkJoin(requests).pipe(
      tap((data: any[]) => console.log('All requests completed: ', data)),
      catchError((error: any) => {
        console.log('error occurred in one of the requests: ', error);
        return throwError(error);
      }),
    );
  }

  // manual observable handling for more complex scenarios
  manuallyHandleObservable(): Observable<any> {
    return new Observable(observer => {
      // pass in async operation?
      // example async operation
      setTimeout(() => {
        try {
          const result = 'manual observable handling -> result';
          observer.next(result);
          observer.complete();
        } catch (error) {
          observer.error(error);
        }
      }, 2000);
    });
  }

  // async/await prototype method
  async asyncAwaitOn(data: any[]): Promise<any[]> {
    try {
      const result = await this.asyncSimulation(data);
      console.log('async/await result: ', result);
      return result;
    } catch (error) {
      console.error('Error occurred in async/await method... ', error);
    }
  }

  private async asyncSimulation(data: any[]): Promise<any> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (data && data.length) {
          resolve(data.map(item => item * 2));
        } else {
          reject('invalid data...');
        }
      }, 3000);
    });
  }

  listenForResult(): Observable<any> {
    this.loadingSubject.next(true);
    return this.longRunningSimulation().pipe(
      tap(() => this.loadingSubject.next(false)),
      catchError(error => {
        this.loadingSubject.next(false);
        console.error('error occurred during long running operation... ', error);
        return throwError(error);
      }),
    );
  }

  private longRunningSimulation(): Observable<any> {
    return of('long running operation: complete').pipe(
      delay(5000),
    );
  }

  // sequential execution mechanism
  executeInSequence(datasets: any[], functions: ((data: any) => Promise<any>)[]): Observable<any[]> {
    return from(datasets).pipe(
      switchMap((data, index) => from(functions[index](data)).pipe(
        tap(result => console.log( `function ${index} executed with result: ${result}`)),
      )),
      catchError(error => {
        console.error('error occurred during sequential execution... ', error);
        return throwError(error);
      }),
      toArray()
    );
  }
}
