import { DateRange, NamedInvoice } from '@app/common/data';

export const SUBCONTRACTS: string[] = ['240004', '240013', '240021', '240036'];

export interface StyleDef {
  bold?: boolean;
  fontSize?: number;
  alignment?: string;
  color?: string,
  margin?: number[] | number;
  marginTop?: number;
  marginLeft?: number;
  marginRight?: number;
  marginBottom?: number;
  paddingTop?: number;
  paddingLeft?: number;
  paddingRight?: number;
  paddingBottom?: number;
  wordWrap?: string;
  display?: string;
  height?: string;
  width?: string;
}

export const DOCHEADER: StyleDef = {
  bold: true,
  fontSize: 24,
  alignment: 'right',
  color: 'light-gray',
  margin: [0, -30, 0, 10],
};

export const PAGEHEADER: StyleDef = {
  bold: true,
  fontSize: 18,
  alignment: 'left',
  margin: [0, 0, 0, 10],
};

export const PAGESUBHEADER: StyleDef = {
  bold: false,
  fontSize: 16,
  alignment: 'left',
  margin: [0, -33, 0, 10],
};

export const INVOICEBLOCKHEADER: StyleDef = {
  bold: true,
  fontSize: 24,
  alignment: 'right',
  color: 'light-gray',
  margin: [0, -30, 0, 10],
};

export const BLOCKHEADER: StyleDef = {
  bold: true,
  fontSize: 12,
  margin: [0, -30, 0, 10],
  wordWrap: 'break-word',
};

export const INVOICEBLOCKTEXT: StyleDef = {
  bold: false,
  fontSize: 12,
  margin: [0, -30, 0, 10],
  wordWrap: 'break-word',
};

export const BLOCKTEXT: StyleDef = {
  bold: false,
  fontSize: 12,
  marginTop: 10,
  wordWrap: 'break-word',
};

export const BLOCKTEXTVAL: StyleDef = {
  bold: false,
  fontSize: 12,
  marginTop: 10,
  wordWrap: 'break-word',
};

export const INVOICETABLE: StyleDef = {
  display: 'inline-table',
};

export const TIMESHEETSUMMARYTABLE: StyleDef = {
  marginTop: 20,
  display: 'inline-table',
};

export const TIMESHEETTABLE: StyleDef = {
  marginTop: 20,
  display: 'inline-table',
};

export const INVOICECOLHEADER: StyleDef = {
  bold: true,
  fontSize: 12,
  alignment: 'center',
};

export const INVOICEFIRSTCOLCELL: StyleDef = {
  bold: false,
  fontSize: 12,
  alignment: 'left',
};

export const INVOICEMIDCOLCELL: StyleDef = {
  bold: false,
  fontSize: 12,
  alignment: 'center',
};

export const INVOICELASTCOLCELL: StyleDef = {
  bold: false,
  fontSize: 12,
  alignment: 'right',
};

export const BLANKNEWLINE: StyleDef = {
  width: '100%',
  height: '10px',
  display: 'block',
};

export const SIGNATUREFOOTER: StyleDef = {
  marginTop: 50,
  alignment: 'center',
};

export const TIMESHEETLOGO: StyleDef = {
  alignment: 'right',
  margin: [0, -30, 0, 0],
};

export const TABLEROWLABEL: StyleDef = {
  bold: true,
  alignment: 'left',
};

export const TABLEROWVALUE: StyleDef = {
  bold: false,
  alignment: 'left',
};

export const TIMESHEETCOLHEADER: StyleDef = {
  bold: true,
  fontSize: 12,
  paddingTop: 10,
  color: 'light-gray',
  alignment: 'center',
};

export const TIMESHEETFIRSTCOLCELL: StyleDef = {
  bold: false,
  fontSize: 10,
  alignment: 'left',
};

export const TIMESHEETFIRSTCOLCELLPAD1LEFT: StyleDef = {
  bold: false,
  fontSize: 10,
  alignment: 'center',
};

export const TIMESHEETFIRSTCOLCELLPAD2LEFT: StyleDef = {
  bold: false,
  fontSize: 10,
  alignment: 'right',
};

export const TIMESHEETMIDCOLCELL: StyleDef = {
  bold: false,
  fontSize: 10,
  alignment: 'center',
};

export const TIMESHEETLASTCOLCELL: StyleDef = {
  bold: false,
  fontSize: 10,
  alignment: 'right',
};

export const TIMESHEETMULTIROWRIGHTCOL: StyleDef = {
  fontSize: 10,
  alignment: 'right',
};

export interface InvoiceRecord {
  definition: any;
  name: string;
  id: string;
}

export interface PreviewedInvoice {
  invoicerecord: InvoiceRecord;
  namedinvoice: NamedInvoice;
  needscorrection?: boolean;
  id?: string;
}

export interface ExportedInvoice {
  invoicerecord: InvoiceRecord;
  namedinvoice: NamedInvoice;
  needscorrection?: boolean;
  verified: boolean;
  version: number;
  id?: string;
}

export interface ByTotal {
  invoiceid: string | number;
  total: number;
}

export interface TotalsByInvoice {
  recorded: Date | string;
  bytotals: ByTotal[];
  period: DateRange;
}

export interface ByHour {
  invoiceid: string | number;
  hours: number;
}

export interface HoursByInvoice {
  recorded: Date | string;
  byhours: ByHour[];
  period: DateRange;
}

export interface Invoice {
  record?: InvoiceRecord;
  customername: string;
  projectname?: string;
  customerid?: number;
  generated: boolean;
  corrected: boolean;
  approved: boolean;
  verified: boolean;
  projectid?: any;
  name: string;
  uiid: string;
  ix: number;
}
