import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Invoice, InvoiceRecord, ExportedInvoice, PreviewedInvoice } from '@invoice/invoice.model';
import { HistoryService } from './history.service';

@Component({
  selector: 'ncal-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})
export class HistoryComponent implements OnInit {
  constructor(
    private service: HistoryService,
    private db: AngularFireDatabase,
  ) {}

  ngOnInit(): void {
    console.log('history component initiated...');
  }
}
