import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { AuthService, AuthenticationService } from '@auth/services';
import { StorageService } from '@services/storage.service';
import { environment } from '@env/environment';
import { StorageKeys } from '@data/enums';
import { Logger } from '@logger';

const log = new Logger('RequestInterceptor');
const ORIGIN: string = environment.origin;
const AUTHTOKEN: string = environment.actitime_token;
const CONTENTHEADER: string = environment.content_type;
const ACCEPTHEADER: string = environment.actitime_accept;

@Injectable({
  providedIn: 'root',
})
export class RequestInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthenticationService,
    private storage: StorageService,
    private auth: AuthService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes('/api/auth/')) {
      if (!request.url.includes('/login') && !request.url.includes('/register')) {
        const permitted = this.setTokenHeader(request);
        log.debug('request executing: ' + JSON.stringify(permitted));
        this.storage.setLastUrl(permitted.urlWithParams);
        return next.handle(permitted);
      } else {
        const permitted = this.setContentTypeHeader(request);
        log.debug('request executing: ' + JSON.stringify(request));
        this.storage.setLastUrl(permitted.urlWithParams);
        return next.handle(permitted);
      }
    } else if (request.url.includes('/assets') || request.url.includes('/sync/')) {
      log.info('Setting accept header on new request...');
      const permitted = this.setAcceptHeader(request);
      log.debug('request executing: ' + JSON.stringify(permitted));
      return next.handle(permitted);
    } else {
      if (this.authService.isAuthenticated()) {
        log.info('Setting auth token header on new request...');
        const permitted = this.setAuthHeader(request);
        log.debug('request executing: ' + JSON.stringify(permitted));
        this.storage.setLastUrl(permitted.urlWithParams);
        return next.handle(permitted);
      } else {
        log.warn('user is not authenticated - logging out automatically...')
        this.auth.logout();
      }
    }
  }

  setTokenHeader(request: HttpRequest<any>): HttpRequest<any> {
    const token = this.storage.grab(StorageKeys.Token);
    console.log(token);
    return request.clone({
      setHeaders: {
        accept: `${ACCEPTHEADER}`,
        authorization: `Bearer ${token}`,
        'Content-Type': `${CONTENTHEADER}`,
        'Access-Control-Allow-Origin': `${ORIGIN}`,
      },
      withCredentials: true,
    });
  }

  setContentTypeHeader(request: HttpRequest<any>): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        accept: `${ACCEPTHEADER}`,
        'Content-Type': `${CONTENTHEADER}`,
        'Access-Control-Allow-Origin': `${ORIGIN}`,
      },
    });
  }

  setAcceptHeader(request: HttpRequest<any>): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        accept: `${ACCEPTHEADER}`,
      },
    });
  }

  setAuthHeader(request: HttpRequest<any>): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        accept: `${ACCEPTHEADER}`,
        authorization: `${AUTHTOKEN}`,
      },
    });
  }
}
