import { Injectable } from '@angular/core';
import { addMonths, getMonth, isWithinInterval, startOfDay, endOfDay } from 'date-fns';
import { Customer, DateRange, Project, QueryParam, Task, TimeTrack, TypeOfWork, User, UserRate, TimeTrackRecord, CustomerInvoiceBlob, ProjectWithTasks, WorkTypedTimetrackedTask, InvoiceRecipients, CustomerAddress, Client, InvoiceTask, InvoiceProject } from '@data/models';
import { fom, lom, parseFromProjectName, processDate, normalizeDate } from '@data/utils';
import { DEFAULTPARAMS, MONTHS, NEXTPARAMS, TTPARAMS } from '@data/constants';
import { StorageKeys, CacheKeys } from '@data/enums';
import { InvoicingLogic } from '@logic/invoicing.logic';
import { CacheService } from '@cache/cache.service';
import { StorageService } from './storage.service';
import { InvoiceLog } from '@data/classes';
import { Logger } from '@logger';

const log = new Logger('InvoicingService');

@Injectable({
  providedIn: 'root'
})
export class InvoicingService {
  defaultParams: QueryParam[] = DEFAULTPARAMS;
  timetrackParams: QueryParam[] = TTPARAMS;
  nextParams: QueryParam[] = NEXTPARAMS;
  timetrackinvoices: any;
  customerinvoices: any;
  logic: InvoicingLogic;
  taskinvoices: any;

  constructor(
    private storage: StorageService,
    private cache: CacheService,
  ) {
    this.logic = new InvoicingLogic();
  }

  get customerInvoices() {
    return this.customerinvoices;
  }

  set customerInvoices(customerinvoices: any) {
    this.customerinvoices = customerinvoices;
  }

  private normalizeCustomerAddresses(response: any): CustomerAddress[] {
    return response.hasOwnProperty(CacheKeys.CustomerAddresses) ? response.customeraddresses : response;
  }

  private normalizeClientSet(set: any): Client[] {
    return set.hasOwnProperty(CacheKeys.Clients) ? set.clients : set;
  }

  async fetchLatestClients(): Promise<Client[]> {
    const clientset = await this.cache.get(CacheKeys.Clients);
    return this.normalizeClientSet(clientset);
  }

  async fetchCustomerProjectData(): Promise<any> {
    console.log('Fetching customer project data...');
    const [allclients, allcustomeraddresses, allcustomers, allprojects] = await Promise.all([
      this.fetchLatestClients(),
      this.cache.get(CacheKeys.CustomerAddresses) as CustomerAddress[] | any,
      this.cache.get(CacheKeys.Customers) as Customer[] | any,
      this.cache.get(CacheKeys.Projects) as Project[] | any,
    ]);
    const customeraddresses = this.normalizeCustomerAddresses(allcustomeraddresses);
    return this.logic.getCustomerProjectData(allclients, customeraddresses, allcustomers, allprojects);
  }

  async new_fetchWorkDone(cpdata: InvoiceRecipients): Promise<InvoiceRecipients> {
    const [alltasks, alltypesofwork] = await Promise.all([
      this.cache.get(CacheKeys.Tasks),
      this.cache.get(CacheKeys.TypesOfWork),
    ]);
    const taskmap = new Map();
    alltasks.forEach((task: Task) => {
      const projectid = `${task.customerId}-${task.projectId}`;
      if (!taskmap.has(projectid)) {
        taskmap.set(projectid, []);
      }
      taskmap.get(projectid).push(task);
    });
    const typeofworkmap = new Map(alltypesofwork.map((tow: TypeOfWork) => [tow.id, tow]));
    const customerprojecttasks = cpdata.customers.map((cust: CustomerInvoiceBlob) => {
      const projectswithtasks = cust.projects.map((project: Project) => {
        const projectid = `${project.customerId}-${project.id}`
        const projecttasks = taskmap.get(projectid) || [];
        const tasksofworktype = projecttasks.map((projtask: Task) => {
          const typeofwork = typeofworkmap.get(projtask.typeOfWorkId) || {};
          return { ...projtask, typeofwork };
        });
        return { ...project, tasks: tasksofworktype };
      });
      return { ...cust, projects: projectswithtasks};
    });
    console.log(customerprojecttasks);
    return { customers: customerprojecttasks };
  }

  async fetchWorkDone(cpdata: InvoiceRecipients): Promise<InvoiceRecipients> {
    const alltasks = await this.cache.get(CacheKeys.Tasks);
    const alltypesofwork = await this.cache.get(CacheKeys.TypesOfWork);
    const customerprojecttasks = cpdata.customers.map((cust: CustomerInvoiceBlob) => {
      const projectswithtasks = cust.projects.map((project: Project) => {
        const projecttasks = alltasks.filter((task: Task) => {
          return (
            task.customerId === project.customerId &&
            task.projectId === project.id
          );
        });
        const tasksofworktype = projecttasks.map((projtask: Task) => {
          const typedworktask = alltypesofwork.find((tow: TypeOfWork) => {
            return (tow.id === projtask.typeOfWorkId);
          });
          return {...projtask, typeOfWork: typedworktask};
        });
        return {...project, tasks: tasksofworktype};
      });
      return {...cust, projects: projectswithtasks};
    });
    console.log(customerprojecttasks);
    return {customers: customerprojecttasks};
  }

  async fetchWorkedDetails(cpdata: InvoiceRecipients, mockdate?: DateRange | null): Promise<InvoiceRecipients> {
    const alltimetracks = await this.cache.get(CacheKeys.Timetracks);
    const alluserrates = await this.cache.get(CacheKeys.UserRates);
    const allusers = await this.cache.get(CacheKeys.Users);
    const activeusers = allusers.filter((user: User) => {
      return user.active || !user.active;
    });
    const invoicerange = this.getCurrentMonthRange(mockdate ?? null);
    const inrangetimetracks = alltimetracks.filter((tt: TimeTrack) => {
      return (
        isWithinInterval(normalizeDate(tt.date), {
          start: startOfDay(new Date(invoicerange.from)),
          end: endOfDay(new Date(invoicerange.til)),
        })
      );
    });
    const customerworktoinvoice = cpdata.customers.map((cust: CustomerInvoiceBlob) => {
      const customerprojects = cust.projects.map((cp: ProjectWithTasks) => {
        const customerprojecttasks = cp.tasks.map((cpt: WorkTypedTimetrackedTask) => {
          const timetracksoncustomerprojecttasks = inrangetimetracks.map(
            (tt: TimeTrack) => {
              const timetrackrecs = tt.records.filter((ttr: TimeTrackRecord) => {
                return ttr.taskId === cpt.id;
              });
              const activetimetrackuser = activeusers.find((user: User) => {
                return user.id === tt.userId;
              });
              const activetimetrackuserrate = alluserrates.find((ur: UserRate) => {
                return ur.userId === tt.userId;
              });
              return {...tt, user: activetimetrackuser, userRate: activetimetrackuserrate, records: timetrackrecs};
            }
          );
          const timetrackswithrecordslogged = timetracksoncustomerprojecttasks.filter((tt: any) => {
            return tt.records.length > 0;
          });
          return {...cpt, timetracks: timetrackswithrecordslogged};
        });
        const customerprojecttasksinrange = customerprojecttasks.filter((cpt: WorkTypedTimetrackedTask) => {
          return cpt.timetracks.length > 0;
        });
        return {...cp, tasks: customerprojecttasksinrange};
      });
      const customerprojectsinrange = customerprojects.filter((cp: ProjectWithTasks) => {
        return cp.tasks.length > 0;
      });
      return {...cust, projects: customerprojectsinrange};
    });
    const customerstoinvoice = customerworktoinvoice.filter((cust: CustomerInvoiceBlob) => {
      return cust.projects.length > 0;
    });
    this.customerInvoices = {customers: customerstoinvoice};
    console.log(customerstoinvoice);
    return {customers: customerstoinvoice};
  }

  async getLogs(): Promise<InvoiceLog[]> {
    return await this.cache.get(CacheKeys.AuditLogs);
  }

  transformProjects(projects: ProjectWithTasks[]): InvoiceProject[] {
    console.log(projects);
    const projectsmap = new Map<string, InvoiceProject>();
    projects.forEach((proj: ProjectWithTasks, ix: number) => {
      const key: string = `${proj.customerId}|${proj.customerName}`;
      if (!projectsmap.has(key)) {
        projectsmap.set(key, {
          id: proj.id,
          name: proj.customerName,
          customerId: proj.customerId,
          customerName: proj.customerName,
          allowedActions: proj.allowedActions,
          project: {
            id: proj.id,
            name: parseFromProjectName('projectname', proj.customerName),
            archived: proj.archived,
            created: proj.created,
            url: proj.url,
            description: proj.description,
          },
          tasks: [],
        });
      }
      const invoicetasks = this.transformTasks(proj.tasks);
      console.log(invoicetasks);
      invoicetasks.forEach((task: InvoiceTask) => {
        projectsmap.get(key)!.tasks.push({
          ...task,
        });
      });
    });
    return Array.from(projectsmap.values());
  }

  transformTasks(tasks: WorkTypedTimetrackedTask[]): InvoiceTask[] {
    console.log(tasks);
    const tasksmap = new Map<string, InvoiceTask>();
    tasks.forEach((task: WorkTypedTimetrackedTask) => {
      const key: string = `${task.projectId}|${task.projectName}`;
      if (!tasksmap.has(key)) {
        tasksmap.set(key, {
          id: task.id,
          name: task.name,
          customerId: task.customerId,
          customerName: task.customerName,
          allowedActions: task.allowedActions,
          task: {
            id: task.id,
            url: task.url,
            name: task.name,
            created: task.created,
            archived: task.archived,
            projectId: task.projectId,
            projectName: task.projectName,
            description: task.description,
          },
          status: task.status,
          deadline: task.deadline,
          estimatedTime: task.estimatedTime,
          typeOfWork: task.typeOfWork,
          typeOfWorkId: task.typeOfWorkId,
          typeOfWorkName: task.typeOfWorkName,
          workflowStatusId: task.workflowStatusId,
          workflowStatusName: task.workflowStatusName,
          subtasks: [],
        });
      }
      tasksmap.get(key)!.subtasks.push({
        ...task,
        timetracks: task.timetracks || [],
      });
    });
    return Array.from(tasksmap.values());
  }

  getCurrentMonthRange(mockdate?: DateRange | null): DateRange {
    const invoicemonth = this.storage.grab(StorageKeys.InvoiceFiscalMonth);
    console.log(invoicemonth);
    const day = 15;
    const month = invoicemonth.label;
    let year = new Date(Date.now()).getFullYear();
    if (invoicemonth.value === 0) year -= 1;
    const reference = new Date(`${month} ${day}, ${year}`);
    const folm = fom(addMonths(reference, 0));
    const lolm = lom(addMonths(reference, 0));
    return { from: processDate(folm), til: processDate(lolm) };
  }

  getTimeIntervalData(): void {
    const daterange = this.getCurrentMonthRange();
    console.log(daterange);
    const lastMonthIx = getMonth(addMonths(new Date(), -1));
    const lastMonth = MONTHS[lastMonthIx];
    console.log(lastMonth);
  }
}
