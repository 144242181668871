import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root',
})
export class ConfirmationService {
  private confirmationSubject: Subject<boolean>;

  constructor() {
    this.confirmationSubject = new Subject<boolean>();
  }

  confirm(message: string): Observable<boolean> {
    const confirmation = window.confirm(message);
    this.confirmationSubject.next(confirmation);
    return this.confirmationSubject.asObservable();
  }
}
