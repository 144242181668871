import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { NavigationService } from '@services/navigation.service';
import { UntilDestroy, untilDestroyed } from '@core/index';
import { TranslateService } from '@ngx-translate/core';
import { I18nService } from '@services/i18n.service';
import { filter, map, merge, mergeMap } from 'rxjs';
import { SeoService } from '@services/seo.service';
import { LoadingService } from '@loading/index';
import { environment } from '@env/environment';
import { Logger } from '@logger';
import { AuthService } from './auth';

const log = new Logger('AppLogger');

@UntilDestroy()
@Component({
  selector: 'ncal-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  authview: boolean = true;
  title = 'ncalops';
  loading!: boolean;

  constructor(
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private i18nService: I18nService,
    private loader: LoadingService,
    private nav: NavigationService,
    private auth: AuthService,
    private seo: SeoService,
    private router: Router,
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.loading = true;
      } else if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
        this.loading = false;
      }
    });
  }

  ngOnInit(): void {
    this.loading = true;
    if (environment.production) {
      Logger.enableProductionMode();
    }
    log.debug('initializing...');
    this.i18nService.init(environment.defaultLanguage, environment.supportedLanguages);
    const onNavigationEnd = this.router.events.pipe(filter((event) => event instanceof NavigationEnd));
    this.router.events.subscribe((event) => {
      this.checkView();
      if (event instanceof NavigationStart) {
        this.loader.show();
      } else if (event instanceof NavigationEnd && event.url === '/export') {
        setTimeout(() => {
          this.router.navigate(['/export'], {skipLocationChange: true});
        }, 0);
      } else if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
        merge(this.translateService.onLangChange, onNavigationEnd).pipe(
          map(() => {
            let route = this.activatedRoute;
            while (route.firstChild) {
              route = route.firstChild;
            }
            return route;
          }),
          filter((route) => route.outlet === 'primary'),
          mergeMap((route) => route.data),
          untilDestroyed(this)
        ).subscribe((data) => {
          const seodata = data['seo'] || null;
          if (seodata) {
            if (seodata['title']) {
              this.seo.updateTitle(this.translateService.instant(seodata['title']));
            } else {
              this.seo.updateTitle(this.translateService.instant(data['title']));
            }
            if (seodata['metaTags']) {
              this.seo.updateMetaTags(this.translateService.instant(seodata['metaTags']));
            } else {
              log.debug('no metatags found on this route...');
            }
          }
          this.loader.hide();
        });
      }
      this.loader.isLoading.subscribe(isLoading => {
        this.loading = isLoading;
      });
    });
    this.nav.broadcast();
  }

  private checkView(): void {
    if (window.location.href.includes('/auth')) {
      this.authview = true;
    } else {
      this.authview = false;
    }
  }

  ngOnDestroy(): void {
    this.i18nService.destroy();
  }
}
