import { Injectable } from '@angular/core';
import { CacheService } from '@cache/cache.service';
import customeraddresses from '@jsonassets/customeraddresses.json';
import customers from '@jsonassets/customers.json';
import clients from '@jsonassets/clients.json';
import departments from '@jsonassets/departments.json';
import projects from '@jsonassets/projects.json';
import tasks from '@jsonassets/tasks.json';
import timetracks from '@jsonassets/timetracks.json';
import typesofwork from '@jsonassets/typesofwork.json';
import users from '@jsonassets/users.json';
import userrates from '@jsonassets/userrates.json';
import workflowstatuses from '@jsonassets/workflowstatuses.json';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OfflineService {
  constructor(
    private cache: CacheService,
  ) { }
}

